import { format } from 'date-fns';
import Util from './Util';
import { IonIcon, IonLabel } from '@ionic/react';
import { camera } from 'ionicons/icons';

const getListLabel = (entry) => {
  const hoursWorked = getHoursWorked(entry);
  const tipsPerHour = getTipsPerHour(entry);
  return (
    <IonLabel className='pt-1.5 pb-1.5 px-2'>
      {/* date @ workplace */}
      <h2>{format(new Date(getStartTimestamp(entry)), 'MMM d, yyyy')} @ {entry.resteraunt}{window.location.origin.match(/.*localhostzzz.*/) !== null ? ', (id: '+entry.id+')' : ''}</h2>
      <p className='py-0.5'>

        {/* tips */}
        <span className='text-black dark:text-white mr-[1px]'>$</span><span className='tipzMoney'>{`${Util.getCurrencyString(getTotalTips(entry))}`}</span> tips

        {/* net sales */}
        {!isNaN(parseFloat(entry.netSales)) &&
         <>, <span className='text-black dark:text-white mr-[1px]'>$</span><span className='inline-block'><span className='tipzMoney'>{'' + Util.getCurrencyString(entry.netSales)}</span> net sales</span></>
        }
        {/* hours worked */}
        {hoursWorked > 0 && 
          <>, <span className='tipzHours'>{hoursWorked.toFixed(2).replace(/\.00$/, '')}</span> hours</>
        }

        {/* tips / hour */}
        {tipsPerHour > 0 && 
          <span className='whitespace-nowrap inline-block'>, <span className='tipzPerHour'>{tipsPerHour.toFixed(2).replace(/\.00$/, '')}</span> tips / hour</span>
        }

        {/* image */}
        {entryHasImage(entry) && <span>, Image <IonIcon icon={camera} color='primary' className='align-middle' /></span>}
      </p>
    </IonLabel>
  );
};

const getTipsPerHour = (entry) => {
  const hoursWorked = getHoursWorked(entry);
  const totalTips = getTotalTips(entry);
  return Math.abs(hoursWorked < 0.001) ? 0 : totalTips / hoursWorked;
};

/**
* Returns the hours worked for the given entry as a float, or 0 if the entry doesn't have any hours worked info
**/
const getHoursWorked = (entry) => {
  if(isUsingStartEndTimes(entry)) {
    const startTime = getStartTimestamp(entry);
    if(startTime === 0) {
      return 0;
    }

    const endTime = getEndTimestamp(entry);
    if(endTime < startTime) {
      return 0;
    }

    let breakDuration = parseInt(entry.breakDuration);
    if(isNaN(breakDuration)) {
      breakDuration = 0;
    }

    let entryWorkedMillis = endTime - startTime;
    entryWorkedMillis -= breakDuration*(1000*60); 
    const entryWorkedHours = entryWorkedMillis/(1000*60*60); //convert from milliseconds to hours
    return entryWorkedHours;
  } 

  // not using start and end times
  const hoursWorked = parseFloat(entry.hoursWorked);
  return isNaN(hoursWorked) ? 0 : hoursWorked;
};

const getEntryDatestring = (entry) => {
  if(typeof entry !== 'object') {
    return '';
  }

  const startTimestamp = getStartTimestamp(entry);
  return format(new Date(startTimestamp), 'EEE, MMM d, yyyy');
};

const getStartTimestamp = (entry) => {
  const startTimestamp = parseInt(entry.startTimestamp);
  if(!isNaN(startTimestamp)) {
    return startTimestamp;
  }

  const startTimestring = parseInt(entry.startTimestring);
  if(!isNaN(startTimestring)) {
    return startTimestring;
  }

  return 0;
};

const getEndTimestamp = (entry) => {
  const endTimestamp = parseInt(entry.endTimestamp);
  if(!isNaN(endTimestamp)) {
    return endTimestamp;
  }

  const endTimestring = parseInt(entry.endTimestring);
  if(!isNaN(endTimestring)) {
    return endTimestring;
  }

  return 0;
};

const entryHasImage = (entry) => {
  if(typeof entry !== 'object' || 'hasImage' in entry !== true) {
    return false;
  }
  return entry['hasImage'] === 'true' || entry['hasImage'] === true;
};

const isUsingStartEndTimes = (entry) => {
  if(typeof entry !== 'object') {
    return false;
  }

  const startTimestamp = getStartTimestamp(entry);
  const endTimestamp = getEndTimestamp(entry);
  return startTimestamp > 0 && endTimestamp > 0;

  //const endTimestamp = parseInt(entry.endTimestamp);
  //const endTimestring = parseInt(entry.endTimestring);
  //return (!isNaN(endTimestamp) && endTimestamp > 0) || (!isNaN(endTimestring) && endTimestring > 0);
};

const getNetSales = (entry) => {
  if(typeof entry !== 'object') {
    return 0;
  }

  const netSales = parseFloat(entry.netSales);

  return isNaN(netSales) ? 0 : netSales;
};

const getTotalTips = (entry) => {
  if(typeof entry !== 'object') {
    return 0;
  }

  let totalTips = 0;
  let uncategorizedTips = parseFloat(entry.uncategorizedTips);
  let creditTips = parseFloat(entry.creditTips);
  let cashTips = parseFloat(entry.cashTips);
  let tipIn = parseFloat(entry.tipIn);
  let tipOut = parseFloat(entry.tipOut);

  // if cash or credit tips exist add both to the total, else add uncategorizedTips
  if(!isNaN(creditTips) || !isNaN(cashTips)) {
    if(!isNaN(creditTips)) {
      totalTips += creditTips;
    }
    if(!isNaN(cashTips)) {
      totalTips += cashTips;
    }
  } else if(!isNaN(uncategorizedTips)) {
      totalTips += uncategorizedTips;
  }

  // add tip in
  if(!isNaN(tipIn)) {
    totalTips += tipIn;
  }

  // subtract tip out
  if(!isNaN(tipOut)) {
    totalTips -= tipOut;
  }
  
  return totalTips;
};

const isBlankEntry = (entry) => {
  if(!isNaN(parseFloat(entry.uncategorizedTips)) && parseFloat(entry.uncategorizedTips) > 0) {
    return false;
  }
  if(!isNaN(parseFloat(entry.cashTips)) && parseFloat(entry.cashTips) > 0) {
    return false;
  }
  if(!isNaN(parseFloat(entry.creditTips)) && parseFloat(entry.creditTips) > 0) {
    return false;
  }
  if(!isNaN(parseFloat(entry.tipIn)) && parseFloat(entry.tipIn) > 0) {
    return false;
  }
  if(!isNaN(parseFloat(entry.tipOut)) && parseFloat(entry.tipOut) > 0) {
    return false;
  }
  if(!isNaN(parseFloat(entry.netSales)) && parseFloat(entry.netSales) > 0) {
    return false;
  }
  if(typeof entry.notes === 'string' && entry.notes.length > 0) {
    return false
  }
  if(!isNaN(parseFloat(entry.hoursWorked)) && parseFloat(entry.hoursWorked) > 0) {
    return false;
  }
  if(entryHasImage(entry)) {
    return false;
  }
  return true;
};

const areEqual = (entry1, entry2) => {
  if(!entry1 || !entry2) {
    return false;
  }
  if(entry1.resteraunt !== entry2.resteraunt) {
    return false;
  }
  if(entry1.job !== entry2.job) {
    //console.log('---entriesEqual', 'entry1.job !== entry2.job');
    return false;
  }
  if(getStartTimestamp(entry1) !== getStartTimestamp(entry2)) {
    //console.log('---entriesEqual', 'getStartTimestamp(entry1) !== getStartTimestamp(entry2)');
    return false;
  }
  if(getEndTimestamp(entry1) !== getEndTimestamp(entry2)) {
    //console.log('---entriesEqual', 'getEndTimestamp(entry1) !== getEndTimestamp(entry2)');
    return false;
  }
  if(Util.getCurrencyString(entry1.hoursWorked) !== Util.getCurrencyString(entry2.hoursWorked)) {
    //console.log('---entriesEqual', 'Util.getCurrencyString(entry1.hoursWorked) !== Util.getCurrencyString(entry2.hoursWorked)');
    return false;
  }
  //if(Util.getCurrencyString(entry1.hoursWorkedString) !== Util.getCurrencyString(entry2.hoursWorkedString)) {
  //  console.log('---entriesEqual', 'Util.getCurrencyString(entry1.hoursWorkedString) !== Util.getCurrencyString(entry2.hoursWorkedString)');
  //  return false;
  //}
  if(Util.getCurrencyString(entry1.breakDuration) !== Util.getCurrencyString(entry2.breakDuration)) {
    //console.log('---entriesEqual', 'Util.getCurrencyString(entry1.breakDuration) !== Util.getCurrencyString(entry2.breakDuration)');
    return false;
  }
  if(Util.getCurrencyString(entry1.cashTips) !== Util.getCurrencyString(entry2.cashTips)) {
    //console.log('---entriesEqual', 'Util.getCurrencyString(entry1.cashTips) !== Util.getCurrencyString(entry2.cashTips)');
    return false;
  }
  if(Util.getCurrencyString(entry1.creditTips) !== Util.getCurrencyString(entry2.creditTips)) {
    //console.log('---entriesEqual', 'Util.getCurrencyString(entry1.creditTips) !== Util.getCurrencyString(entry2.creditTips)');
    return false;
  }
  if(Util.getCurrencyString(entry1.uncategorizedTips) !== Util.getCurrencyString(entry2.uncategorizedTips)) {
    //console.log('---entriesEqual', 'Util.getCurrencyString(entry1.uncategorizedTips) !== Util.getCurrencyString(entry2.uncategorizedTips)');
    return false;
  }
  if(Util.getCurrencyString(entry1.tipIn) !== Util.getCurrencyString(entry2.tipIn)) {
    //console.log('---entriesEqual', 'Util.getCurrencyString(entry1.tipIn) !== Util.getCurrencyString(entry2.tipIn)');
    return false;
  }
  if(Util.getCurrencyString(entry1.tipOut) !== Util.getCurrencyString(entry2.tipOut)) {
    //console.log('---entriesEqual', 'Util.getCurrencyString(entry1.tipOut) !== Util.getCurrencyString(entry2.tipOut)');
    return false;
  }
  if(Util.getCurrencyString(entry1.netSales) !== Util.getCurrencyString(entry2.netSales)) {
    //console.log('---entriesEqual', 'Util.getCurrencyString(entry1.netSales) !== Util.getCurrencyString(entry2.netSales)');
    return false;
  }
  if(entry1.notes !== entry2.notes) {
    //console.log('---entriesEqual', 'entry1.notes !== entry2.notes');
    return false;
  }
  if(entryHasImage(entry1) !== entryHasImage(entry2)) { // TODO: need to differentiate between images, not only if one exists or not
    //console.log('---entriesEqual', 'entry1.hasImage !== entry2.hasImage');
    return false;
  }
  return true;
};

const exports = {
  getTotalTips: getTotalTips,
  isUsingStartEndTimes: isUsingStartEndTimes,
  entryHasImage: entryHasImage,
  getStartTimestamp: getStartTimestamp,
  getEndTimestamp: getEndTimestamp,
  getHoursWorked: getHoursWorked,
  getEntryDatestring: getEntryDatestring,
  getListLabel: getListLabel,
  getNetSales: getNetSales,
  isBlankEntry: isBlankEntry,
  areEqual: areEqual,
  getTipsPerHour: getTipsPerHour,
};

export default exports;
