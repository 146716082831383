import  { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { IonContent, IonHeader, IonToolbar, IonIcon, IonTitle, IonLabel, IonCard, IonCardContent, IonCardSubtitle, IonCardTitle, IonCardHeader, IonCheckbox, IonChip } from '@ionic/react';
import { Chart as ChartJS } from 'chart.js/auto';
import TipzSelect from './TipzSelect';
import TipzButton from './TipzButton';
import TipzLogoMinimal from './TipzLogoMinimal';
import TipzDatetimePickerButtons from './TipzDatetimePickerButtons'; 
import { Bar } from 'react-chartjs-2';
import { formatISO, format } from 'date-fns';
import { arrowBack } from 'ionicons/icons';
import Entry from './Entry';
import Util from './Util';

const TipzSummary = ({userData, hidden=false, showHeader=true, onFinished = () => {}}) => {
  const dateRangeChoices = useMemo(() => ['This Week', 'Last Week', 'This Month', 'Last Month', 'This Year', 'All Entries', 'Custom Range'], []);
  const barDurationChoice = useMemo(() => ['Daily', 'Weekly', 'Monthly', 'Yearly'], []);

  const chartRef = useRef(null);
  const chartContainer = useRef(null);
  const [hideChart, setHideChart] = useState(false);

  const [summaryHoursWorked, setSummaryHoursWorked] = useState(0);
  const [summaryEntriesCount, setSummaryEntriesCount] = useState(0);
  const [summaryCashCreditTips, setSummaryCashCreditTips] = useState(0);
  const [summaryTipsPerHour, setSummaryTipsPerHour] = useState(0);

  const [chartShowingTips, setChartShowingTips] = useState(true);
  const [chartShowingHours, setChartShowingHours] = useState(false);
  const [chartShowingTipsPerHour, setChartShowingTipsPerHour] = useState(true);

  const [inputDateRange, setInputDateRange] = useState(dateRangeChoices[5]); 
  const [inputBarDuration, setInputBarDuration] = useState(barDurationChoice[1]); 
  const [inputFromDate, setInputFromDate] = useState(undefined);
  const [inputToDate, setInputToDate] = useState(undefined);
  const [fromTimestamp, setFromTimestamp] = useState(undefined);
  const [toTimestamp, setToTimestamp] = useState(undefined);
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: []
  });
  const [chartOptions, setChartOptions] = useState({
    scales: {
      y: {
        beginAtZero: true,
        //max: 1,
      },
    },
    //responsive: true,
    maintainAspectRatio: false,
    //aspectRatio: 1.5,
  });

  const updateChart = useCallback((fromDate, toDate) => {
    const oneDayMillis = 1000*60*60*24;
    const startTimestamp = fromDate.getTime();
    const endTimestamp = toDate.getTime()+oneDayMillis;
    const dayCount = Math.round((endTimestamp-startTimestamp)/oneDayMillis);
    const currentDate = new Date();

    const newGraphLabels = []; // label data for chart.js
    const hoursWorkedData = []; // dataset for chart.js
    const tipsData = []; // dataset for chart.js
    const tipsPerHourData = []; // dataset for chart.js
    let maxBarValue = 0.01;
    let totalHoursWorked = 0;
    let totalEntriesCount = 0;
    let totalTips = 0;
    let totalTipsWithHours = 0; // these are tips for entries that also have information about hours worked (we need this for tips / hours calculations)

    const entriesInRange = [];
    const startOfFromDate = new Date(fromDate.getTime());
    startOfFromDate.setHours(0, 0, 0); // set to first second of the day
    const endOfToDate = new Date(toDate.getTime());
    endOfToDate.setHours(23, 59, 59); // set to last second of the day
    const startOfFromDateTimestamp = startOfFromDate.getTime();
    const endOfToDateTimestamp = endOfToDate.getTime();
    for(const entry of userData.entries) {
      const entryStartTime = Entry.getStartTimestamp(entry);
      if(entryStartTime >= startOfFromDateTimestamp && entryStartTime <= endOfToDateTimestamp) {
        entriesInRange.push(entry);
      }
    }

    if(inputBarDuration === 'Daily') {
      // create a hash map with a datetime string for each entry
      const labelEntries = {};
      for(const entry of entriesInRange) {
        const date = new Date(Entry.getStartTimestamp(entry));
        const datestring = format(date, date.getYear() === currentDate.getYear() ? 'MMM d' : 'MMM d, yy');
        if(typeof labelEntries[datestring] !== 'object') {
          labelEntries[datestring] = [];
        }
        labelEntries[datestring].push(entry);
      }

      //create day labels and data for each label
      for(let i = 0; i < dayCount; i++) {
        const date = new Date();
        date.setTime(startTimestamp+(i*oneDayMillis));
        const datestring = format(date, date.getYear() === currentDate.getYear() ? 'MMM d' : 'MMM d, yy');
        newGraphLabels.push(datestring);

        let hoursWorkedForLabel = 0;
        let tipsForLabel = 0;
        let tipsWithHoursForLabel = 0; // these are tips for entries that also have information about hours worked (we need this for tips / hours calculations)
        if(typeof labelEntries[datestring] === 'object') {
          for(const entry of labelEntries[datestring]) {
            const entryWorkedHours = Entry.getHoursWorked(entry);
            hoursWorkedForLabel += entryWorkedHours;
            totalHoursWorked += entryWorkedHours;
            totalEntriesCount += 1;
            const totalTipsForEntry = Entry.getTotalTips(entry);
            totalTips += totalTipsForEntry;
            tipsForLabel += totalTipsForEntry;
            tipsWithHoursForLabel += (Math.abs(entryWorkedHours) >= 0.001) ? totalTipsForEntry : 0;
            totalTipsWithHours += (Math.abs(entryWorkedHours) >= 0.001) ? totalTipsForEntry : 0;
          }
        }
        if(Math.ceil(hoursWorkedForLabel) > maxBarValue) {
          maxBarValue = Math.ceil(hoursWorkedForLabel);
        }
        hoursWorkedData.push(hoursWorkedForLabel > 0 ? parseFloat(hoursWorkedForLabel.toFixed(2)) : '');
        tipsData.push(tipsForLabel > 0 ? parseFloat(tipsForLabel.toFixed(2)) : '');
        tipsPerHourData.push((isNaN(tipsWithHoursForLabel) || isNaN(hoursWorkedForLabel) || Math.abs(hoursWorkedForLabel) < 0.0001) ? '0.00' : (tipsWithHoursForLabel/hoursWorkedForLabel).toFixed(2));
      }
    } else if(inputBarDuration === 'Weekly') {
      const entriesPerBar = {};
      let earliestEntry = null;
      let latestEntry = null;
      // create a hash map with a datetime string for each entry, find earliest and latest entries
      for(const entry of entriesInRange) {
        const date = new Date(Entry.getStartTimestamp(entry));
        date.setDate(date.getDate() - date.getDay()); //set to first day of week
        const datestring = format(date, date.getYear() === currentDate.getYear() ? 'MMM d' : 'MMM d, yy'); 
        if(typeof entriesPerBar[datestring] !== 'object') {
          entriesPerBar[datestring] = [];
        }
        entriesPerBar[datestring].push(entry);

        if(earliestEntry === null || Entry.getStartTimestamp(entry) < Entry.getStartTimestamp(earliestEntry)) {
          earliestEntry = entry;
        }
        if(latestEntry === null || Entry.getStartTimestamp(entry) > Entry.getStartTimestamp(latestEntry)) {
          latestEntry = entry;
        }
      }

      if(earliestEntry !== null && fromDate.getTime() <= toDate.getTime()) {
        const firstDayOfToDateWeek = new Date(toDate.getTime());
        firstDayOfToDateWeek.setDate(firstDayOfToDateWeek.getDate() - firstDayOfToDateWeek.getDay()); //set to first day of week
        const endDatestring = format(firstDayOfToDateWeek, firstDayOfToDateWeek.getYear() === currentDate.getYear() ? 'MMM d' : 'MMM d, yy'); 
        
        // Create dataset, equal length arrays of labels and corresponding values
        let indexDate = new Date(fromDate.getTime());
        indexDate.setDate(indexDate.getDate() - indexDate.getDay()); //set to first day of week
        let indexDatestring = format(indexDate, indexDate.getYear() === currentDate.getYear() ? 'MMM d' : 'MMM d, yy');  
        let addedFinalBar = false;
        while(!addedFinalBar) {
          //label
          newGraphLabels.push('Wk '+indexDatestring);

          let hoursWorkedForLabel = 0;
          let tipsForLabel = 0;
          let tipsWithHoursForLabel = 0; // these are tips for entries that also have information about hours worked (we need this for tips / hours calculations)
          if(typeof entriesPerBar[indexDatestring] === 'object') {
            for(const entry of entriesPerBar[indexDatestring]) {
              const entryWorkedHours = Entry.getHoursWorked(entry);
              hoursWorkedForLabel += entryWorkedHours;
              totalHoursWorked += entryWorkedHours;
              totalEntriesCount += 1;
              const totalTipsForEntry = Entry.getTotalTips(entry);
              totalTips += totalTipsForEntry;
              tipsForLabel += totalTipsForEntry;
              tipsWithHoursForLabel += (Math.abs(entryWorkedHours) >= 0.001) ? totalTipsForEntry : 0;
              totalTipsWithHours += (Math.abs(entryWorkedHours) >= 0.001) ? totalTipsForEntry : 0;
              entry.added = true;
            }
          }
          if(Math.ceil(hoursWorkedForLabel) > maxBarValue) {
            maxBarValue = Math.ceil(hoursWorkedForLabel);
          }

          //label value
          hoursWorkedData.push(hoursWorkedForLabel > 0 ? parseFloat(hoursWorkedForLabel.toFixed(2)) : '');
          tipsData.push(tipsForLabel > 0 ? parseFloat(tipsForLabel.toFixed(2)) : '');
          tipsPerHourData.push((isNaN(tipsWithHoursForLabel) || isNaN(hoursWorkedForLabel) || Math.abs(hoursWorkedForLabel) < 0.0001) ? '0.00' : (tipsWithHoursForLabel/hoursWorkedForLabel).toFixed(2));

          addedFinalBar = indexDatestring === endDatestring;
          indexDate.setDate(indexDate.getDate() + 7); //move forward by one week
          indexDatestring = format(indexDate, indexDate.getYear() === currentDate.getYear() ? 'MMM d' : 'MMM d, yy');
        }
      }
    } else if(inputBarDuration === 'Monthly') {
      const entriesPerBar = {};
      let earliestEntry = null;
      let latestEntry = null;
      const dateStringFormat = "MMM yy";
      const getDatestring = (date) => {
        let dateString = format(date, dateStringFormat);
        const match = dateString.match(/([0-9]+)$/);
        if(match) {
          dateString = dateString.replace(match[1], '\''+match[1]); // add an apostrophe before the two-digit year in MM 'YY datestring
        }
        return dateString;
      };

      // create a hash map with a datetime string for each entry, find earliest and latest entries
      for(const entry of entriesInRange) {
        const date = new Date(Entry.getStartTimestamp(entry));
        const datestring = getDatestring(date);
        if(typeof entriesPerBar[datestring] !== 'object') {
          entriesPerBar[datestring] = [];
        }
        entriesPerBar[datestring].push(entry);

        if(earliestEntry === null || Entry.getStartTimestamp(entry) < Entry.getStartTimestamp(earliestEntry)) {
          earliestEntry = entry;
        }
        if(latestEntry === null || Entry.getStartTimestamp(entry) > Entry.getStartTimestamp(latestEntry)) {
          latestEntry = entry;
        }
      }

      if(earliestEntry !== null && fromDate.getTime() <= toDate.getTime()) {
        const endDatestring = getDatestring(toDate);
        
        // Create dataset, equal length arrays of labels and corresponding values
        let indexDate = new Date(fromDate.getTime());
        let indexDatestring = getDatestring(fromDate) 
        let addedFinalBar = false;
        while(!addedFinalBar) {
          //label
          newGraphLabels.push(indexDatestring);

          let hoursWorkedForLabel = 0;
          let tipsForLabel = 0;
          let tipsWithHoursForLabel = 0; // these are tips for entries that also have information about hours worked (we need this for tips / hours calculations)
          if(typeof entriesPerBar[indexDatestring] === 'object') {
            for(const entry of entriesPerBar[indexDatestring]) {
              const entryWorkedHours = Entry.getHoursWorked(entry);
              hoursWorkedForLabel += entryWorkedHours;
              totalHoursWorked += entryWorkedHours;
              totalEntriesCount += 1;
              const totalTipsForEntry = Entry.getTotalTips(entry);
              totalTips += totalTipsForEntry;
              tipsForLabel += totalTipsForEntry;
              tipsWithHoursForLabel += (Math.abs(entryWorkedHours) >= 0.001) ? totalTipsForEntry : 0;
              totalTipsWithHours += (Math.abs(entryWorkedHours) >= 0.001) ? totalTipsForEntry : 0;
            }
          }
          if(Math.ceil(hoursWorkedForLabel) > maxBarValue) {
            maxBarValue = Math.ceil(hoursWorkedForLabel);
          }

          //label value
          hoursWorkedData.push(hoursWorkedForLabel > 0 ? parseFloat(hoursWorkedForLabel.toFixed(2)) : '');
          tipsData.push(tipsForLabel > 0 ? parseFloat(tipsForLabel.toFixed(2)) : '');
          tipsPerHourData.push((isNaN(tipsWithHoursForLabel) || isNaN(hoursWorkedForLabel) || Math.abs(hoursWorkedForLabel) < 0.0001) ? '0.00' : (tipsWithHoursForLabel/hoursWorkedForLabel).toFixed(2));

          addedFinalBar = indexDatestring === endDatestring;
          indexDate.setMonth(indexDate.getMonth()+1); //this causes the date to wrap around to january if the month is >= 12
          indexDatestring = getDatestring(indexDate) 
        }
      }
    } else if(inputBarDuration === 'Yearly') {
      const entriesPerBar = {};
      let earliestEntry = null;
      let latestEntry = null;
      // create a hash map with a datetime string for each entry, find earliest and latest entries
      for(const entry of entriesInRange) {
        const date = new Date(Entry.getStartTimestamp(entry));
        const datestring = format(date, 'yyyy');
        if(typeof entriesPerBar[datestring] !== 'object') {
          entriesPerBar[datestring] = [];
        }
        entriesPerBar[datestring].push(entry);

        if(earliestEntry === null || Entry.getStartTimestamp(entry) < Entry.getStartTimestamp(earliestEntry)) {
          earliestEntry = entry;
        }
        if(latestEntry === null || Entry.getStartTimestamp(entry) > Entry.getStartTimestamp(latestEntry)) {
          latestEntry = entry;
        }
      }

      if(earliestEntry !== null && fromDate.getTime() <= toDate.getTime()) {
        const endDatestring = format(toDate, 'yyyy');
        
        // Create dataset, equal length arrays of labels and corresponding values
        let indexDate = new Date(fromDate.getTime());
        let indexDatestring = format(fromDate, 'yyyy'); 
        let addedFinalBar = false;
        while(!addedFinalBar) {
          //label
          newGraphLabels.push(indexDatestring);

          let hoursWorkedForLabel = 0;
          let tipsForLabel = 0;
          let tipsWithHoursForLabel = 0; // these are tips for entries that also have information about hours worked (we need this for tips / hours calculations)
          if(typeof entriesPerBar[indexDatestring] === 'object') {
            for(const entry of entriesPerBar[indexDatestring]) {
              const entryWorkedHours = Entry.getHoursWorked(entry);
              hoursWorkedForLabel += entryWorkedHours;
              totalHoursWorked += entryWorkedHours;
              totalEntriesCount += 1;
              const totalTipsForEntry = Entry.getTotalTips(entry);
              totalTips += totalTipsForEntry;
              tipsForLabel += totalTipsForEntry;
              tipsWithHoursForLabel += (Math.abs(entryWorkedHours) >= 0.001) ? totalTipsForEntry : 0;
              totalTipsWithHours += (Math.abs(entryWorkedHours) >= 0.001) ? totalTipsForEntry : 0;
            }
          }
          if(Math.ceil(hoursWorkedForLabel) > maxBarValue) {
            maxBarValue = Math.ceil(hoursWorkedForLabel);
          }

          //label value
          hoursWorkedData.push(hoursWorkedForLabel > 0 ? parseFloat(hoursWorkedForLabel.toFixed(2)) : '');
          tipsData.push(tipsForLabel > 0 ? parseFloat(tipsForLabel.toFixed(2)) : '');
          tipsPerHourData.push((isNaN(tipsWithHoursForLabel) || isNaN(hoursWorkedForLabel) || Math.abs(hoursWorkedForLabel) < 0.0001) ? '0.00' : (tipsWithHoursForLabel/hoursWorkedForLabel).toFixed(2));

          addedFinalBar = indexDatestring === endDatestring;
          indexDate.setFullYear(indexDate.getFullYear()+1);
          indexDatestring = format(indexDate, 'yyyy'); 
        }
      }
    }

    const dataSets = [];
    if(chartShowingTips) {
      dataSets.push({
        label: 'Tips',
        data: tipsData,
        backgroundColor: userData.darkModeEnabled ? '#00ff00' : '#008800',
        yAxisID: 'yAxis2'
      });
    }
    if(chartShowingHours) {
      dataSets.push({
        label: 'Hours',
        data: hoursWorkedData,
        backgroundColor: userData.darkModeEnabled ? '#00b8c1' : '#1c779c',
        yAxisID: 'yAxis1'
      });
    }
    if(chartShowingTipsPerHour) {
      dataSets.push({
        label: 'Tips / Hour',
        data: tipsPerHourData,
        backgroundColor: userData.darkModeEnabled ? '#ffff00' : '#ff7700',
        yAxisID: 'yAxis3'
      });
    }
    if(dataSets.length === 0) {
      dataSets.push({}); //keep the chart from dissapearing by giving it one empy element
    }

    setChartData({
      labels: newGraphLabels,
      datasets: dataSets
    });
    setChartOptions({
      plugins: {
        legend: {
          display: false
        },
      },
      scales: {
        ...(chartShowingTips ? {
          yAxis2: {
            position: 'left',
            beginAtZero: true,
            ticks: {
              color: userData.darkModeEnabled ? '#00ff00' : '#008800',
              callback: function(value, index, values) {
                return '$' + value
              },
              font: {
                weight: 'bold'
              },
            }
            //max: totalEntriesCount === 0 ? 10 : maxBarValue,
          },
        } : {}),
        ...(chartShowingHours ? {
          yAxis1: {
            position: 'right',
            beginAtZero: true,
            ticks: {
              color: userData.darkModeEnabled ? '#00b8c1' : '#1c779c',
              font: {
                weight: 'bold'
              },
            },
            grid: {
              display: false
            },
            //max: totalEntriesCount === 0 ? 10 : maxBarValue,
          }
        }: {}),
        ...(chartShowingTipsPerHour ? {
          yAxis3: {
            position: 'right',
            beginAtZero: true,
            ticks: {
              color: userData.darkModeEnabled ? '#ffff00' : '#ff7700',
              font: {
                weight: 'bold'
              },
            },
            grid: {
              display: false
            },
            //max: totalEntriesCount === 0 ? 10 : maxBarValue,
          }
        }: {}),
      },
      //responsive: true,
      maintainAspectRatio: false,
      //aspectRatio: 1.5,
    });

    setSummaryHoursWorked(totalHoursWorked.toFixed(2));
    setSummaryEntriesCount(totalEntriesCount);
    setSummaryCashCreditTips(totalTips);
    setSummaryTipsPerHour((isNaN(totalHoursWorked) || isNaN(totalTipsWithHours) || Math.abs(totalHoursWorked) < 0.0001) ? '0.00' : (totalTipsWithHours/totalHoursWorked).toFixed(2));
    setInputFromDate(formatISO(fromDate));
    setInputToDate(formatISO(toDate));
  }, [userData, inputBarDuration, chartShowingTips, chartShowingHours, chartShowingTipsPerHour]);

  const setTimestampsForDateRange = useCallback((dateRange) => {
    const newFromDate = new Date();
    const newToDate = new Date();
    if(dateRange === 'This Week') {
      newFromDate.setDate(newFromDate.getDate() - newFromDate.getDay()); //set to start of week
      newToDate.setTime(newFromDate.getTime());
      newToDate.setDate(newToDate.getDate() + 6); //set to last day of week
      setFromTimestamp(newFromDate.getTime());
      setToTimestamp(newToDate.getTime());
    } else if(dateRange === 'Last Week') {
      newFromDate.setDate(newFromDate.getDate() - newFromDate.getDay() - 7); //set to start of last week
      newToDate.setTime(newFromDate.getTime());
      newToDate.setDate(newToDate.getDate() + 6); //set to last day of week
      setFromTimestamp(newFromDate.getTime());
      setToTimestamp(newToDate.getTime());
    } else if(dateRange === 'This Month') {
      newFromDate.setDate(1);
      newToDate.setDate(1);
      newToDate.setMonth(newToDate.getMonth()+1);
      newToDate.setDate(0);
      setFromTimestamp(newFromDate.getTime());
      setToTimestamp(newToDate.getTime());
    } else if(dateRange === 'Last Month') {
      newFromDate.setDate(1);
      newFromDate.setMonth(newFromDate.getMonth()-1);
      newToDate.setDate(0); // last day of last month
      setFromTimestamp(newFromDate.getTime());
      setToTimestamp(newToDate.getTime());
    } else if(dateRange === 'This Year') {
      newFromDate.setDate(1);
      newFromDate.setMonth(0);
      newToDate.setDate(1);
      newToDate.setMonth(12);
      newToDate.setDate(0);
      setFromTimestamp(newFromDate.getTime());
      setToTimestamp(newToDate.getTime());
    } else if(dateRange === 'All Entries') { 
      if(userData.entries.length > 0) {
        let minTimestamp = Number.MAX_VALUE;
        let maxTimestamp = 0;
        for(const entry of userData.entries) {
          if(Entry.getStartTimestamp(entry) < minTimestamp) {
            minTimestamp = Entry.getStartTimestamp(entry);
          }
          if(Entry.getStartTimestamp(entry) > maxTimestamp) {
            maxTimestamp = Entry.getStartTimestamp(entry);
          }
        }
        newFromDate.setTime(minTimestamp);
        newToDate.setTime(maxTimestamp);
        setFromTimestamp(newFromDate.getTime());
        setToTimestamp(newToDate.getTime());
      } else {
        setFromTimestamp(new Date().getTime());
        setToTimestamp(new Date().getTime());
      }
    } else if(dateRange === 'Custom Range') { 
      //newFromDate.setTime(fromTimestamp);
      //newToDate.setTime(toTimestamp);
      //setFromTimestamp(newFromDate.getTime());
      //setToTimestamp(newToDate.getTime());
    }
  }, [userData.entries]);

  const startTimeElement = useMemo(() => (
    <div className={'flex justify-center items-center ' + (showHeader === false ? 'flex-row flex-row-reverse' : 'flex-col ')}>
      <TipzDatetimePickerButtons 
        hidden={hidden}
        label=''
        dateOnly={true}
        inputValue={inputFromDate}
        onInit={(timestamp) => {}} 
        onClosed={(timestamp) => {
          const date = new Date();
          date.setTime(parseInt(parseInt(timestamp / 1000)*1000));
          date.setHours(0, 0, 0);
          setFromTimestamp(timestamp);
          setInputDateRange('Custom Range');
        }}
      />
      <div className='leading-6 font-bold'>Start Date{showHeader === false ? ':' : ''}</div>
    </div>
  ), [inputFromDate, hidden, showHeader]);

  const endTimeElement = useMemo(() => (
    <div className={'flex justify-center items-center ' + (showHeader === false ? 'flex-row flex-row-reverse' : 'flex-col ')}>
      <TipzDatetimePickerButtons label='' dateOnly={true} inputValue={inputToDate}
        hidden={hidden}
        onInit={(timestamp) => {}} 
        onClosed={(timestamp) => {
          const date = new Date();
          date.setTime(parseInt(parseInt(timestamp / 1000)*1000));
          date.setHours(0, 0, 0);
          setToTimestamp(timestamp);
          setInputDateRange('Custom Range');
        }}
      />
      <div className='leading-6 font-bold'>End Date (inclusive){showHeader === false ? ':' : ''}</div>
    </div>
  ), [inputToDate, hidden, showHeader]);

  const setInputBarDurationForAllEntries = (entries) => {
    let earliestEntry = null;
    let latestEntry = null;
    for(const entry of userData.entries) {
      if(earliestEntry === null || Entry.getStartTimestamp(entry) <  earliestEntry.startTimestamp) {
        earliestEntry = {
          entry: entry,
          startTimestamp: Entry.getStartTimestamp(entry),
        };
      }
      if(latestEntry === null || Entry.getStartTimestamp(entry) >  latestEntry.startTimestamp) {
        latestEntry = {
          entry: entry,
          startTimestamp: Entry.getStartTimestamp(entry),
        };
      }
    }
    if(earliestEntry === null || latestEntry === null) { // abort if we haven't found both an earliest and latest entry
      return;
    }

    const oneMonthInMillis = (1000*60*60*24*30);
    const oneWeekInMillis = (1000*60*60*24*7);
    const entryTotalRangeInMillis = latestEntry.startTimestamp - earliestEntry.startTimestamp;

    if (entryTotalRangeInMillis < oneWeekInMillis) { // all of the user's entries span less than one week
      setInputBarDuration(barDurationChoice[0]); // set the chart's bar duration to one day
    } else if(entryTotalRangeInMillis < oneMonthInMillis) {
      setInputBarDuration(barDurationChoice[1]); // set the chart's bar duration to one week
    } else { // all of the user's entries span more than on month
      setInputBarDuration(barDurationChoice[2]); // set the chart's bar duration to one month
    }
  }

  useEffect(() => {
    setInputBarDurationForAllEntries(userData.entries);
  }, []);

  useEffect(() => {
    setTimestampsForDateRange(inputDateRange);
  }, [inputDateRange, setTimestampsForDateRange]);

  useEffect(() => {
    if(hidden) {
      return;
    }

    ChartJS.defaults.color = userData.darkModeEnabled ? '#fff' : '#000';
    ChartJS.defaults.borderColor = userData.darkModeEnabled ? '#222' : '#ccc';
    ChartJS.defaults.responsive = true;
    if(fromTimestamp === undefined || toTimestamp === undefined) {
      setTimestampsForDateRange(dateRangeChoices[5]);
    } else {
      const newFromDate = new Date();
      const newToDate = new Date();
      newFromDate.setTime(fromTimestamp);
      newToDate.setTime(toTimestamp);
      updateChart(newFromDate, newToDate);
    }
  }, [fromTimestamp, toTimestamp, updateChart, userData.darkModeEnabled, dateRangeChoices, setTimestampsForDateRange, inputBarDuration, hidden]);

  const resizeChart = () => {
    const chart = ChartJS.getChart('barChart');
    if(chartRef.current !== null && chart && chartContainer.current !== null) {
      //chartRef.current.chartInstance.resize()
      //setChartOptions({
      //  aspectRatio: chartContainer.current.offsetWidth/chartContainer.current.offsetHeight,
      //});
      //chartRef.current.resize(chartContainer.current.offsetWidth, chartContainer.current.offsetHeight)

      setHideChart(true);
      chart.resize(chartContainer.current.offsetWidth, chartContainer.current.offsetHeight);
      setTimeout(() => {
        setHideChart(false);
      }, 1);

      //ChartJS.getChart('barChart').render();
      //console.log(chartContainer.current.offsetWidth, chartContainer.current.offsetHeight);
      //console.log(typeof chartContainer.current.offsetWidth,typeof  chartContainer.current.offsetHeight);
      //console.log(chartContainer.current.getBoundingClientRect());
      //for(let id in ChartJS.instances) {
      //  ChartJS.instance[id].resize();
      //}
      //ChartJS.resize();
    }
  };

  useEffect(() => {
    resizeChart();
    window.addEventListener('resize', resizeChart);
    return () => {
      window.removeEventListener('resize', resizeChart);
    };
  }, []);

  useEffect(() => {
    if(inputDateRange === 'Custom Range') {
      resizeChart();
    }
  }, [inputDateRange]);

  useEffect(() => {
    const onPopState = (event) => {
      if(!hidden) {
        onFinished();
      }
    };

    window.addEventListener('popstate', onPopState);
    return () => {
      window.removeEventListener('popstate', onPopState);
    };
  }, [hidden, onFinished]);

  const getHeader = useCallback(() => {
    console.log('render main header');
    return (
      <IonToolbar color='primary'>
        <div className='flex justify-evenly'>
          <TipzButton 
            onClick={() => {
              window.history.back();
            }}
          >
            <IonIcon icon={arrowBack} className='text-xl align-middle' /> Back
          </TipzButton>
          <div className='grow-[1]'></div>
          <IonTitle className=''>Totals</IonTitle>
          <div className='grow-[1]'></div>
          <div className='mr-2'>
            <TipzLogoMinimal darkMode={false} className='h-[40px]' />
          </div>
        </div>
      </IonToolbar>
    );
  }, []);

  const headerJSX = useMemo(() => {
    return getHeader();
  }, [getHeader]);

  const getContent = useCallback(() => {
    console.log('render main content');
    const getNarrowTotalCard = () => (
      <div className={'py-4 rounded-lg shadow-large ' + (showHeader ? ' ' : '')}>
        <div className={'mx-3 flex flex-col ' + (showHeader ? 'justify-start items-start': 'justify-center items-center')}>

          {/* narrow screen select date range  */}
          {(!isNaN(fromTimestamp) && !isNaN(toTimestamp) && showHeader) &&
            <div className={'mb-3 w-full flex flex-col gap-y-1'}>
              <div className='font-bold text-2xl flex gap-x-2 items-center'>
                <div className=''>My Totals for</div>
                <div className=''>
                  <TipzSelect 
                    className='noLabel'
                    items={dateRangeChoices} 
                    error='' 
                    inlineLabel={true} 
                    inputValue={inputDateRange}
                    interfaceType={showHeader ? 'action-sheet' : 'popover'}
                    onInput={(value) => { 
                      setInputDateRange(value);
                      if(value === 'This Week' || value === 'Last Week') {
                        setInputBarDuration(barDurationChoice[0]);
                      } else if(value === 'This Month' || value === 'Last Month') {
                        setInputBarDuration(barDurationChoice[1]);
                      } else if(value === 'This Year') {
                        setInputBarDuration(barDurationChoice[2]);
                      } else if(value === 'All Entries') {
                        setInputBarDurationForAllEntries(userData.entries);
                      }
                  }} /> 
                </div>
              </div>
              <div className='font-bold leading-5 tipzCardSubtitle'>Date Range: {format(fromTimestamp, 'MMM d, yyyy')} -- {format(toTimestamp, 'MMM d, yyyy')}</div>
            </div>
          }

          {/* custom range date selects  */}
          {inputDateRange === 'Custom Range' && 
            <div className='flex mb-5 whitespace-nowrap'>
              {startTimeElement}
              {endTimeElement}
            </div>
          }

          {/* narrow screen totals: tips, hours, entries  */}
          <div className={'text-left ' + (showHeader ? '' : ' hidden')}>
            <div className={'text-left flex justify-start items-center gap-x-2 leading-5'}>
              <strong>Entries:</strong>
              <p className='leading-3'>{summaryEntriesCount}</p>
            </div>
            <div className='text-left flex justify-start items-center gap-x-2 leading-5'>
              <strong>Tips:</strong>
              <div className='flex items-center'>
                <p>$</p><p className='leading-3 tipzMoney'>{Util.getCurrencyString(summaryCashCreditTips, true)}</p>
              </div>
            </div>
            <div className='text-left flex justify-start items-center gap-x-2 leading-5'>
              <strong>Hours:</strong>
              <p className='leading-5 whitespace-nowrap tipzHours'>{summaryHoursWorked}</p>
            </div>
            <div className='text-left flex justify-start items-center gap-x-2 leading-5'>
              <strong>Tips / Hour:</strong>
              <p className='leading-5 whitespace-nowrap tipzPerHour'>{summaryTipsPerHour}</p>
            </div>
          </div>
          
        </div>
      </div>
    );
    const getWideTotalCard = () => (
      <div className={'py-0 flex flex-col rounded-lg shadow-large m-2'}>
        <div className={'flex justify-start items-center gap-x-8'}>

          {/* wide screen select date range  */}
          {(!isNaN(fromTimestamp) && !isNaN(toTimestamp)) &&
            <div className={'flex flex-col justify-center items-start flex-col-reverse gap-x-4'}>
              {inputDateRange !== 'Custom Range' && <div className='font-bold leading-5 tipzCardSubtitle'>Date Range: {format(fromTimestamp, 'MMM d, yyyy')} -- {format(toTimestamp, 'MMM d, yyyy')}</div>}
              <div className='font-bold text-2xl flex gap-x-2 items-center'>
                <div className=''>My Totals for</div>
                <div className=''>
                  <TipzSelect 
                    className='noLabel'
                    items={dateRangeChoices} 
                    error='' 
                    inlineLabel={true} 
                    inputValue={inputDateRange}
                    interfaceType={showHeader ? 'action-sheet' : 'popover'}
                    onInput={(value) => { 
                      setInputDateRange(value);
                      if(value === 'This Week' || value === 'Last Week') {
                        setInputBarDuration(barDurationChoice[0]);
                      } else if(value === 'This Month' || value === 'Last Month') {
                        setInputBarDuration(barDurationChoice[1]);
                      } else if(value === 'This Year') {
                        setInputBarDuration(barDurationChoice[2]);
                      } else if(value === 'All Entries') {
                        setInputBarDurationForAllEntries(userData.entries);
                      }
                  }} /> 
                </div>
              </div>
            </div>
          }

          {/* wide screeen totals: tips, hours, entries  */}
          <div className={'text-left ' + (showHeader ? ' hidden ' : ' flex justify-start gap-x-8')}>
            <div className={'text-left flex flex-col justify-start items-center gap-x-2 leading-7 text-lg lg:text-xl'}>
              <strong>Entries</strong>
              <p className='leading-3'>{summaryEntriesCount}</p>
            </div>
            <div className='text-left flex flex-col justify-start items-center gap-x-2 leading-7 text-lg lg:text-xl'>
              <strong>Tips</strong>
              <div className='flex items-center'>
                <p>$</p><p className='leading-3 tipzMoney'>{Util.getCurrencyString(summaryCashCreditTips, true)}</p>
              </div>
            </div>
            <div className='text-left flex flex-col justify-start items-center gap-x-2 leading-7 text-lg lg:text-xl'>
              <strong>Hours</strong>
              <p className='leading-5 whitespace-nowrap tipzHours'>{summaryHoursWorked}</p>
            </div>
            <div className='text-left flex flex-col justify-start items-center gap-x-2 leading-7 text-lg lg:text-xl'>
              <strong>Tips / Hour</strong>
              <p className='leading-5 whitespace-nowrap tipzPerHour'>{summaryTipsPerHour}</p>
            </div>
          </div>
          
        </div>

        {/* custom range date selects  */}
        {inputDateRange === 'Custom Range' && 
          <div className='flex justify-start whitespace-nowrap gap-x-6'>
            {startTimeElement}
            {endTimeElement}
          </div>
        }
      </div>
    );
    return (
      <div className='h-full mx-auto'>
        <div className={`flex flex-col gap-y-2 h-full`}>
          <div className='grow-[1] flex flex-col justify-center'>

            {/* totals card and select entry range  */}
            {showHeader ? getNarrowTotalCard() : getWideTotalCard()}
          </div>
          <div className='grow-[1] flex flex-col'>
            <div className={'flex w-full items-end ' + (showHeader ? 'justify-start' : 'justify-around')}>
              <div className={'w-full flex ' + (showHeader ? 'flex-col items-start' : 'flex-row justify-start items-center ') + ' gap-2 mb-2'}>

                  {/* select bar duration */}
                  <div className='font-bold flex items-center gap-x-2 mx-2'>
                    <div className=''><span className='underline font-bold'>{inputDateRange}</span> Charted</div>
                    <div className=''>
                      <TipzSelect 
                        items={barDurationChoice} 
                        error='' 
                        inlineLabel={true} 
                        inputValue={inputBarDuration}
                        interfaceType={showHeader ? 'action-sheet' : 'popover'}
                        onInput={(value) => { 
                          setInputBarDuration(value);
                      }} /> 
                    </div>
                  </div>

                  {/* show tips / show hours checkboxes */}
                  <div className={'flex ' + (showHeader ? '': 'justify-end ') + ' gap-x-4 mx-1'}>
                    <div>
                      <IonCheckbox 
                        {...(chartShowingTips ? {checked:''} : {})} 
                        className='tipzMoney'
                        onIonChange={(event) => {
                          setChartShowingTips(event.detail.checked);
                        }}
                       >Show Tips
                       </IonCheckbox>
                    </div>
                    <div>
                      <IonCheckbox 
                        {...(chartShowingHours ? {checked:''} : {})} 
                        className='tipzHours'
                        onIonChange={(event) => {
                          setChartShowingHours(event.detail.checked);
                        }}
                       >Hours
                       </IonCheckbox>
                    </div>
                    <div>
                      <IonCheckbox 
                        {...(chartShowingTipsPerHour ? {checked:''} : {})} 
                        className='tipzPerHour'
                        onIonChange={(event) => {
                          setChartShowingTipsPerHour(event.detail.checked);
                        }}
                       >Tips / Hour
                       </IonCheckbox>
                    </div>
                  </div>

              </div>
            </div>
            <div ref={chartContainer} className='grow-[1]'>
              {hideChart !== true &&
                <Bar id='barChart' ref={chartRef} data={chartData} options={chartOptions} className='' />
              }
            </div>
          </div>
        </div>
      </div>
    );
  }, [inputDateRange, summaryEntriesCount, summaryHoursWorked, chartData, chartOptions, dateRangeChoices, endTimeElement, startTimeElement, summaryCashCreditTips, inputBarDuration, barDurationChoice, fromTimestamp, toTimestamp, chartShowingTips, chartShowingHours, chartShowingTipsPerHour, userData.entries, hideChart, showHeader, summaryTipsPerHour]);
  
  const contentJSX = useMemo(() => {
    return getContent();
  }, [getContent]);

  const elementJSX = useMemo(() => (
    <>
      {showHeader === true && 
        <IonHeader className={hidden === true ? 'hidden' : ''}>
          {headerJSX}
        </IonHeader>
      }
      <IonContent className={hidden === true ? 'hidden' : ''}>
        {contentJSX}
      </IonContent>
    </>
  ), [headerJSX, contentJSX, hidden, showHeader]);

  return (
    <>
      {elementJSX}
    </>
  );
};

export default TipzSummary;

//const noAnimation = {
//  duration: 0,
//  direction: 'none',
//  easing: 'linear',
//  enterAnimation: () => Promise.resolve(),
//  leaveAnimation: () => Promise.resolve()
//};


