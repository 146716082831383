///* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
///* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css'; // Remove if nothing is visible
import '@ionic/react/css/typography.css';
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
/* Dark mode CSS */
import '@ionic/react/css/palettes/dark.class.css';

/* Custom Tipz CSS */
import './App.scss';

import axios from 'axios'
import { useEffect, createContext } from 'react';
import { setupIonicReact, useIonAlert } from '@ionic/react';
import useUserData from './hooks/useUserData';
import TipzSetup from './TipzSetup';
import LoadingScreen from './LoadingScreen';
import TipzMain from './TipzMain'
import TipzLogin from './TipzLogin'
import { useState, useRef } from 'react';
//import Logger from './hooks/Logger'

setupIonicReact({
  mode: 'ios',
//  mode: 'md',
});

//export const UserDataContext = createContext();

function App() {
  const userData = useUserData();
  const pageLoaded = useRef(false);
  const loadedLocation = useRef('');
  const [ionAlert] = useIonAlert();

  useEffect(() => {
    document.title = 'Tipz';
    loadedLocation.current = window.location.href;
    window.history.replaceState('menu', '', ' ');
  }, []);

  useEffect(() => {
    if(userData.initialized && pageLoaded.current === false) {
      pageLoaded.current = true;

      console.log('-> pageLoad');
      if(process.env.REACT_APP_LIVE_MODE === 'true') {
        axios.post(process.env.REACT_APP_WEBSITE_ACTION_API_URL, {
          'action': 'pageLoad',
          ...(userData.email.length > 0 ? {'email': userData.email} : {}),
          ...(userData.sessionKey.length > 0 ? {'sessionKey': userData.sessionKey} : {}),
          'page': loadedLocation.current,
          'prevPage': '',
          'referer': document.referrer,
          'data': ''
        }).then((response) => {
          try {
            if (response.status !== 200) {
              throw "non 200 http response code";
            }
            if ('status' in response.data !== true 
              || 'data' in response.data !== true) {
              throw 'bad response object';
            }
            if(response.data.status === 'error' 
              && typeof response.data.data === 'object'
              && 'clientMessage' in response.data.data && typeof response.data.data.clientMessage === 'string'
              && response.data.data.clientMessage.length > 0) {
              throw { 'modalMessage' : response.data.data.clientMessage };
            }

            //user has an outdated or incorrect session key, make them login again
            if(response.data.status === 'success' 
              && 'action' in response.data.data === true
              && response.data.data.action === 'badSessionKey') {
              ionAlert({
                header: 'Session Expired',
                message: 'You session has expired, you\'ll need to login again to continue.',
                buttons: [
                  {
                    text: 'Ok',
                    handler: () => {
                      userData.logout(); 
                    }
                  }
                ]
              });
              return;
            }

            if(response.data.status !== 'success' 
              || 'action' in response.data.data !== true
              || response.data.data.action !== 'pageLoadSuccess') {
              throw 'non success response from server';
            }

            // successfully sent pageload
          } catch (error) {
            console.log('error processing pageLoad response');
          }
        }).catch((error) => {
          console.log('error sending pageLoad');
        })
      }
    }
  }, [userData.initialized]);

  useEffect(() => {
    console.log('app userData change');
  }, [userData]);

  return (
  <>
    {!userData.initialized &&
      <LoadingScreen />
    }
    {userData.initialized && !userData.isLoggedIn &&
      <TipzLogin userData={userData} />
    }
    {userData.initialized && userData.isLoggedIn && userData.needsSetup &&
      <TipzSetup userData={userData} />
    }
    {userData.initialized && userData.isLoggedIn && !userData.needsSetup &&
      <TipzMain userData={userData} />
    }
  </>
  )
}

export default App;

//    {!userData.initialized &&
//      <LoadingScreen />
//    }
//    {userData.initialized && !userData.isLoggedIn &&
//      <TipzLogin userData={userData} />
//    }
//    {userData.initialized && userData.isLoggedIn && userData.needsSetup &&
//      <TipzSetup userData={userData} />
//    }
//    {userData.initialized && userData.isLoggedIn && !userData.needsSetup &&
//      <TipzMain userData={userData} />
//    }
