import { IonApp, IonIcon, IonFooter, IonPage, IonModal, IonDatetime, IonContent, IonButton, IonLabel, IonFab, IonFabButton, IonLoading, useIonAlert, IonPopover } from '@ionic/react';
import { useState, useEffect, useRef } from 'react';
import { image, trash, save, arrowBack, addCircle, pencil } from 'ionicons/icons';
import axios from 'axios'
import TipzLogo from './TipzLogo';

function TipzLogin({userData}) {
  const [ionAlert] = useIonAlert();

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [isRequestingLogin, setIsRequestingLogin] = useState(false);

  const aboutButtonRef = useRef(false);
  const [aboutTipzShowing, setAboutTipzShowing] = useState(false);

  const loginCodeRef = useRef(false);

  const [loginCode, setLoginCode] = useState('');
  const [loginCodeError, setLoginCodeError] = useState('');
  const [isLoginCodeSent, setIsLoginCodeSent] = useState(false);
  const [isCheckingLoginCode, setIsCheckingLoginCode] = useState(false);

  const showAlert = (message) => {
    ionAlert({
      header: 'Error',
      message: message,
      buttons: [
        {
          text: 'Ok',
        }
      ]
    });
  };

  const getSubmitJsx = () => {
    return (
      <button
        className="bg-[#00ff00] hover:scale-[1.04] hover:bg-[#ffff00] active:scale-[1] select-none transition duration-100 text-[#151515] font-bold py-3 px-5 rounded-md focus:outline-none focus:shadow-outline"
        type="submit"
      >
        Submit
      </button>
    );

    //<IonButton 
    //  className='hidden font-bold'
    //  type="submit"
    //  onClick={() => {
    //  }}
    //>
    //  <IonLabel>Submit</IonLabel>
    //</IonButton>
  };

  const onSubmitEmail = (e) => {
    e.preventDefault();

    // Email validation regex pattern
    const emailPattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/ //   /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Check if the entered email matches the pattern
    if (!emailPattern.test(email)) {
      setEmailError('Invalid email');
      return; // Prevent further execution
    }

    // Clear email error if validation succeeds
    setEmailError('');

    setIsRequestingLogin(true);
    axios.post(process.env.REACT_APP_WEBSITE_ACTION_API_URL, {
      'action': 'login',
      'email': email
    }).then((response) => {
      try {
        if (response.status !== 200) {
          throw "non 200 http response code";
        }
        if ('status' in response.data !== true 
          || 'data' in response.data !== true) {
          throw 'bad response object';
        }
        if(response.data.status === 'error' 
          && typeof response.data.data === 'object'
          && 'clientMessage' in response.data.data && typeof response.data.data.clientMessage === 'string'
          && response.data.data.clientMessage.length > 0) {
          throw { 'modalMessage' : response.data.data.clientMessage };
        }
        if(response.data.status !== 'success' 
          || 'action' in response.data.data !== true
          || (response.data.data.action !== 'loginCodeEmailed' && response.data.data.action !== 'loginCodeExists')) {
          throw 'non success response from server';
        }

        //success
        setIsLoginCodeSent(true);
        setIsRequestingLogin(false);
      } catch(error) {
        if(typeof error === 'object' && 'modalMessage' in error) {
          showAlert(error.modalMessage);
        } else {
          showAlert('Something went wrong when attempting to login. Please try again. Reload the page and try again if the problem persists.');
        }
        setIsLoginCodeSent(false);
        setIsRequestingLogin(false);
        //logger.addError('error doing stuff ' + error)
      }
    }).catch((error) => {
      showAlert('Couldn\'t contact the server. Please try again. Reload the page and try again if the problem persists.');
      setIsLoginCodeSent(false);
      setIsRequestingLogin(false);
    });
  };

  const onSubmitLoginCode = (e) => {
    e.preventDefault();

    //login code validation regex pattern
    const shortLoginCodePattern = /^[0-9]+$/;
    const longLoginCodePattern = /^[0-9a-f]+$/;

    // check if the login code format is correct
    if (!(loginCode.length === 6 && shortLoginCodePattern.test(loginCode)) && !(loginCode.length === 256 && longLoginCodePattern.test(loginCode))) {
      setLoginCodeError('Invalid code');
      return; // Prevent further execution
    }

    // clear error because login code format is correct
    setLoginCodeError('');

    setIsCheckingLoginCode(true);
    axios.post(process.env.REACT_APP_WEBSITE_ACTION_API_URL, {
      'action': 'loginCode',
      'email': email,
      'loginCode': loginCode
    }).then((response) => {
      try {
        if (response.status !== 200) {
          throw "non 200 http response code";
        }
        if ('status' in response.data !== true 
          || 'data' in response.data !== true) {
          throw 'bad response object';
        }
        if(response.data.status === 'error' 
          && typeof response.data.data === 'object'
          && 'clientMessage' in response.data.data && typeof response.data.data.clientMessage === 'string'
          && response.data.data.clientMessage.length > 0) {
          throw { 'modalMessage' : response.data.data.clientMessage };
        }
        if(response.data.status !== 'success' 
          || 'action' in response.data.data !== true || response.data.data.action !== 'loginSuccess'
          || 'sessionKey' in response.data.data !== true || typeof response.data.data.sessionKey !== 'string'
          || 'settings' in response.data.data !== true || typeof response.data.data.settings !== 'string'
          || 'entries' in response.data.data !== true || typeof response.data.data.entries !== 'string'
          ) {
          throw 'non success response from server';
        }

        const settingsFromServer = JSON.parse(response.data.data.settings);
        const entriesFromServer = JSON.parse(response.data.data.entries);

        //success, logged in now
        setIsCheckingLoginCode(false);
        const tokenExpiresAtTimestamp = (new Date().getTime()) + (1000 * 60 * 60 * 24 * 30); //token  expires in 30 days
        userData.saveDarkModeEnabled(settingsFromServer.darkMode || false);
        userData.saveEntries(entriesFromServer);
        userData.saveUsername(settingsFromServer.username || '');
        userData.saveResteraunts(settingsFromServer.resteraunts || []);
        userData.saveJobs(settingsFromServer.jobs || []);
        userData.saveEmail(email);
        userData.saveSessionKey(response.data.data.sessionKey, tokenExpiresAtTimestamp); //save token for future API calls
      } catch(error) {
        if(typeof error === 'object' && 'modalMessage' in error) {
          showAlert(error.modalMessage);
        } else {
          showAlert('Something went wrong verifying your login code. Please try again. Reload the page and try again if the problem persists.');
        }
        setIsCheckingLoginCode(false);
        //logger.addError('error doing stuff ' + error)
      }
    }).catch((error) => {
      showAlert('Couldn\'t contact the server. Please try again. Reload the page and try again if the problem persists.');
      setIsCheckingLoginCode(false);
    });
  };

  //useEffect(() => {
  //  const onClick = (event) => {
  //    setAboutTipzShowing(event.target === aboutButtonRef.current);
  //  };
  //  window.addEventListener('click', onClick);
  //  return () => {
  //    window.removeEventListener('click', onClick);
  //  }
  //}, [aboutTipzShowing]);

  useEffect(() => {
    if(isLoginCodeSent && loginCodeRef.current) {
      loginCodeRef.current.focus();
    }
  }, [isLoginCodeSent]);

  return (
    <IonApp>
      <IonPage>
        <IonContent>
          <div className="relative flex flex-col justify-start items-center h-screen bg-cover bg-center gap-y-8 pt-12">
            {/*<div className='top-0 left-0 absolute w-full h-full bg-cover' style={{ backgroundImage: 'url("/images/palm-trees.jpg")' }}></div>*/}
            {/*<div className={'absolute top-0 left-0 w-full h-full bg-black opacity-50'}></div>*/}
            <TipzLogo className='h-[100px]' darkMode={userData.darkModeEnabled} />
            {!isLoginCodeSent && 
              <div className="relative bg-white bg-opacity-80 shadow-md rounded-xl px-8 mx-4 pt-2 pb-4 mb-4 w-[300px] flex flex-col gap-y-6">
                <form onSubmit={onSubmitEmail}>
                  <div className="mb-4">
                    <h2 className="text-2xl py-3 text-center text-gray-800 font-bold">Log In / Sign Up</h2>
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                        Email
                    </label>
                    <input
                      className={`shadow appearance-none border rounded-md w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${emailError ? 'border-red-500' : ''}`}
                      id="email"
                      type="email"
                      placeholder=""
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    {emailError && <p className="text-red-500 text-xs italic">{emailError}</p>}
                  </div>
                  <div className="flex flex-col items-center justify-center">
                    {getSubmitJsx()}
                  </div>
                </form>
                <div className='text-right'>
                  <a href={process.env.REACT_APP_LANDING_WEBSITE_DOMAIN} rel='noreferrer' target="_blank" className='inline-block'>
                    <div 
                      className="flex text-black inline-block items-end justify-end underline w-full mt-2 cursor-pointer relative"
                      onClick={(event) => {
                      }}
                    >
                      What is TIPz?
                    </div>
                  </a>
                </div>
                {/*<div className='relative'>
                  <button 
                    ref={aboutButtonRef}
                    className="flex items-end justify-end text-blue-500 underline text-sm w-full mt-2 cursor-pointer relative"
                    onClick={(event) => {
                      setAboutTipzShowing(!aboutTipzShowing);
                    }}
                  >
                    What is Tipz?
                  </button>
                  <div className={'text-black text-left pt-1 ' +  (aboutTipzShowing ? ' flex flex-col' : ' hidden') }>
                    <div className='leading-[1.3rem]'>
                      Tipz assists you in monitoring your tip earnings, setting personal goals, and gauging your progress. To begin, please enter your email address and click "Submit".
                    </div>
                  </div>
                </div>*/}
              </div>
            }
            {isLoginCodeSent && 
              <form onSubmit={onSubmitLoginCode} className="relative bg-white bg-opacity-80 shadow-md rounded-xl mx-4 px-8 pt-4 pb-8 mb-4 w-[300px]">
                <div 
                  className="absolute top-0 left-0 text-black text-sm cursor-pointer p-4 hover:underline flex content-center gap-x-0.5"
                  onClick={(event) => {
                    setIsLoginCodeSent(false);
                  }}
                >
                  <IonIcon icon={arrowBack} className='text-xl align-middle' /> <span></span>
                </div>
                <div className="mb-4">
                  <h2 className="text-2xl py-3 text-center text-gray-800 font-bold">Enter Login Code</h2>
                  <p className='text-black'>We emailed a code to <strong>{email}</strong>. Please enter the code to sign in.</p>
                  <div className='my-4'>
                    <input
                      ref={loginCodeRef}
                      className={`shadow appearance-none border rounded-md w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${loginCodeError ? 'border-red-500' : ''}`}
                      id="loginCode"
                      type="text"
                      placeholder="Login Code"
                      value={loginCode}
                      onChange={(e) => setLoginCode(e.target.value)}
                    />
                    {loginCodeError && <p className="text-red-500 text-xs italic">{loginCodeError}</p>}
                  </div>
                </div>
                <div className="flex items-center justify-center">
                  {getSubmitJsx()}
                </div>
                <div className='leading-5 mt-6 text-black'>Check your spam folder if the login code isn't in your inbox</div>
              </form>
            }
            <IonLoading message='Sending Login Code' isOpen={isRequestingLogin} />
            <IonLoading message='Checking Login Code' isOpen={isCheckingLoginCode} />
          </div>
        </IonContent>
      </IonPage>
    </IonApp>
  );
}

//const TipzMoved = () => {
//  return (
//    <div className="h-screen flex flex-col items-center justify-start bg-cover bg-center text-white text-center px-3">
//
//      <div className='absolute w-full h-full bg-cover bg-center ' style={{backgroundImage: "url('/images/palm-trees.jpg')"}}></div>
//      <div className="absolute bg-black opacity-20"></div>
//
//      <div className="h-[4em]"></div>
//      <TipzLogo className='relative h-[100px]' darkMode={true} />
//      <div className="relative bg-black bg-opacity-50 p-8 md:p-12 rounded-3xl shadow-lg max-w-2xl w-full m-5">
//        <h1 className="font-['Pacifico',cursive] text-5xl text-yellow-300 mb-5">Aloha!</h1>
//        <p className="text-xl mb-8">
//          Tipz has moved to{' '}
//          <a href="https://app.trackyourtips.com" className="text-yellow-300 font-bold hover:text-teal-300">
//            https://app.trackyourtips.com
//          </a>
//          .
//        </p>
//        <button 
//          onClick={() => window.location.href='https://app.trackyourtips.com'}
//          className="bg-yellow-300 text-black py-4 px-8 rounded text-base cursor-pointer transition-colors duration-300 hover:bg-teal-300"
//        >
//          Visit the New Site
//        </button>
//      </div>
//    </div>
//  );
//};

//export default TipzMoved;
export default TipzLogin;
