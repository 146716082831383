
import { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { IonContent, IonTitle, IonToolbar, IonButton, IonItem, IonLabel, IonList, IonSegment, IonSegmentButton, IonIcon } from '@ionic/react';
//import { SortList } from './MaterialIcons';
import TipzSelect from './TipzSelect';
import TipzButton from './TipzButton';
import TipzLogo from './TipzLogo';
import TipzLogoMinimal from './TipzLogoMinimal';
import { formatISO, format } from 'date-fns';
import { chevronBack, chevronForward, calendarNumber, list, arrowBack, addCircle, camera } from 'ionicons/icons';
import Entry from './Entry';
import Util from './Util';

const TipzCalendar = ({userEntries, setEntrySelectionsForModal, showEditModal, showNewEntryModal, resetDate = false}) => {
  const [selectedCalendarDate, setSelectedCalendarDate] = useState(new Date());
  const [tipsForDatestring, setTipsForDatestring] = useState(null); 
  const [entriesForDatestring, setEntriesForDatestring] = useState(null); // hash map string->array, string = formatted date, array = list of entries occuring on that day
  const [totalTipsForMonth, setTotalTipsForMonth] = useState(0); 

  const calendarRef = useRef(null);
  const dragX = useRef(null); // drag stuff is used to detect swipe left/right
  const dragStartX = useRef(null);
  const dragStartTime = useRef(null);

  function getMouseCoords(event) {
    var coords = [];
    if (event.type.indexOf('touch') >= 0) {
      if (event.touches && event.touches.length) { 	// iPhone
        coords[0] = event.touches[0].clientX;
        coords[1] = event.touches[0].clientY;
      } else { 								// all others
        coords[0] = event.clientX;
        coords[1] = event.clientY;
      }
    } else {
      if (event.pageX == null) { // IE case
        var doc = (document.documentElement && document.documentElement.scrollLeft != null) ? document.documentElement : document.body;
        coords[0] = event.clientX + doc.scrollLeft;
        coords[1] = event.clientY + doc.scrollTop;
      } else { // all other browsers
        coords[0] = event.pageX;
        coords[1] = event.pageY;
      }
    }
    return coords;
  };

  const getTotalTipsForMonth = useCallback((date) => {
    const fromDate = new Date(date.getTime());
    fromDate.setDate(1); // set to first day of month
    fromDate.setHours(0, 0, 0); // set to first second of the day

    const toDate = new Date(date.getTime());
    toDate.setDate(1); // set to first day of month to avoid wrapping if for example the date is the 31st and we set the month to February
    toDate.setMonth(toDate.getMonth()+1); // set to next month
    toDate.setDate(0); // set to last day of current month
    toDate.setHours(23, 59, 59); // set to last second of the day

    let totalHoursWorked = 0;
    let totalEntriesCount = 0;
    let totalTips = 0;

    const fromDateTimestamp = fromDate.getTime();
    const toDateTimestamp = toDate.getTime();
    for(const entry of userEntries) {
      const entryStartTime = Entry.getStartTimestamp(entry);
      if(entryStartTime >= fromDateTimestamp && entryStartTime <= toDateTimestamp) {
        totalTips += Entry.getTotalTips(entry);
      }
    }

    return Util.getCurrencyString(totalTips, true);
  }, [userEntries]);

  useEffect(() => {
    setSelectedCalendarDate(new Date());
  }, [resetDate]);

  useEffect(() => {
    const newTipsForDatestring = {};
    const newEntriesForDatestring = {};

    for(const entry of userEntries) {
      const date = new Date(Entry.getStartTimestamp(entry));
      const datestring = format(date, 'MMM d, yyyy');
      const totalTips = Entry.getTotalTips(entry); 
      if(typeof newTipsForDatestring[datestring] !== 'number') {
        newTipsForDatestring[datestring] = totalTips;
      } else {
        newTipsForDatestring[datestring] += totalTips;
      }

      if(typeof newEntriesForDatestring[datestring] !== 'object') {
        newEntriesForDatestring[datestring] = [];
      } 
      newEntriesForDatestring[datestring].push(entry);
    }

    setTipsForDatestring(newTipsForDatestring);
    setEntriesForDatestring(newEntriesForDatestring);
  }, [userEntries]);

  useEffect(() => {
    setTotalTipsForMonth(getTotalTipsForMonth(selectedCalendarDate));

    // setup mouse/touch listeners to detect swipe right/left gestures
    const startFling = (e) => {
      dragX.current = dragStartX.current = getMouseCoords(e)[0];
      dragStartTime.current = new Date().getTime();
    };

    const moveFling = (e) => {
      dragX.current = getMouseCoords(e)[0];
    };

    const endFling = (e) => {
      if(Math.abs(dragStartX.current - dragX.current) > 30 && (new Date().getTime() - dragStartTime.current) < 500) {
        if(dragX.current > dragStartX.current) {
          const newCurrentCalendarDate = new Date(selectedCalendarDate.getTime());
          newCurrentCalendarDate.setDate(1); // set to first day of month before incrementing month. if we're on day 31 and increment to a month with 30 days it will skip a month
          newCurrentCalendarDate.setMonth(newCurrentCalendarDate.getMonth() - 1);
          setSelectedCalendarDate(newCurrentCalendarDate);
        } else {
          const newCurrentCalendarDate = new Date(selectedCalendarDate.getTime());
          newCurrentCalendarDate.setDate(1); // set to first day of month before incrementing month. if we're on day 31 and increment to a month with 30 days it will skip a month
          newCurrentCalendarDate.setMonth(newCurrentCalendarDate.getMonth() + 1);
          setSelectedCalendarDate(newCurrentCalendarDate);
          //if(calendarRef.current !== null) {
          //  calendarRef.current.focus();
          //}
        }
      }
    };

    const savedCalendarRef = calendarRef.current;

    if(calendarRef.current !== null) {
      calendarRef.current.addEventListener('mousedown', startFling);
      calendarRef.current.addEventListener('mousemove', moveFling);
      calendarRef.current.addEventListener('mouseup', endFling);
      
      calendarRef.current.addEventListener('touchstart', startFling);
      calendarRef.current.addEventListener('touchmove', moveFling);
      calendarRef.current.addEventListener('touchend', endFling);
    }
    return () => {
      if(savedCalendarRef !== null) {
        savedCalendarRef.removeEventListener('mousedown', startFling);
        savedCalendarRef.removeEventListener('mousemove', moveFling);
        savedCalendarRef.removeEventListener('mouseup', endFling);

        savedCalendarRef.removeEventListener('touchstart', startFling);
        savedCalendarRef.removeEventListener('touchmove', moveFling);
        savedCalendarRef.removeEventListener('touchend', endFling);
      }
    };
  }, [selectedCalendarDate, getTotalTipsForMonth]);

  const getCalendarElement = useCallback(() => {
    const currentDatestring = format(new Date(), 'MMM d, yyyy');
    const selectedDate = new Date(selectedCalendarDate.getTime());
    const lastDayPreviousMonth = new Date(selectedCalendarDate.getTime());
    const startDate = new Date(selectedCalendarDate.getTime());
    startDate.setDate(1); //set to first day of month
    startDate.setDate(startDate.getDate() - startDate.getDay()); //set to first day of week
    const endDate = new Date(selectedCalendarDate.getTime());
    endDate.setDate(1); // set to first day of month before incrementing month. if we're on day 31 and increment to a month with 30 days it will skip a month
    endDate.setMonth(endDate.getMonth() + 1); //set to next month
    endDate.setDate(0); //set to last day of month previous month (last day of this month)
    endDate.setDate(endDate.getDate() + (6 - endDate.getDay())); //set to last day of week
    lastDayPreviousMonth.setDate(0); //set to last day of previous month
    const lastDayPreviousMonthDatestring = format(new Date(lastDayPreviousMonth.getTime()), 'MMM d, yyyy');
    const days = [];

    const date = new Date(startDate.getTime());
    while(date.getTime() <= endDate.getTime()) {
      const datestring = format(date, 'MMM d, yyyy');
      if(date.getMonth() === selectedDate.getMonth()) {
        const tipsForDay = tipsForDatestring !== null && typeof tipsForDatestring[datestring] === 'number' ? tipsForDatestring[datestring] : '';
        const entriesForDay = entriesForDatestring !== null && typeof entriesForDatestring[datestring] === 'object' ? entriesForDatestring[datestring] : null;
        days.push({ 
          'day': date.getDate(),
          'tipTotal': tipsForDay,
          'inMonth': true,
          'isToday': datestring === currentDatestring,
          'isLastDayPreviousMonth': false,
          'entriesForDay': entriesForDay,
          'timestamp': date.getTime()
        });
      } else {
        days.push({ 
          'day': '',
          'tipTotal': '',
          'inMonth': false,
          'isToday': false,
          'isLastDayPreviousMonth': datestring === lastDayPreviousMonthDatestring,
          'entriesForDay': null,
          'timestamp': null
        });
      }
      date.setDate(date.getDate() + 1);
    }

    return (
      <div className='h-full flex flex-col relative'>
        <div className='flex items-center w-full justify-between'>
          <div className='p-3 pl-3 tipzPrimaryColor ' onClick={() => {
            const newCurrentCalendarDate = new Date(selectedCalendarDate.getTime());
            newCurrentCalendarDate.setDate(1); // set to first day of month before incrementing month. if we're on day 31 and increment to a month with 30 days it will skip a month
            newCurrentCalendarDate.setMonth(newCurrentCalendarDate.getMonth() - 1);
            setSelectedCalendarDate(newCurrentCalendarDate);
          }}>
            <IonIcon icon={chevronBack} className='text-2xl align-middle cursor-pointer' />
          </div>
          <div className='font-bold my-2 text-lg '>
            {format(selectedDate, 'MMMM yyyy')}
          </div>
          <div className='p-3 pl-3 tipzPrimaryColor' onClick={() => {
            const newCurrentCalendarDate = new Date(selectedCalendarDate.getTime());
            newCurrentCalendarDate.setDate(1); // set to first day of month before incrementing month. if we're on day 31 and increment to a month with 30 days it will skip a month
            newCurrentCalendarDate.setMonth(newCurrentCalendarDate.getMonth() + 1);
            setSelectedCalendarDate(newCurrentCalendarDate);
          }}>
            <IonIcon icon={chevronForward} className='text-2xl align-middle cursor-pointer pr-3' />
          </div>
        </div>
        <div className='flex justify-around'>
          <div className='uppercase text-sm font-bold tipzMonthCalendarLabel'>Sun</div>
          <div className='uppercase text-sm font-bold tipzMonthCalendarLabel'>Mon</div>
          <div className='uppercase text-sm font-bold tipzMonthCalendarLabel'>Tue</div>
          <div className='uppercase text-sm font-bold tipzMonthCalendarLabel'>Wed</div>
          <div className='uppercase text-sm font-bold tipzMonthCalendarLabel'>Thu</div>
          <div className='uppercase text-sm font-bold tipzMonthCalendarLabel'>Fri</div>
          <div className='uppercase text-sm font-bold tipzMonthCalendarLabel'>Sat</div>
        </div>
        <div 
          ref={calendarRef}
          className={'grid grid-cols-7 auto-rows-fr justify-items-center mx-auto w-full h-full w-fit'}>
          {days.map((dayNumber, index) => (
            <div 
              key={index} 
              className={
                (dayNumber.inMonth ? 
                  ' border-stone-700 dark:border-stone-500 ' 
                  : (index < 7 ? 
                      ' border-stone-200 dark:border-stone-800 border-b-stone-700 dark:border-b-stone-500 ' 
                      : ' border-stone-200 dark:border-stone-800 '))
                + (index < 7 ? ' border-t ' : '') 
                + (index % 7 === 0 ? ' sm:border-l border-r ' : (index % 7 === 6 ? ' sm:border-r ' : ' border-r '))
                + (dayNumber.isLastDayPreviousMonth ? ' border-r-stone-700 dark:border-r-stone-500 ' : '')
                + (dayNumber.inMonth && index < 7 ? ' ' : '')
                + ' border-b border-solid w-full flex flex-col justify-around items-around h-full whitespace-nowrap '
                + (dayNumber.inMonth ? 
                  ' cursor-pointer active:outline-yellow-500 active:outline active:outline-2 active:outline-offset-[-1px] ' 
                  : '')
               } 
               onClick={() => {
                 if(dayNumber.entriesForDay !== null && dayNumber.entriesForDay.length > 1) {
                   if(typeof setEntrySelectionsForModal === 'function') {
                     setEntrySelectionsForModal(dayNumber.entriesForDay);
                   }
                 } else if(dayNumber.inMonth && dayNumber.entriesForDay !== null) {
                   if(typeof showEditModal === 'function') {
                     showEditModal(true, dayNumber.entriesForDay[0]);
                   }
                 } else if(dayNumber.inMonth && dayNumber.entriesForDay === null) {
                   const currentTime = new Date();
                   const newEntryDate = new Date(parseInt(dayNumber.timestamp));
                   //keep the day-month-year but set to the current hours-minutes-seconds
                   newEntryDate.setHours(currentTime.getHours(), currentTime.getMinutes(), currentTime.getSeconds());
                   //add new entry for the selected day
                   if(typeof showNewEntryModal === 'function') {
                     showNewEntryModal(newEntryDate.getTime());
                   }
                 }
               }}
              >
              {dayNumber.isToday &&
                <div className='tipzTodayMarker w-full text-center font-bold text-xs pl-0.5'>Today</div>
              }
              <div className={((dayNumber.entriesForDay !== null && dayNumber.entriesForDay.length > 1) ? 'flex' : '') + ' text-left text-lg'}>
               <div className='leading-3 pt-2 pl-1.5'>{dayNumber.day}</div>
               {(dayNumber.entriesForDay !== null && dayNumber.entriesForDay.length > 1) &&
                 <div className='text-sm w-full text-center '>({dayNumber.entriesForDay.length})</div>
               }
             </div>
              <div className={'grow font-bold text-center place-content-center active:scale-110'}>
                <span className='inline'>{(typeof dayNumber.tipTotal === 'number' ? '$' : '')}</span>
                <span className='inline tipzMoney '>{(typeof dayNumber.tipTotal === 'number' ? parseInt(Math.round(parseFloat(dayNumber.tipTotal))) : '')}</span>
              </div>
            </div>
          ))}
        </div>
        <div className='py-1 text-center'>
         <span className='font-bold'>{format(selectedDate, 'MMMM')} total:</span> $<span className='tipzMoney'>{totalTipsForMonth}</span>
        </div>
      </div>
    );
  }, [tipsForDatestring, entriesForDatestring, selectedCalendarDate, showEditModal, setEntrySelectionsForModal, showNewEntryModal, totalTipsForMonth]);

  const elementJSX = useMemo(() => (
    <div className='h-full mx-auto select-none'>
      {getCalendarElement()}
    </div>
  ), [getCalendarElement]);

  return (
    <>
      {elementJSX}
    </>
  );
};

export default TipzCalendar;
