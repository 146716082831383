import { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { IonContent, IonHeader, IonTitle, IonToolbar, IonButton, IonItem, IonLabel, IonList, IonSegment, IonSegmentButton, IonIcon, IonToast, IonModal } from '@ionic/react';
//import { SortList } from './MaterialIcons';
import TipzSelect from './TipzSelect';
import TipzButton from './TipzButton';
import TipzLogo from './TipzLogo';
import TipzLogoMinimal from './TipzLogoMinimal';
import { formatISO, format } from 'date-fns';
import { chevronBack, chevronForward, calendarNumber, list, arrowBack, addCircle, camera } from 'ionicons/icons';
import Entry from './Entry';
import Util from './Util';
import TipzCalendar from './TipzCalendar';

const TipzBrowseCalendar = ({userData, showEditModal, showNewEntryModal, editEntryModalOpen, hidden=false, showHeader=true, onFinished = () => {}}) => {
  const [toastMessage, setToastMessage] = useState('');
  const [toastShowing, setToastShowing] = useState(false);
  const [toastColor, setToastColor] = useState('');
  const [toastTimeout, setToastTimeout] = useState(null);

  const [entrySelectionModalOpen, setEntrySelectionModalOpen] = useState(false);
  const [entrySelectionsForModal, setEntrySelectionsForModal] = useState([]);

  const showToastMessage = useCallback((message, color, duration) => {
    //show toast then hide after a delay period
    setToastMessage(message);
    if(color) {
      setToastColor(color);
    }
    setToastShowing(true);

    if(toastTimeout !== null) {
      clearTimeout(toastTimeout);
    } 
    const timeout = setTimeout(() => {
      setToastShowing(false);
      //setToastTimeout(null);
    }, duration && typeof duration === 'number' ? duration : 2000);
    setToastTimeout(timeout);
  }, [toastTimeout]);

  useEffect(() => {
    if(hidden) {
      setToastShowing(false);
      setEntrySelectionModalOpen(false);
      setEntrySelectionsForModal([]);
    }
  }, [hidden]);

  useEffect(() => {
    const onPopState = (event) => {
      if(hidden) {
        return;
      }

      if(entrySelectionModalOpen) {
        setEntrySelectionModalOpen(false);
      } else if(!editEntryModalOpen) {
        onFinished();
      }
    };

    window.addEventListener('popstate', onPopState);
    return () => {
      window.removeEventListener('popstate', onPopState);
    };
  }, [hidden, editEntryModalOpen, entrySelectionModalOpen, onFinished]);

  const getHeader = useCallback(() => {
    console.log('render calendar header');
    return (
      <IonToolbar color='primary'>
        <div className='flex justify-evenly'>
          <TipzButton 
            onClick={() => {
              window.history.back();
            }}
          >
            <IonIcon icon={arrowBack} className='text-xl align-middle' /> Back
          </TipzButton>
          <div className='grow-[1]'></div>
          <IonTitle className=''>Entries ({userData.entries.length})</IonTitle>
          <div className='grow-[1]'></div>
          <div className='mr-2'>
            <TipzLogoMinimal darkMode={false} className='h-[40px]' />
          </div>
        </div>
      </IonToolbar>
    );
  }, [userData.entries]);

  const headerJSX = useMemo(() => {
    return getHeader();
  }, [getHeader]);

  const getContent = useCallback(() => {
    console.log('render calendar content');
    return (
      <div className='h-full mx-auto select-none'>
        <TipzCalendar 
          userEntries={userData.entries}
          setEntrySelectionsForModal={(entriesForDay) => {
            if(typeof entriesForDay === 'object' && Array.isArray(entriesForDay)) {
               setEntrySelectionModalOpen(false);
               setEntrySelectionsForModal(entriesForDay);
               setTimeout(() => {
                 setEntrySelectionModalOpen(true);
                 window.history.pushState('select', '', '#select');
               }, 10);
            }
          }} 
          showEditModal={showEditModal}
          showNewEntryModal={showNewEntryModal}
          resetDate={hidden}
        />
      </div>
    );
  }, [showEditModal, showNewEntryModal, userData, hidden]);

  const contentJSX = useMemo(() => {
    return getContent();
  }, [getContent]);

  const elementJSX = useMemo(() => (
    <>
      {showHeader === true && 
        <IonHeader className={hidden === true ? 'hidden' : ''}>
          {headerJSX}
        </IonHeader>
      }
      <IonContent className={hidden === true ? 'hidden' : ''}>
        {contentJSX}
      </IonContent>
      <IonToast className='' message={toastMessage} color={toastColor} isOpen={toastShowing}></IonToast>
      {/* Modal showing popup entry selection for days with more than one entry */}
      <IonModal
        isOpen={entrySelectionModalOpen}
        initialBreakpoint={0.40}
        breakpoints={[0, 0.40, 0.8]}
        header="Select Entry"
        canDismiss={() => {
          // this is called when the modal wants to close, like if the backdrop area behind the modal is clicked or <esc> is pressed
          // take this as a signal the user wants to close the modal and send the page back signal to signal our modal to close

          if(entrySelectionModalOpen === true) { // the modal hasn't been dismissed yet
            window.history.back();
          }
          return entrySelectionModalOpen === false;
        }}
        onDidDismiss={() => {
          //if(window.location.href.match(/.*#select$/)) {
          //  //this is called only when the user has closed the modal by clicking the the darkened area around it
          //  window.history.back();
          //}
        }}
      >
        <IonContent>
          <div className='text font-bold p-1 pt-3 pl-2 select-none'>{entrySelectionsForModal.length} Entries for {entrySelectionsForModal.length > 0 ?   format(new Date(parseInt(Entry.getStartTimestamp(entrySelectionsForModal[0]))), 'EEE, MMM d, yyyy') : ''}</div>
          <IonList>
            {entrySelectionsForModal.map((entry, key) => (
              <IonItem key={key} className='cursor-pointer tipz' lines='full' onClick={() => {
                showEditModal(true, entry);
                //setEntrySelectionModalOpen(false);
                 window.history.back();
              }}>
                {Entry.getListLabel(entry)}
              </IonItem>
            ))}
          </IonList>
        </IonContent>
      </IonModal>
    </>
  ), [headerJSX, contentJSX, hidden, toastMessage, toastColor, toastShowing, entrySelectionModalOpen, entrySelectionsForModal, showEditModal, showHeader]);

  return (
    <>
      {elementJSX}
    </>
  );
};

export default TipzBrowseCalendar;
