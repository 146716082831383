import { IonItem, IonTextarea, IonLabel, IonText, IonIcon } from '@ionic/react';
import { closeCircle } from 'ionicons/icons';

//this component uses styles from sr/index.css
//the styles relevant to this component are enabled by adding the 'tipz' class to IonItem

const TipzTextarea = ({label, input, error, onDelete, onInput, headerWarning, isReadOnly, placeholder, maxLength, onEnterKey, inputRef}) => {
  const useHeaderWarning = headerWarning || false;
  return (
    <div className='tipz w-full text-left'>
      {useHeaderWarning ?
        <div className='p-0 text-lg'>{label}{error.length > 0 ? <strong className='ml-4 text-red-400'>{error}</strong> : ''}</div>
      :
        <div className='p-0 text-lg'>{label}</div>
      }
      <IonTextarea
        ref={inputRef}
        value={input}
        onIonInput={(e) => {
          onInput(e.target.value);
        }}
        onKeyUp={(event) => {
          if(event.keyCode === 13 && typeof onEnterKey === 'function') { //enter key
            onEnterKey();
          }
        }}
        className={error.length > 0 ? 'hasError' : ''}
        placeholder={placeholder}
        autoGrow={true}
        counter={maxLength ? true : false}
        maxlength={maxLength || 1000000}
      ></IonTextarea>
      {useHeaderWarning === false &&
        <IonText color='danger' className={`${error.length > 0 ? '' : 'hidden'}`}>{error.length > 0 ? error : 'error'}</IonText>
      }
      {typeof onDelete === 'function' &&
        <IonIcon slot='end' icon={closeCircle} className='ml-2 cursor-pointer relative top-[0.75em]' title='Remove this resteraunt' size='large' onClick={() => {
          onDelete();
        }}/>
      }
    </div>
  );
}

export default TipzTextarea;
