import { useState, useEffect, useRef } from 'react';
import { IonLabel, IonDatetime, IonDatetimeButton, IonModal } from '@ionic/react';
import { parseISO } from 'date-fns';

//onInit will be called when the component mounts and will provide as an argument the current timestamp shown on the picker in milliseconds
//onClose will be called when the picker is closed by the user and will provide as an argument the current timestamp shown on the picker in milliseconds
//this module use setAttribute to store the timestamp on change because if we store it in the state it will trigger a render and change the layout
const TipzDatetimePickerButtons = ({label, onInit, onClosed, dateOnly, inputValue, hidden}) => {
  const timeRef = useRef(null);
  const modalRef = useRef(null);
  const [elementId] = useState((label || '')+new Date().getTime()+Math.floor(Math.random()*1000000));
  const [timestamp, setTimestamp] = useState(0);

  useEffect(() => {
    let pollInitTimer = null;
    let pollModalStateTimer = null;

    //get the initial date show on the dateTime picker and store it
    const getInitialTime = () => {
      if(timestamp !== 0) { //only set this if it's set to it's initial value
        return;
      }
      if(!timeRef.current || !timeRef.current.todayParts) {
        pollInitTimer = setTimeout(getInitialTime, 100);
        return;
      }

      const todayParts = timeRef.current.todayParts;
      const month = todayParts.month < 10 ? '0'+todayParts.month : todayParts.month;
      const day = todayParts.day < 10 ? '0'+todayParts.day : todayParts.day;
      const hour = todayParts.hour < 10 ? '0'+todayParts.hour : todayParts.hour;
      const minute = todayParts.minute < 10 ? '0'+todayParts.minute : todayParts.minute;
      const dateString = todayParts.year+'-'+month+'-'+day+'T'+hour+':'+minute;
      const date = parseISO(dateString);
      const initialTimestamp = date.getTime();

      if(typeof onInit === 'function') {
        onInit(initialTimestamp);
      }
      setTimestamp(initialTimestamp);
      //timeRef.current.setAttribute('data-timestamp', initialTimestamp);
    };

    //poll to see whether a date-time modal is open. we need to update hours worked when the modal is closed
    //but the ion-framework doesn't fire a close event unless the dialog was touched, so it doesn't work with just using the
    //mouse wheel then clicking outside the modal to close it
    //const pollModalState = () => {
    //  //if(timeRef.current && modalRef.current) {
    //  //  const isModalOpen = modalRef.current.offsetParent !== null ? 'true' : 'false';
    //  //  let wasModalOpen = timeRef.current.getAttribute('isOpen');

    //  //  if(wasModalOpen === null) {
    //  //    timeRef.current.setAttribute('isOpen', 'false');
    //  //    wasModalOpen = 'false';
    //  //  }
    //  //  if(isModalOpen !== wasModalOpen) {
    //  //    timeRef.current.setAttribute('isOpen', isModalOpen);
    //  //    if(isModalOpen === 'false') { 
    //  //      //modal just closed, now we update hours worked with the start time value that was saved when the modal's ionChange event fired
    //  //      //console.log('pollClose1', dynamicInputValue);
    //  //      //if(dynamicInputValue) {
    //  //      //  console.log('pollClose3', dynamicInputValue);
    //  //      //  onClosed(parseISO(dynamicInputValue).getTime());
    //  //      //} else if(timeRef.current.getAttribute('data-timestamp')) {
    //  //      //  console.log('pollClose2', timeRef.current.getAttribute('data-timestamp'));
    //  //      //  onClosed(timeRef.current.getAttribute('data-timestamp'));
    //  //      //  setTimestamp(timeRef.current.getAttribute('data-timestamp')); //TODO: this probably doesn't actually set the state
    //  //      //}
    //  //    }
    //  //  } 
    //  //}
    //  //pollModalStateTimer = setTimeout(pollModalState, 300);
    //};

    getInitialTime();
    //pollModalState();

    return () => {
      clearTimeout(pollInitTimer);
      clearTimeout(pollModalStateTimer);
    };
  }, [timestamp, onInit, onClosed]);

  useEffect(() => {
    if(hidden && modalRef.current) {
      modalRef.current.dismiss();
    }
  }, [hidden, modalRef]);

  return (
    <div className='flex justify-center text-center w-full'>
      <IonLabel>{label || ''}</IonLabel>
      <IonDatetimeButton datetime={elementId} onClick={() => {
        timeRef.current.reset(inputValue);
      }}></IonDatetimeButton>
      <IonModal keepContentsMounted={true} ref={modalRef}>
        <IonDatetime 
          ref={timeRef}
          id={elementId}
          value={inputValue || undefined}
          presentation={dateOnly === true ? 'date' : 'date-time'}
          showDefaultButtons={true}
          doneText='Ok'
          formatOptions={{
            date: {
              weekday: 'short',
              month: 'short',
              day: '2-digit',
              year: 'numeric'
            },
            time: {
              hour: '2-digit',
              minute: '2-digit'
            }
          }}
          onIonChange={(event) => {
            if(event.target.value) {
              //timeRef.current.setAttribute('data-timestamp', ''+parseISO(event.target.value).getTime());
              //setTimestamp(parseISO(event.target.value).getTime());
              onClosed(parseISO(event.target.value).getTime());
            } else {
              onClosed(timestamp); //this can happen if the user presses done before interacting with the element
            }
          }}
        >
        </IonDatetime>
      </IonModal>
    </div>
  );
};

export default TipzDatetimePickerButtons;
