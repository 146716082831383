import { Preferences } from '@capacitor/preferences';
import { useState, useEffect, useCallback } from 'react';
import { compressToUTF16 as LZCompress, decompressFromUTF16 as LZDecompress } from 'lz-string';
import { format } from 'date-fns';
//import MD5 from '../MD5';

const useUserData = () => {
  const [initialized, setInitialized] = useState(false);
  let [username, setUsername] = useState('');
  let [resteraunts, setResteraunts] = useState([]);
  let [jobs, setJobs] = useState([]);
  const [email, setEmail] = useState('');
  const [entries, setEntries] = useState([]);
  const [darkModeEnabled, setDarkModeEnabled] = useState(false);
  const [needsSetup, setNeedsSetup] = useState(true);
  const [sessionKey, setSessionKey] = useState('');
  const [sessionKeyExpiresAtTimestamp, setSessionKeyExpiresAtTimestamp] = useState(0);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const logout = () => {
    saveSessionKey('', 0);
    saveEmail('');
    saveDarkModeEnabled(false);
    saveUsername('');
    saveResteraunts([]);
    saveJobs([]);
    saveEntries([]);
  };

  const getUsernameError = (name) => {
    if(!name || name.length === 0) {
      return 'Cannot be empty';
    } else if(name.length > 50) {
      return 'Too long (50 characters max)';
    } else if(name.match(/^[a-zA-Z0-9 ]+$/) === null) {
      return  'May only contain letters and numbers';
    }
    return '';
  }

  const getResterauntNameError = (name) => {
    if(!name || name.length === 0 || name.match(/^ +$/)) {
      return 'Cannot be empty';
    } else if(name.length > 50) {
      return 'Too long (50 characters max)';
    } else if(name.match(/^[a-zA-Z0-9 @#&()-+_,.'"]+$/) === null) {
      const invalidCharacters = name.replaceAll(/[a-zA-Z0-9 @#&()-+_,.'"]/g, '');
      return  'Contains invalid characters: '+invalidCharacters;
    }
    return '';
  }

  const getJobNameError = (name) => {
    if(!name || name.length === 0 || name.match(/^ +$/)) {
      return 'Cannot be empty';
    } else if(name.length > 50) {
      return 'Too long (50 characters max)';
    } else if(name.match(/^[a-zA-Z0-9 #&'"+,.]+$/) === null) {
      const invalidCharacters = name.replaceAll(/[a-zA-Z0-9 #&'"+,.]/g, '');
      return  'Contains invalid characters: '+invalidCharacters;
    }
    return '';
  }
  
  const saveDarkModeEnabled = (enabled) => {
    if(enabled) {
      document.documentElement.classList.add('ion-palette-dark');
      document.body.classList.add('dark');
      Preferences.set({'key': 'darkMode', 'value': 'true'});
      setDarkModeEnabled(true);
    } else {
      document.documentElement.classList.remove('ion-palette-dark');
      document.body.classList.remove('dark');
      Preferences.set({'key': 'darkMode', 'value': 'false'});
      setDarkModeEnabled(false);
    }
  };

  const saveEmail = (newEmail) => {
    setEmail(newEmail);
    Preferences.set({'key': 'email', 'value': newEmail});
  }

  const saveUsername = (newName) => {
    setUsername(newName);
    username = newName;
    Preferences.set({'key': 'name', 'value': newName});
    setNeedsSetup(resteraunts.length === 0 || jobs.length === 0);
  }

  const saveResteraunts = (newResteraunts) => {
    setResteraunts(newResteraunts);
    resteraunts = newResteraunts;
    Preferences.set({'key': 'resteraunts', 'value': JSON.stringify(newResteraunts)});
    setNeedsSetup(resteraunts.length === 0 || jobs.length === 0);
  }

  const saveJobs = (newJobs) => {
    setJobs(newJobs);
    jobs = newJobs;
    Preferences.set({'key': 'jobs', 'value': JSON.stringify(newJobs)});
    setNeedsSetup(resteraunts.length === 0 || jobs.length === 0);
  }

  //argument can be a single entry or an array of entries
  const saveNewEntry = (newEntry) => {
    const newEntries = [];
    for(const entry of entries) {
      newEntries.push(entry);
    }

    if(Array.isArray(newEntry)) { 
      for(const entry of newEntry) {
        newEntries.push(entry);
        //console.log(JSON.stringify(entry, '', 2));
      }
    } else if(typeof newEntry === 'object') {
      newEntries.push(newEntry);
    } 

    setEntries(newEntries);
    Preferences.set({'key': 'entries', 'value': LZCompress(JSON.stringify(newEntries))});
  };

  const saveUpdatedEntry = (updatedEntry) => {
    const newEntries = [];
    for(const entry of entries) {
      if(entry.id !== updatedEntry.id) {
        newEntries.push(entry);
      }
    }
    newEntries.push(updatedEntry);
    setEntries(newEntries);
    Preferences.set({'key': 'entries', 'value': LZCompress(JSON.stringify(newEntries))});
  };

  const saveSessionKey = (newKey, expiresAtTimestamp) => {
    if(typeof newKey !== 'string' || typeof expiresAtTimestamp !== 'number') {
      return;
    }
    Preferences.set({'key': 'sessionKey', 'value': newKey});
    Preferences.set({'key': 'sessionKeyExpiresAtTimestamp', 'value': expiresAtTimestamp});
    setSessionKey(newKey);
    setSessionKeyExpiresAtTimestamp(expiresAtTimestamp);
    setIsLoggedIn(typeof newKey === 'string' && newKey.length > 0);
  };

  const deleteEntry = (entryId) => {
    const newEntries = [];
    for(const entry of entries) {
      if(entry.id !== entryId) {
        newEntries.push(entry);
      }
    }
    setEntries(newEntries);
    Preferences.set({'key': 'entries', 'value': LZCompress(JSON.stringify(newEntries))});
  };
  
  const saveEntries = (newEntries) => {
    setEntries(newEntries);
    Preferences.set({'key': 'entries', 'value': LZCompress(JSON.stringify(newEntries))});
  };

  //generate a new unique id for a list of entries
  const getNewIdForEntry = useCallback((entriesList) => {
    const list = entriesList || entries; //use entries saved in object state if none are supplied as a parameter
    let newId = 0;
    for(const entry of list) {
      if(typeof entry.id === 'number' && entry.id > newId) {
        newId = entry.id;
      }
    }
    return newId+1;
  }, [entries]);

  const readUserData = useCallback(async () => {
    if(initialized) {
      return;
    }

    if(typeof window === 'undefined') {
      setTimeout(readUserData, 250);
      return;
    }

    let unfinishedSetup = false;
    try {
      //load session key and expiresAtTimestamp
      const sessionKey = await Preferences.get({'key': 'sessionKey'});
      const sessionKeyExpiresAtTimestamp = await Preferences.get({'key': 'sessionKeyExpiresAtTimestamp'});
      if(typeof sessionKey.value === 'string') {
        setSessionKey(sessionKey.value);
        setIsLoggedIn(sessionKey.value.length > 0);
      }
      if(typeof sessionKeyExpiresAtTimestamp.value === 'string') {
        setSessionKeyExpiresAtTimestamp(sessionKeyExpiresAtTimestamp.value);
      }

      // TODO: logout if session key is expired
    } catch(error) {}
    try {
      //load saved email preference
      const emailPreference = await Preferences.get({'key': 'email'});
      if(typeof emailPreference.value === 'string') {
        setEmail(emailPreference.value);
      } 
    } catch(error) {}
    try {
      //load saved name preference
      const namePreference = await Preferences.get({'key': 'name'});
      if(typeof namePreference.value === 'string' && getUsernameError(namePreference.value).length === 0) {
        setUsername(namePreference.value);
      } else {
        //unfinishedSetup = true;
      }
    } catch(error) {}
    try {
      //load saved dark mode preference
      const darkModePreference = await Preferences.get({'key': 'darkMode'});
      if(typeof darkModePreference.value === 'string' && (darkModePreference.value === 'true' || darkModePreference.value === 'false')) {
        saveDarkModeEnabled(darkModePreference.value === 'true');
      }
    } catch(error) {}
    try {
      //load saved resteraunts
      const resterauntsPreferences = await Preferences.get({'key': 'resteraunts'});
      const savedResteraunts = JSON.parse(resterauntsPreferences.value);
      if(!Array.isArray(savedResteraunts)) { 
        throw 'saved resteraunts value is not an array';
      }
      const newResteraunts = [];
      for(const item of savedResteraunts) {
        if(typeof item === 'string' && getResterauntNameError(item).length === 0) {
          newResteraunts.push(item);
        }
      }
      if(newResteraunts.length === 0) {
        unfinishedSetup = true;
      }
      setResteraunts(newResteraunts);
    } catch(error) { 
      console.log(error); 
      unfinishedSetup = true;
    }
    try {
      //load saved jobs
      const jobsPreferences = await Preferences.get({'key': 'jobs'});
      const savedJobs = JSON.parse(jobsPreferences.value);
      if(!Array.isArray(savedJobs)) {
        throw 'saved jobs value is not an array';
      }
      const newJobs = [];
      for(const item of savedJobs) {
        if(typeof item === 'string' && getJobNameError(item).length === 0) {
          newJobs.push(item);
        }
      }
      if(newJobs.length === 0) {
        unfinishedSetup = true;
      }
      setJobs(newJobs);
    } catch(error) { 
      console.log(error); 
      unfinishedSetup = true;
    }
    try {
      //load saved entries
      let savedEntries = null;
      const entriesPreferences = await Preferences.get({'key': 'entries'});
      const compressedEntries = entriesPreferences.value;
      if(!compressedEntries || compressedEntries.length === 0) {
        savedEntries = [];
      } else {
        savedEntries = JSON.parse(LZDecompress(entriesPreferences.value));
        if(!Array.isArray(savedEntries)) {
          //TODO: need a better way to handle this
          throw 'saved entries value is not an array';
        }

        //generate new ids for entries that don't have one
        for(let i = 0; i < savedEntries.length; i++) {
          if(typeof savedEntries[i].id !== 'number') { //needs a new id
            savedEntries[i].id = getNewIdForEntry(savedEntries);
          }
        }
      }

      setEntries(savedEntries);
    } catch(error) { 
      console.log(error); 
    }

    setNeedsSetup(unfinishedSetup); 
    setInitialized(true)
  }, [getNewIdForEntry, initialized]);

  useEffect(() => {
    setTimeout(readUserData, 250); //let the loading logo show for a split second
  }, [readUserData]);

  return {
    initialized: initialized,
    sessionKey: sessionKey, 
    needsSetup: needsSetup,
    username: username,
    resteraunts: resteraunts,
    jobs: jobs,
    entries: entries,
    darkModeEnabled: darkModeEnabled,
    isLoggedIn: isLoggedIn,
    email: email,

    saveEmail: saveEmail,
    saveSessionKey: saveSessionKey,

    saveDarkModeEnabled: saveDarkModeEnabled,
    saveUsername: saveUsername,
    saveResteraunts: saveResteraunts,
    saveJobs: saveJobs,

    saveNewEntry: saveNewEntry,
    saveUpdatedEntry: saveUpdatedEntry,
    saveEntries: saveEntries,
    deleteEntry: deleteEntry,
    logout: logout,

    getUsernameError: getUsernameError,
    getResterauntNameError: getResterauntNameError,
    getJobNameError: getJobNameError,
    getNewIdForEntry: getNewIdForEntry,
  };
}

export default useUserData;
