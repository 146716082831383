// if the number is not an integer format it to have two decimal places, or return as is if it's an integer
const getCurrencyString = (number, includeTrailingZeros) => {
  if(typeof number === 'undefined' || isNaN(parseFloat(number))) {
    return includeTrailingZeros ? '0.00' : '0';
  }

  let formattedNumber = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(parseFloat(number));
  formattedNumber = formattedNumber.replaceAll('$', '');
  formattedNumber = includeTrailingZeros !== true ? formattedNumber.replace(/\.00$/, '') : formattedNumber;
  return formattedNumber;

  //if(typeof number === 'string' && number !== (''+parseInt(number))) {
  //  // number is not an integer
  //  return parseFloat(number).toFixed(2);
  //}
  //if(typeof number === 'number' && (''+number) !== (''+parseInt(number))) { 
  //  // number is not an integer
  //  return parseFloat(number).toFixed(2);
  //}
  //return number; // number is an integer, return as is
};

const getTimeWorkedString = (startTimestamp, endTimestamp, breakDurationMinutes) => {
  const startDate = new Date();
  startDate.setTime(parseInt(parseInt(startTimestamp / 1000)*1000)); //zero out milliseconds part of the timestamp
  startDate.setSeconds(0);
  const endDate = new Date();
  endDate.setTime(parseInt(parseInt(endTimestamp / 1000)*1000));
  endDate.setSeconds(0);

  let workedSeconds = Math.floor((endDate.getTime()-startDate.getTime())/1000);
  let workedHours = 0;
  let workedMinutes = 0;

  if(breakDurationMinutes && parseInt(breakDurationMinutes) > 0 && (''+breakDurationMinutes).match(/^[0-9]+$/) !== null) { //check if it's a valid integer
    workedSeconds -= parseInt(breakDurationMinutes)*60; //remove break minutes from the elapsed time
  }

  if(workedSeconds < 0) {
    throw 'getTimeWorkedString: Cannot be negative';
  }

  //break up seconds into hours, minutes, seconds
  if(workedSeconds >= 60*60) { //at least an hour
    workedHours = Math.floor(workedSeconds / (60*60));
    workedSeconds -= workedHours * 60*60; //subtract an hours worth of milliseconds
  }
  if(workedSeconds >= 60) { //at least a minute
    workedMinutes = Math.floor(workedSeconds / (60));
    workedSeconds -= workedMinutes * 60; //subtract a minutes worth of milliseconds
  }

  let workedDuration = '';
  if(workedHours > 0) {
    workedDuration += workedHours + ' Hour' + (workedHours !== 1 ? 's': ''); // plural hours?
  }
  if(workedMinutes > 0) {
    workedDuration += workedDuration.length > 0 ? ' ' : '';
    workedDuration += workedMinutes + ' Minute' + (workedMinutes !== 1 ? 's': ''); // plural minutes?
  }
  if(workedDuration.length === 0) {
    workedDuration = '0 Hours';
  }

  return workedDuration;
}

const getTimestringForHours = (hours) => {
  let totalHoursWorked = parseFloat(hours);
  if(isNaN(totalHoursWorked)) {
    return '0 Hours';
  }

  const isNegative = totalHoursWorked < 0;
  if(isNegative) {
    totalHoursWorked *= -1;
  }

  let hoursString = '';

  if(totalHoursWorked > 0) {
    hoursString += Math.floor(totalHoursWorked) + ' Hour' + (totalHoursWorked !== 1 ? 's' : '');
  }

  let minutes = (totalHoursWorked - Math.floor(totalHoursWorked)) * 60;
  if(minutes > 0) {
    hoursString += (hoursString.length > 0 ? ' ' : '') + Math.round(minutes) + ' Minute' + (minutes !== 1 ? 's' : '');
  }

  if(hoursString.length === 0) {
    hoursString = '0 Hours';
  }

  if(isNegative) {
    hoursString = '-' + hoursString ;
  }

  return hoursString;
};

const exports = {
  getCurrencyString: getCurrencyString,
  getTimeWorkedString: getTimeWorkedString,
  getTimestringForHours: getTimestringForHours,
};

export default exports;
