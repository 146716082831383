import { useState } from 'react';

//this component uses styles from src/App.css
//the styles relevant to this component are enabled by adding the 'tipzButton'

const TipzButton = ({isError = false, isSuccess = false, onClick, className, children, color = 'primary'}) => {
  const [pressed] = useState(false);

  return (
    <div  
      className={'tipzButton' + (pressed ? ' tipzButtonPressed ' : '') + (isError === true ? ' tipzError ' : (isSuccess === true ? ' tipzSuccess ' : '')) + (color === 'tertiary' ? ' tipzColorTertiary ' : '') + ' ' + className}
      onClick={(event) => {
        if(onClick && typeof onClick === 'function') {
          onClick(event);
        }
      }}
      onTouchStart={(event) => {
        //setPressed(true);
      }}
      onTouchEnd={(event) => {
        //setPressed(false);
      }}
    >
      {children}
    </div>
  );
}

export default TipzButton;
