import { useState, useMemo, useCallback, useEffect, useRef } from 'react';
import { IonContent, IonHeader, IonTitle, IonToolbar, IonButton, IonToggle, IonIcon, IonCard, IonCardContent, IonListHeader, useIonAlert, IonLoading, IonToast, IonLabel  } from '@ionic/react';
import { logOut, arrowBack, addCircle, moon, copy, download } from 'ionicons/icons';
import axios from 'axios'
import TipzInput from './TipzInput';
import TipzButton from './TipzButton';
import TipzLogoMinimal from './TipzLogoMinimal';
import { formatISO, format } from 'date-fns';
import { Clipboard } from  '@capacitor/clipboard';
import Entry from './Entry';
import Util from './Util';

const TipzSettings = ({userData, isOpen=true, onFinished = () => {}}) => {
  const [ionAlert] = useIonAlert();

  const userDataRef = useRef(null);
  const inputUsernameRef = useRef(null);
  const inputResterauntsRef = useRef(null);
  const inputJobsRef = useRef(null);
  const darkModeToggleCheckedRef = useRef(null);
  const saveDataTimeout = useRef(null);
  const uploadSettingsTimeout = useRef(null);

  const [inputUsername, setInputUsername] = useState({'name': userData.username, 'inputError': ''});
  const [inputResteraunts, setInputResteraunts] = useState(userData.resteraunts.length === 0 ? [{'name': '', 'inputError': ''}] : userData.resteraunts.map((item) => {return {'name': item, 'inputError': ''};}));
  const [inputJobs, setInputJobs] = useState(userData.jobs.length === 0 ?  [{'name': '', 'inputError': ''}] : userData.jobs.map((item) => {return {'name': item, 'inputError': ''};}));
  const [darkModeToggleChecked, setDarkModeToggleChecked] = useState(userData.darkModeEnabled);
  const [nextButtonErrorActive, setNextButtonErrorActive] = useState(false);
  const [showSavedConfirm, setShowSavedConfirm] = useState(false);
  const [isLoggingOut, setIsLoggingOut] = useState(false);

  const [toastMessage, setToastMessage] = useState('');
  const [toastShowing, setToastShowing] = useState(false);
  const [toastColor, setToastColor] = useState('');
  const toastTimeout = useRef(null);

  const showAlert = useCallback((message) => {
    ionAlert({
      header: 'Error',
      message: message,
      buttons: [
        {
          text: 'Ok',
        }
      ]
    });
  }, [ionAlert]);

  const uploadSettings = useCallback(() => {
    console.log('uploading settings');
    axios.post(process.env.REACT_APP_WEBSITE_ACTION_API_URL, {
      'action': 'updateSettings',
      'email': userDataRef.current.email,
      'sessionKey': userDataRef.current.sessionKey,
      'settings': JSON.stringify({
        'darkMode': userDataRef.current.darkModeEnabled,
        'username': userDataRef.current.username,
        'resteraunts': userDataRef.current.resteraunts,
        'jobs': userDataRef.current.jobs,
      })
    }).then((response) => {
      try {
        if (response.status !== 200) {
          throw "non 200 http response code";
        }
        if ('status' in response.data !== true 
          || 'data' in response.data !== true) {
          throw 'bad response object';
        }
        if(response.data.status === 'error' 
          && typeof response.data.data === 'object'
          && 'clientMessage' in response.data.data && typeof response.data.data.clientMessage === 'string'
          && response.data.data.clientMessage.length > 0) {
          throw { 'modalMessage' : response.data.data.clientMessage };
        }

        //user has an outdated or incorrect session key, make them login again
        if(response.data.status === 'success' 
          && 'action' in response.data.data === true
          && response.data.data.action === 'badSessionKey') {
          ionAlert({
            header: 'Session Expired',
            message: 'You session has expired, you\'ll need to login again to continue.',
            buttons: [
              {
                text: 'Ok',
                handler: () => {
                  userDataRef.current.logout(); 
                }
              }
            ]
          });
          return;
        }

        if(response.data.status !== 'success' 
          || 'action' in response.data.data !== true
          || response.data.data.action !== 'updateSettingsSuccess'
          || 'settings' in response.data.data !== true
          || typeof response.data.data.settings !== 'string'
          || 'actionId' in response.data.data !== true
          || typeof response.data.data.actionId !== 'string') {
          throw 'non success response from server';
        }

        const settingsFromServer = JSON.parse(response.data.data.settings);
        //success
      } catch(error) {
        if(typeof error === 'object' && 'modalMessage' in error) {
          showAlert(error.modalMessage);
        } else {
          showAlert('Something went wrong updating your settings. Please try again. Reload the page and try again if the problem persists.');
        }
        //logger.addError('error doing stuff ' + error)
      }
    }).catch((error) => {
      showAlert('Couldn\'t contact the server. Please try again. Reload the page and try again if the problem persists.');
    });
  }, [showAlert, ionAlert]);

  //TODO: need to escape special csv characters like separator and quotes
  //get a list of all entries in csv format with the first row as a header
  const getEntriesCsv = useCallback(() => {
    const rows = [];
    //add header row
    rows.push({
      'sort': 0,
      'csvLine': [
        'Workplace',
        'Job',
        'Shift Start',
        'Shift End',
        'Break Duration',
        'Hours Worked',
        'Cash Tips',
        'Credit Tips',
        'Tip In',
        'Tip Out',
        'Net Sales',
        'Notes',
        'Has Image'
      ].join(';')
    });
    //add all the entries
    for(const entry of userData.entries) {
      const startTimestamp = parseInt(Entry.getStartTimestamp(entry));
      const endTimestamp = parseInt(Entry.getEndTimestamp(entry));
      const hoursWorked = Entry.getHoursWorked(entry);
      rows.push({
        'sort': Entry.getStartTimestamp(entry),
        'csvLine': [
          typeof entry.resteraunt === 'string' ? entry.resteraunt : '',
          typeof entry.job === 'string' ? entry.job : '',
          format(new Date(startTimestamp), 'MMM d, yyyy @ p'),
          ((startTimestamp < endTimestamp) ? format(new Date(endTimestamp), 'MMM d, yyyy @ p') : 'none'),
          Util.getCurrencyString(entry.breakDuration, true),
          Util.getCurrencyString(hoursWorked, true),
          Util.getCurrencyString(entry.cashTips, true),
          Util.getCurrencyString(entry.creditTips, true),
          Util.getCurrencyString(entry.tipIn, true),
          Util.getCurrencyString(entry.tipOut, true),
          Util.getCurrencyString(entry.netSales, true),
          typeof entry.notes === 'string' ? entry.notes : '',
          Entry.entryHasImage(entry)
        ].join(';')
      });
    }

    //sort by shift date
    rows.sort((rowOne, rowTwo) => {
      const compare = rowOne.sort - rowTwo.sort;
      return compare;
    });

    return rows.map((row) => { return row.csvLine});
  }, [userData]);;

  const showToastMessage = useCallback((message, color, duration) => {
    //show toast then hide after a delay period
    setToastMessage(message);
    if(color) {
      setToastColor(color);
    }
    setToastShowing(true);

    if(toastTimeout.current !== null) {
      clearTimeout(toastTimeout.current);
    } 
    const timeout = setTimeout(() => {
      setToastShowing(false);
    }, duration && typeof duration === 'number' ? duration : 2000);
    toastTimeout.current = timeout;
  }, []);

  const copyCsvToClipboard = useCallback(async () => {
    showToastMessage('Copied to Clipboard', 'success', 3000);
    await Clipboard.write({
      string: getEntriesCsv().join('\n')
    });
  }, [getEntriesCsv, showToastMessage]);;

  const downloadCsv = useCallback(async () => {
    const blob = new Blob([getEntriesCsv().join('\n')], {type: 'text/csv'});
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    //link.download = 'tipz-entries_'+userData.username.replaceAll(/ /g, '')+'_'+format(new Date(), 'yyyy-LL-dd')+'.csv'; 
    link.download = 'tipz-entries_'+format(new Date(), 'yyyy-LL-dd')+'.csv'; 
    link.href = url;
    link.click();
  }, [getEntriesCsv]);;

  const onAddResteraunt = useCallback((event) => {
    const newResteraunts = getArrayCopy(inputResteraunts);
    newResteraunts.push({'name': '', 'inputError': ''});
    setInputResteraunts(newResteraunts);
  }, [inputResteraunts]);

  const onAddJob = useCallback((event) => {
    const newJobs = getArrayCopy(inputJobs);
    newJobs.push({'name': '', 'inputError': ''});
    setInputJobs(newJobs);
  }, [inputJobs]);

  //get a shallow copy of an array
  //used when signalling that we've updated the state of an array, because react acts weird when calling setState on the original state object
  const getArrayCopy = (arrayToCopy) => {
    const copy = [];
    for(const item of arrayToCopy) {
      copy.push(item);
    }
    return copy;
  }

  const eventuallySaveSettings = useCallback(() => {
    if(saveDataTimeout.current !== null) {
      clearTimeout(saveDataTimeout.current);
    }
    if(uploadSettingsTimeout.current !== null) {
      clearTimeout(uploadSettingsTimeout.current);
    }

    const timeout = setTimeout(() => {
      console.log('saving settings locally');

      userData.saveDarkModeEnabled(darkModeToggleCheckedRef.current);

      //const usernameError = userData.getUsernameError(inputUsernameRef.current.name);
      //if(usernameError.length === 0) {
      //  userData.saveUsername(inputUsernameRef.current.name);
      //}

      let hasResterauntErrors = false;
      for(const resteraunt of inputResterauntsRef.current) {
        const nameError = userData.getResterauntNameError(resteraunt.name);
        if(nameError.length > 0) {
          hasResterauntErrors  = true;
        } 
      }
      if(!hasResterauntErrors ) {
        userData.saveResteraunts(inputResterauntsRef.current.map((item) => item.name));
      }

      let hasJobsErrors = false;
      for(const job of inputJobsRef.current) {
        const nameError = userData.getJobNameError(job.name);
        if(nameError.length > 0) {
          hasJobsErrors  = true;
        } 
      }
      if(!hasJobsErrors ) {
        userData.saveJobs(inputJobsRef.current.map((item) => item.name));
      }

      if(!hasResterauntErrors && !hasJobsErrors) {
        // TODO: update other values even if one of these has an error?
        // Upload settings to the server after a certain number of seconds without the user changing their settings
        const timeout2 = setTimeout(uploadSettings, 4000);
        uploadSettingsTimeout.current = timeout2;
      }
    }, 1000);
    saveDataTimeout.current = timeout;
  }, [userData, uploadSettings]);

  useEffect(() => {
    //toggle dark mode with shift+d when on localhost domain for easier viewing during development
    const onKeyUp = (event) => {
      if(event && event.shiftKey === true && event.keyCode === 68 && window.location.origin.match(/.*localhost.*/) !== null) {
        userData.saveDarkModeEnabled(!userData.darkModeEnabled);
        setDarkModeToggleChecked(!userData.darkModeEnabled);
      }
    };
    window.addEventListener('keyup', onKeyUp);
    return () => {
      window.removeEventListener('keyup', onKeyUp);
    }
  }, [userData]);

  useEffect(() => {
    // This is necessary to get the current state within setTimeout
    userDataRef.current = userData;
    inputUsernameRef.current = inputUsername;
    inputResterauntsRef.current = inputResteraunts;
    inputJobsRef.current = inputJobs;
    darkModeToggleCheckedRef.current = darkModeToggleChecked;
  }, [userData, inputUsername, inputResteraunts, inputJobs, darkModeToggleChecked]);

  useEffect(() => {
    const onPopState = (event) => {
      if(!isOpen) {
        onFinished();
      }
    };

    window.addEventListener('popstate', onPopState);
    return () => {
      window.removeEventListener('popstate', onPopState);
    };
  }, [isOpen, onFinished]);
  
  const getHeader = useCallback(() => {
    console.log('render settings header');
    return (
      <IonToolbar color={nextButtonErrorActive ? 'danger' : (showSavedConfirm ? 'success' : 'primary')}>
        <div className='flex justify-evenly'>
          <TipzButton 
            onClick={() => {
              window.history.back();
            }}
          >
            <IonIcon icon={arrowBack} className='text-xl align-middle' /> Back
          </TipzButton>
          <div className='grow-[1]'></div>
          <IonTitle className=''>Settings</IonTitle>
          <div className='grow-[1]'></div>
          <div className='mr-2'>
            <TipzLogoMinimal darkMode={false} className='h-[40px]' />
          </div>
        </div>
      </IonToolbar>
    );
  }, [nextButtonErrorActive, showSavedConfirm]);

  const headerJSX = useMemo(() => {
    return getHeader();
  }, [getHeader]);

  const getContent = useCallback(() => {
    console.log('render settings content');
    return (
      <>
        <div className='max-w-screen-sm mx-auto'> 
          <div className={`flex flex-col gap-y-2 py-4`}>
            <div className=''>
              <IonCard className='tipzCard'>
                <IonCardContent>
                  <IonToggle 
                    className='text-lg'
                    checked={darkModeToggleChecked}
                    onIonChange={(event) => {
                      setDarkModeToggleChecked(event.target.checked);
                      userData.saveDarkModeEnabled(event.target.checked);
                      eventuallySaveSettings();
                    }} 
                  >
                    <IonIcon icon={moon} className='text-xl align-middle mr-1' /> Dark Mode
                  </IonToggle>
                  {/*<div className='py-4'></div>
                  <TipzInput  
                    label='Name'
                    input={inputUsername.name}
                    error={inputUsername.inputError}
                    onInput={(value) => {
                      const usernameError = userData.getUsernameError(value);
                      setInputUsername({'name': value, 'inputError': usernameError });
                      if(usernameError.length === 0) {
                        eventuallySaveSettings();
                      }
                    }}
                  />*/}
                </IonCardContent>
              </IonCard>
            </div>
            <IonListHeader>Workplace(s)</IonListHeader>
            <IonCard className='tipzCard'>
              <IonCardContent>
                {inputResteraunts.map((resteraunt, key) => 
                  <div key={key} className='mt-2'>
                    <TipzInput 
                      label={`Workplace${inputResteraunts.length > 1 ? ' #'+(key+1) : ''}${key === 0 && inputResteraunts.length > 1 ? ' (default)' : ''}`}
                      input={resteraunt.name}
                      error={resteraunt.inputError}
                      onInput={(value) => {
                        const nameError = userData.getResterauntNameError(value);
                        resteraunt.inputError = nameError.length > 0 ? nameError : '';
                        resteraunt.name = value;
                        setInputResteraunts(getArrayCopy(inputResteraunts));
                        if(nameError.length === 0) {
                          eventuallySaveSettings();
                        }
                      }} 
                      onDelete={inputResteraunts.length < 2 ? null : (value) => {
                        if(inputResteraunts[key].name.length > 0) {
                          //ask for confirmation if the workplace name is not blank
                          ionAlert({
                            header: 'Confirm Delete',
                            message: 'Are you sure you want to delete "' + inputResteraunts[key].name + '"?',
                            buttons: [
                              {
                                text: 'Cancel',
                              },
                              {
                                text: 'Delete',
                                handler: () => {
                                  setInputResteraunts(inputResteraunts.filter((item, index) => index !== key)); 
                                  eventuallySaveSettings();
                                }
                              }
                            ]
                          });
                        } else {
                          //delete without asking for confirmation if workplace name is blank
                          setInputResteraunts(inputResteraunts.filter((item, index) => index !== key)); 
                          eventuallySaveSettings();
                        }
                      }}
                    />
                  </div>
                )}
                <IonButton onClick={onAddResteraunt} className='mt-4'>
                  <IonIcon icon={addCircle} className='mr-2 text-2xl align-middle'/>Add Another Workplace
                </IonButton>
              </IonCardContent>
            </IonCard>
            <IonListHeader>Job(s)</IonListHeader>
            <IonCard className='tipzCard'>
              <IonCardContent>
                {inputJobs.map((job, key) => 
                  <div key={key} className='mt-2'>
                    <TipzInput 
                      label={`Job${inputJobs.length > 1 ? ' #'+(key+1) : ''}${key === 0 && inputJobs.length > 1 ? ' (default)' : ''}`}
                      input={job.name}
                      error={job.inputError}
                      onInput={(value) => {
                        const nameError = userData.getJobNameError(value);
                        job.inputError = nameError.length > 0 ? nameError : '';
                        job.name = value;
                        setInputJobs(getArrayCopy(inputJobs));

                        if(nameError.length === 0) {
                          eventuallySaveSettings();
                        }
                      }}
                      onDelete={inputJobs.length < 2 ? null : (value) => {
                        if(inputJobs[key].name.length > 0) {
                          //ask for confirmation if the job name is not blank
                          ionAlert({
                            header: 'Confirm Delete',
                            message: 'Are you sure you want to delete "' + inputJobs[key].name + '"?',
                            buttons: [
                              {
                                text: 'Cancel',
                              },
                              {
                                text: 'Delete',
                                handler: () => {
                                  setInputJobs(inputJobs.filter((item, index) => index !== key)); 
                                  eventuallySaveSettings();
                                }
                              }
                            ]
                          });
                        } else {
                          //delete without asking for confirmation if job name is blank
                          setInputJobs(inputJobs.filter((item, index) => index !== key)); 
                          eventuallySaveSettings();
                        }
                    }}/>
                  </div>
                )}
                <IonButton onClick={onAddJob} className='mt-4'>
                  <IonIcon icon={addCircle} className='mr-2 text-3xl align-middle'/>Add Another Job
                </IonButton>
              </IonCardContent>
            </IonCard>
            <IonListHeader>Sign Out</IonListHeader>
            <IonCard className='tipzCard'>
              <IonCardContent>
                <div>Currently logged in as:</div>
                <div className='font-bold mt-1 p-3 readonly rounded '>{userData.email}</div>
                <IonButton onClick={ () => { 
                  ionAlert({
                    header: 'Confirm Logout',
                    message: 'Are you sure you want to logout?',
                    buttons: [
                      {
                        text: 'Cancel',
                      },
                      {
                        text: 'Yes',
                        handler: () => {
                          setIsLoggingOut(true);
                          axios.post(process.env.REACT_APP_WEBSITE_ACTION_API_URL, {
                            'action': 'logout',
                            'email': userData.email,
                            'sessionKey': userData.sessionKey
                          }).then((response) => {
                            try {
                              if (response.status !== 200) {
                                throw "non 200 http response code";
                              }
                              if ('status' in response.data !== true 
                                || 'data' in response.data !== true) {
                                throw 'bad response object';
                              }
                              if(response.data.status === 'error' 
                                && typeof response.data.data === 'object'
                                && 'clientMessage' in response.data.data && typeof response.data.data.clientMessage === 'string'
                                && response.data.data.clientMessage.length > 0) {
                                throw { 'modalMessage' : response.data.data.clientMessage };
                              }
                              if(response.data.status !== 'success' 
                                || 'action' in response.data.data !== true
                                || (response.data.data.action !== 'logoutSuccess' && response.data.data.action !== 'badSessionKey')) {
                                throw 'non success response from server';
                              }
                              
                              //TODO: need to clear browser url state here

                              //success
                              setIsLoggingOut(false);
                              userData.logout(); 
                            } catch(error) {
                              if(typeof error === 'object' && 'modalMessage' in error) {
                                showAlert(error.modalMessage);
                              } else {
                                showAlert('Something went wrong when attempting to logout. Please try again. Reload the page and try again if the problem persists.');
                              }
                              setIsLoggingOut(false);
                            }
                          }).catch((error) => {
                            showAlert('Couldn\'t contact the server. Please try again. Reload the page and try again if the problem persists.');
                            setIsLoggingOut(false);
                          });
                        }
                      }
                    ]
                  });
                }}
                className='mt-4'>
                  <IonIcon icon={logOut} className='mr-2 text-3xl align-middle'/>Logout
                </IonButton>
              </IonCardContent>
            </IonCard>

            <IonListHeader>Export Data</IonListHeader>
            <IonCard className='tipzCard'>
              <IonCardContent>
                <div className='flex flex-col gap-y-4 leading-6'>
                  <IonButton 
                    className='w-full'
                    onClick={downloadCsv}
                  >
                    <IonIcon icon={download} className='text-2xl mr-2' /> 
                    <IonLabel>Download CSV</IonLabel>
                  </IonButton>
                  <IonButton 
                    className='w-full'
                    onClick={copyCsvToClipboard}
                  >
                    <IonIcon icon={copy} className='text-2xl mr-2' /> 
                    <IonLabel>Copy CSV To Clipboard</IonLabel>
                  </IonButton>
                  <div>Below are your entries in CSV format:</div>
                  <div className='tipzCode whitespace-nowrap overflow-scroll h-[50vh] select-text p-1'>
                    {getEntriesCsv().map((row, key) => (
                      <div className={'whitespace-pre' + (key === 0 ? ' font-bold' : '')} key={key}>
                        {row}
                      </div>
                    ))}
                  </div>
                </div>
              </IonCardContent>
            </IonCard>
          </div>
        </div>
      </>
    );
  }, [darkModeToggleChecked, inputJobs, inputResteraunts, onAddResteraunt, onAddJob, userData, showAlert, ionAlert, eventuallySaveSettings, copyCsvToClipboard, getEntriesCsv, downloadCsv]);

  const contentJSX = useMemo(() => {
    return getContent();
  }, [getContent]);

  const elementJSX = useMemo(() => (
    <>
      <IonHeader>
        {headerJSX}
      </IonHeader>
      <IonContent>
        {contentJSX}
        <IonToast className='' message={toastMessage} color={toastColor} isOpen={toastShowing}></IonToast>
      </IonContent>
      <IonLoading message='Logging Out' isOpen={isLoggingOut} />
    </>
  ), [headerJSX, contentJSX, isLoggingOut, toastShowing, toastColor, toastMessage]);

  return (
    <>
      {elementJSX}
    </>
  );
};

export default TipzSettings;

//            <div className='bg-[#ddd] dark:bg-[#222] px-2 py-2 mt-2 rounded-md mx-1'> 
//              <div className='text-xl text-center font-bold'>Resteraunts</div>
//              {inputResteraunts.map((resteraunt, key) => 
//                <div key={key} className='mt-2'>
//                  <TipzInput label={`Resteraunt${key >= 0 ? ' #'+(key+1) : ''}`} input={resteraunt.name} error={resteraunt.inputError} onInput={(value) => {
//                    resteraunt.name = value;
//                    resteraunt.inputError = '';
//                    setInputResteraunts(getArrayCopy(inputResteraunts));
//                  }} onDelete={key === 0 ? null : (value) => {
//                    setInputResteraunts(inputResteraunts.filter((item, index) => index !== key)); 
//                  }}/>
//                </div>
//              )}
//              <IonButton onClick={onAddResteraunt} className='mt-4'>
//                <IonIcon icon={addCircle} className='mr-2 text-2xl align-middle'/>Add Another Resteraunt
//              </IonButton>
//            </div>

//<IonButton onClick={onNextButton} color={nextButtonErrorActive ? 'danger' : ''} className="w-[50%]">Save</IonButton>

//{stepNumber === 1 && step1Jsx()}
//{stepNumber === 2 && step2Jsx()}
//{stepNumber === 3 && step3Jsx()}

//<div className='relative'>
//  <div className={`flex flex-col gap-y-6 ion-padding absolute w-full transition-all duration-300 
//    ${stepNumber === 1 ? 'translate-x-[0%] opacity-1' : 'translate-x-[-100%] opacity-0 pointer-events-none'}
//    `}>
//    {step1Jsx()}
//  </div>
//  <div className={`flex flex-col gap-y-4 ion-padding absolute w-full transition-all duration-300 
//      ${stepNumber === 1 ? 'translate-x-[100%] opacity-0 pointer-events-none' : ''} 
//      ${stepNumber === 2 ? 'translate-x-[0%] opacity-1' : ''} 
//      ${stepNumber === 3 ? 'translate-x-[-100%] opacity-0 pointer-events-none' : ''} 
//    `}>
//    {step2Jsx()}
//  </div>
//  <div className={`flex flex-col gap-y-4 ion-padding absolute w-full transition-all duration-300 
//    ${stepNumber === 3 ? 'translate-x-[0%] opacity-1' : 'translate-x-[100%] opacity-0 pointer-events-none'}
//    `}>
//    {step3Jsx()}
//  </div>
//</div>


  //const onSaveButton = useCallback((event) => {
  //  let hasErrors = false;

  //  const usernameError = userData.getUsernameError(inputUsername.name);
  //  if(usernameError.length > 0) {
  //    hasErrors = true;
  //    setInputUsername({'name': inputUsername.name, 'inputError': usernameError});
  //    setNextButtonErrorActive(true);
  //    setTimeout(() => {
  //      setNextButtonErrorActive(false);
  //    }, 2000);
  //  } 

  //  let hasResterauntErrors = false;
  //  for(const resteraunt of inputResteraunts) {
  //    const nameError = userData.getResterauntNameError(resteraunt.name);
  //    if(nameError.length > 0) {
  //      hasResterauntErrors  = true;
  //      resteraunt.inputError = nameError;
  //    } 
  //  }
  //  if(hasResterauntErrors ) {
  //    //commit to state the errors we set above
  //    //if we set it to the current list it won't trigger an update, so we provide a copy
  //    hasErrors = true;
  //    setInputResteraunts(getArrayCopy(inputResteraunts)); 
  //    setNextButtonErrorActive(true);
  //    setTimeout(() => {
  //      setNextButtonErrorActive(false);
  //    }, 2000);
  //  }

  //  let hasJobsErrors = false;
  //  for(const job of inputJobs) {
  //    const nameError = userData.getJobNameError(job.name);
  //    if(nameError.length > 0) {
  //      hasJobsErrors = true;
  //      job.inputError = nameError;
  //    } 
  //  }
  //  if(hasJobsErrors ) {
  //    hasErrors = true;
  //    setInputJobs(getArrayCopy(inputJobs)); 
  //    setNextButtonErrorActive(true);
  //    setTimeout(() => {
  //      setNextButtonErrorActive(false);
  //    }, 2000);
  //  }

  //  if(!hasErrors) {
  //    //finished with setup
  //    userData.saveUsername(inputUsername.name);
  //    userData.saveResteraunts(inputResteraunts.map((item) => item.name));
  //    userData.saveJobs(inputJobs.map((item) => item.name));
  //    setShowSavedConfirm(true);
  //    setTimeout(() => {
  //      setShowSavedConfirm(false);
  //    }, 2000);
  //  }
  //}, [inputUsername, inputResteraunts, inputJobs, userData]);
