import { useState, useEffect, useRef, useMemo, useCallback  } from 'react';
import { IonContent, IonHeader, IonTitle, IonToolbar, IonButton, IonLabel, IonNote, IonItem, useIonAlert, IonModal, IonChip, IonIcon, IonToast, IonFab, IonFabButton, IonFabList, IonLoading, IonProgressBar, IonSpinner } from '@ionic/react';
import TipzInput from './TipzInput';
import TipzTextarea from './TipzTextarea';
import TipzButton from './TipzButton';
import TipzSelect from './TipzSelect';
import TipzDatetimePickerButtons from './TipzDatetimePickerButtons'; 
import TipzLogoMinimal from './TipzLogoMinimal';
import { formatISO, format } from 'date-fns';
import { image, trash, save, arrowBack, addCircle, pencil } from 'ionicons/icons';
import { SaveIcon } from './MaterialIcons';
import { Camera, CameraResultType } from '@capacitor/camera';
import axios from 'axios'
import Entry from './Entry';
import Util from './Util';

// serves as the interface to both create and edit entries
const TipzCreateEntry = ({inputEntry = null, userData, isNewEntry, showDatePicker, setModalTime, setModalTimeChangeCallback, onFinished = () => {}, isOpen, isWidescreen = false}) => {
  const currentDate = new Date();
  currentDate.setSeconds(0);
  const currentTimestamp = parseInt(parseInt(currentDate.getTime()/1000)*1000); // remove milliseconds part

  const [finished, setFinished] = useState(false);

  const [initialEntry, setInitialEntry] = useState({});

  const [inputShiftReportImage, setInputShiftReportImage] = useState('');
  const [inputImageAction, setInputImageAction] = useState('');
  const [shiftReportImageUrl, setShiftReportImageUrl] = useState(null);
  const [imageExpanded, setImageExpanded] = useState(false);
  //const [fetchingImage, setFetchingImage] = useState(false);
  const [ocrProgress, setOcrProgress] = useState('');
  const ocrProgressRef = useRef(0);

  const [ionAlert] = useIonAlert();

  const contentRef = useRef(null);

  const modalInputRef = useRef(null);
  const modalInputContainerRef = useRef(null);
  const [tipinList, setTipInList] = useState([]);
  const [isTextInputModalShowing, setTextInputModalShowing] = useState(false);
  const [modalInputAmount, setModalInputAmount] = useState({'value': '', 'error': ''});
  const [modalInputFrom, setModalInputFrom] = useState({'value': '', 'error': ''});

  const [inputResteraunt, setInputResteraunt] = useState(userData.resteraunts.length > 0 ? userData.resteraunts[0] : '');
  const [inputJob, setInputJob] = useState(userData.jobs.length > 0 ? userData.jobs[0] : '');

  const [calculatedHoursWorked, setCalculatedHoursWorked] = useState({'value': '', 'error': ''});
  const [inputHoursWorked, setInputHoursWorked] = useState({'value': '', 'error': ''});
  const [hoursWorkedHighlighted, setHoursWorkedHighlighted] = useState(false);
  const [usingHoursWorked, setUsingHoursWorked] = useState(false);
  const inputHoursWorkedRef = useRef(null);

  const [inputBreakDuration, setInputBreakDuration] = useState({'value': '', 'error': ''});
  const inputBreakDurationRef = useRef(null);

  const [inputUncategorizedTips, setInputUncategorizedTips] = useState({'value': '', 'error': ''});
  const inputUncategorizedTipsRef = useRef(null);

  const [usingCashCreditTips, setUsingCashCreditTips] = useState(true);
  const [cashCreditTipsHighlighted, setCashCreditTipsHighlighted] = useState(false);
  const inputCashTipsRef = useRef(null);

  const [inputCashTips, setInputCashTips] = useState({'value': '', 'error': ''});
  const inputCreditTipsRef = useRef(null);
  const [inputCreditTips, setInputCreditTips] = useState({'value': '', 'error': ''});

  const [inputNotes, setInputNotes] = useState({'value': '', 'error': ''});
  const inputNotesRef = useRef(null);
  const [notesHighlighted, setNotesHighlighted] = useState(false);
  const [usingNotes, setUsingNotes] = useState(false);

  const [inputTipIn, setInputTipIn] = useState({'value': '', 'error': ''});
  const inputTipInRef = useRef(null);
  const [usingTipInOut, setUsingTipInOut] = useState(false);
  const [tipInHighlighted, setTipInHighlighted] = useState(false);

  const [inputTipOut, setInputTipOut] = useState({'value': '', 'error': ''});
  const inputTipOutRef = useRef(null);

  const [inputNetSales, setInputNetSales] = useState({'value': '', 'error': ''});
  const inputNetSalesRef = useRef(null);
  const [usingNetSales, setUsingNetSales] = useState(false);
  const [netSalesHighlighted, setNetSalesHighlighted] = useState(false);
  
  const [startTimestamp, setStartTimestamp] = useState(currentTimestamp);
  const [endTimestamp, setEndTimestamp] = useState(currentTimestamp);
  const [usingStartEndTime, setUsingStartEndTime] = useState(false);
  const [startEndTimeHighlighted, setStartEndTimeHighlighted] = useState(false);
  const startTimeRef = useRef(null);

  const [showFormButtonError, setShowFormButtonError] = useState(false);
  const [showFormSuccess, setShowFormSuccess] = useState(false);

  const [toastMessage, setToastMessage] = useState('');
  const [toastShowing, setToastShowing] = useState(false);
  const [toastColor, setToastColor] = useState('');
  const [toastTimeout, setToastTimeout] = useState(null);

  const [isAddingEntry, setIsAddingEntry] = useState(false);
  const [isUploadingImage, setIsUploadingImage] = useState(false);
  const [isDeletingEntry, setIsDeletingEntry] = useState(false);
  const [isUpdatingEntry, setIsUpdatingEntry] = useState(false);
  const [imageUploadProgress, setImageUploadProgress] = useState(0);

  const showAlert = useCallback((message) => {
    ionAlert({
      header: 'Error',
      message: message,
      buttons: [
        {
          text: 'Ok',
        }
      ]
    });
  }, [ionAlert]);

  const getNumberError = (number) => {
    const numberString = ''+number;
    if(!number || numberString.length === 0) {
      return '';
      //return 'Empty';
    } else if(numberString.length > 10) {
      return 'Too long (10 characters max)';
    } else if(numberString.match(/^[0-9]+(\.[0-9]+)?$/) === null || isNaN(parseFloat(numberString))) {
      return  'Invalid number';
    }
    return '';
  }

  const showToastMessage = useCallback((message, color, duration) => {
    //show toast then hide after a delay period
    setToastMessage(message);
    if(color) {
      setToastColor(color);
    }
    setToastShowing(true);

    if(toastTimeout !== null) {
      clearTimeout(toastTimeout);
    } 
    const timeout = setTimeout(() => {
      setToastShowing(false);
      //setToastTimeout(null);
    }, duration && typeof duration === 'number' ? duration : 2000);
    setToastTimeout(timeout);
  }, [toastTimeout]);

  const onResetButtonClicked = useCallback(() => {
    const currentDate = new Date();
    currentDate.setSeconds(0);
    const currentTimestamp = parseInt(parseInt(currentDate.getTime()/1000)*1000); //remove milliseconds part

    setStartTimestamp(currentTimestamp);
    setEndTimestamp(0);
    setUsingStartEndTime(false);
    setStartEndTimeHighlighted(false);

    setInputResteraunt(userData.resteraunts.length > 0 ? userData.resteraunts[0] : '');
    setInputJob(userData.jobs.length > 0 ? userData.jobs[0] : '');
    setInputBreakDuration({'value': '', 'error': ''});

    setInputHoursWorked({'value': '', 'error': ''});
    setUsingHoursWorked(false);
    setHoursWorkedHighlighted(false);

    setInputNotes({'value': '', 'error': ''});
    setUsingNotes(false);
    setNotesHighlighted(false);

    setInputUncategorizedTips({'value': '', 'error': ''});

    setInputCreditTips({'value': '', 'error': ''});
    setInputCashTips({'value': '', 'error': ''});
    setUsingCashCreditTips(true);
    setCashCreditTipsHighlighted(false);

    setInputTipIn({'value': '', 'error': ''});
    setInputTipOut({'value': '', 'error': ''});
    setUsingTipInOut(false);
    setTipInHighlighted(false);

    setInputNetSales({'value': '', 'error': ''});
    setUsingNetSales(false);
    setNetSalesHighlighted(false);

    setShowFormButtonError(false);
    setShowFormSuccess(false);

    setInputShiftReportImage('');
    setShiftReportImageUrl(null);
    setInputImageAction('');
    setImageExpanded(false);
    //setFetchingImage(false);
    
    setImageUploadProgress(0);
    
    setFinished(false);
  }, [userData.jobs, userData.resteraunts]);

  const getCurrentEntry = useCallback(() => {
    const entry = {
      'id': (inputEntry && typeof inputEntry.id === 'number') ? inputEntry.id : -1,
      'resteraunt': userData.resteraunts.length === 1 ? userData.resteraunts[0] : inputResteraunt,
      'job': userData.jobs.length === 1 ? userData.jobs[0] : inputJob,
      //'startTimestring': startTimestamp,
      //'endTimestring': endTimestamp,
      'startTimestamp': startTimestamp,
      'endTimestamp': endTimestamp,
      'breakDuration': inputBreakDuration.value.length === 0 ? '0' : inputBreakDuration.value,
      'hoursWorked': usingHoursWorked ? inputHoursWorked.value : '',
      //'hoursWorkedString': inputHoursWorked.value,
      'uncategorizedTips': usingCashCreditTips === false ? (!isNaN(parseFloat(inputUncategorizedTips.value)) ? inputUncategorizedTips.value : '0') : '',
      'cashTips': usingCashCreditTips ? inputCashTips.value : '',
      'creditTips': usingCashCreditTips ? inputCreditTips.value : '',
      'tipIn': usingTipInOut ? inputTipIn.value : '',
      'tipOut': usingTipInOut ? inputTipOut.value : '',
      'netSales': usingNetSales ? ''+inputNetSales.value : '',
      'notes': usingNotes ? inputNotes.value : '',
      'hasImage': '' + (inputShiftReportImage.length > 0 || (inputShiftReportImage.length === 0 && typeof shiftReportImageUrl === 'string' && (shiftReportImageUrl.startsWith('https') || shiftReportImageUrl === 'loading')))
    };
    return entry;
  }, [inputEntry, userData, startTimestamp, endTimestamp, inputBreakDuration.value, inputCashTips.value, inputCreditTips.value, inputHoursWorked.value, inputJob, inputNetSales.value, inputNotes.value, inputResteraunt, inputTipIn.value, inputTipOut.value, inputUncategorizedTips.value, usingCashCreditTips, usingHoursWorked, usingNetSales, usingNotes, usingTipInOut, inputShiftReportImage, shiftReportImageUrl]);;

  useEffect(() => {
    onResetButtonClicked();

    if(!inputEntry) {
      return;
    }

    //console.log(inputEntry);

    let usingCashTips = !isNaN(parseFloat(inputEntry.cashTips));
    let usingCreditTips = !isNaN(parseFloat(inputEntry.creditTips));
    let usingUncategorizedTips = !isNaN(parseFloat(inputEntry.uncategorizedTips));
    let usingHoursWorked = !isNaN(parseFloat(inputEntry.hoursWorked));
    let usingNotes = typeof inputEntry.notes === 'string' && inputEntry.notes.length > 0;
    setUsingHoursWorked(usingHoursWorked);
    setUsingCashCreditTips(!usingUncategorizedTips);
    setUsingTipInOut(!isNaN(parseFloat(inputEntry.tipIn)) || !isNaN(parseFloat(inputEntry.tipOut)));
    setUsingNetSales(!isNaN(parseFloat(inputEntry.netSales)));
    setUsingNotes(usingNotes);
    setUsingStartEndTime(Entry.isUsingStartEndTimes(inputEntry));

    setStartTimestamp(Entry.getStartTimestamp(inputEntry));
    setEndTimestamp(Entry.getEndTimestamp(inputEntry));

    setInputResteraunt(typeof inputEntry.resteraunt === 'string' ? inputEntry.resteraunt : '');
    setInputJob(typeof inputEntry.job === 'string' ? inputEntry.job : '');
    setInputBreakDuration({'value': isNaN(parseInt(inputEntry.breakDuration)) ? '' : ''+parseInt(inputEntry.breakDuration), 'error': ''});
    setInputUncategorizedTips({'value': isNaN(parseFloat(inputEntry.uncategorizedTips)) ? '' : parseFloat(inputEntry.uncategorizedTips).toFixed(2), 'error': ''});
    setInputCashTips({'value': isNaN(parseFloat(inputEntry.cashTips)) ? '' : parseFloat(inputEntry.cashTips).toFixed(2), 'error': ''});
    setInputCreditTips({'value': isNaN(parseFloat(inputEntry.creditTips)) ? '' : parseFloat(inputEntry.creditTips).toFixed(2), 'error': ''});
    setInputTipIn({'value': isNaN(parseFloat(inputEntry.tipIn)) ? '' : parseFloat(inputEntry.tipIn).toFixed(2), 'error': ''});
    setInputTipOut({'value': isNaN(parseFloat(inputEntry.tipOut)) ? '' : parseFloat(inputEntry.tipOut).toFixed(2), 'error': ''});
    setInputNetSales({'value': isNaN(parseFloat(inputEntry.netSales)) ? '' : parseFloat(inputEntry.netSales).toFixed(2), 'error': ''});
    setInputNotes({'value': usingNotes ? inputEntry.notes : '', 'error': ''});
    setCalculatedHoursWorked({'value': inputEntry.hoursWorked || '', 'error': ''});
    setInputHoursWorked({'value': usingHoursWorked ? '' + parseFloat(inputEntry.hoursWorked) : '', 'error': ''});

    //determine if we have an image for this entry and if we need to get a URL for that image from the server
    const hasImage = inputEntry['hasImage'] === 'true' || inputEntry['hasImage'] === true;
    let hasValidPresignedUrl = typeof inputEntry['imageUrl'] === 'string' && inputEntry['imageUrl'].startsWith('https') 
      && typeof inputEntry['imageExpiresAtTimestamp'] === 'number' && inputEntry['imageExpiresAtTimestamp'] > new Date().getTime();

    if(hasValidPresignedUrl) {
      setShiftReportImageUrl(inputEntry.imageUrl);
    } else if(hasImage) {
      //get a url from the server for this entry's image
      //setFetchingImage(true);
      setShiftReportImageUrl('loading');
      setTimeout(() => {
        axios.post(process.env.REACT_APP_WEBSITE_ACTION_API_URL, {
          'action': 'getEntry',
          'email': userData.email,
          'sessionKey': userData.sessionKey,
          'entryId': parseInt(inputEntry.id)
        }).then((response) => {
          try {
            if (response.status !== 200) {
              throw "non 200 http response code";
            }
            if ('status' in response.data !== true 
              || 'data' in response.data !== true) {
              throw 'bad response object';
            }
            if(response.data.status === 'error' 
              && typeof response.data.data === 'object'
              && 'clientMessage' in response.data.data && typeof response.data.data.clientMessage === 'string'
              && response.data.data.clientMessage.length > 0) {
              throw { 'modalMessage' : response.data.data.clientMessage };
            }

            //user has an outdated or incorrect session key, make them login again
            if(response.data.status === 'success' 
              && 'action' in response.data.data === true
              && response.data.data.action === 'badSessionKey') {
              //setFetchingImage(false);
              ionAlert({
                header: 'Session Expired',
                message: 'You session has expired, you\'ll need to login again to continue.',
                buttons: [
                  {
                    text: 'Ok',
                    handler: () => {
                      userData.logout(); 
                    }
                  }
                ]
              });
              return;
            }

            if(response.data.status !== 'success' 
              || 'action' in response.data.data !== true
              || response.data.data.action !== 'getEntrySuccess') {
              throw 'non success response from server';
            }

            const entryFromServer = JSON.parse(response.data.data.entry);

            if(typeof entryFromServer['imageExpiresIn'] === 'number') { //imageExpiresIn is how long the presigned url for this entry's image is active for, in seconds
              const expiresInWithPaddingInMilliseconds = Math.max(0, (entryFromServer['imageExpiresIn'] - 30) * 1000); //subtract 30 seconds from expiry time as a buffer
              entryFromServer['imageExpiresAtTimestamp'] = new Date().getTime() + expiresInWithPaddingInMilliseconds; 
            } else {
              entryFromServer['imageExpiresAtTimestamp'] = 0; 
            }

            if(typeof entryFromServer['imageUrl'] === 'string' && entryFromServer['imageUrl'].startsWith('https')) {
              setShiftReportImageUrl(entryFromServer['imageUrl']);
              userData.saveUpdatedEntry(entryFromServer);
            }
            //setFetchingImage(false);
          } catch(error) {
            if(typeof error === 'object' && 'modalMessage' in error) {
              showAlert(error.modalMessage);
            } else {
              showAlert('Error loading shift report image, couldn\'t contact the server. Reload the page to try again.');
            }
            //logger.addError('error doing stuff ' + error)
            //setFetchingImage(false);
          }
        }).catch((error) => {
          showAlert('Error loading shift report image, couldn\'t contact the server. Reload the page to try again.');
          //setFetchingImage(false);
        });
      }, 10);
    }
  }, [inputEntry]);

  useEffect(() => {
    if(isOpen) {
      //scroll to the top of the page when it becomes visible (it was sometimes loading a little from the top)
      if(contentRef.current) {
        contentRef.current.scrollToPoint(0, 0);
      }

      //setTimeout(() => {
      //  if(isNewEntry && inputUncategorizedTipsRef.current !== null) {
      //    inputUncategorizedTipsRef.current.setFocus(true); // set the input cursor on total tips
      //  }
      //}, 750);

      setInitialEntry(getCurrentEntry());
    } else {
      onResetButtonClicked();
      //setToastShowing(false); // hide toast when modal is closed?
    }
  }, [isOpen]);
  
  useEffect(() => {
    const onPopState = (event) => {
      if(isOpen) {
        if(finished) {
          onFinished();
        } else if(isNewEntry && !Entry.areEqual(initialEntry, getCurrentEntry())) {
          window.history.pushState('create', '', '#create');
          ionAlert({
            header: 'Discard entry?',
            message: '',
            buttons: [
              {
                text: 'Discard',
                handler: () => {
                  setFinished(true);
                }
              },
              {
                text: 'Keep editing'
              },
            ]
          });
        } else if(!isNewEntry && !Entry.areEqual(inputEntry, getCurrentEntry())) {
          window.history.pushState('edit entry', '', '#edit');
          ionAlert({
            header: 'Discard changes?',
            message: '',
            buttons: [
              {
                text: 'Discard',
                handler: () => {
                  setFinished(true);
                }
              },
              {
                text: 'Keep editing'
              },
            ]
          });
        } else {
          onFinished();
        }
      }
    };

    window.addEventListener('popstate', onPopState);
    return () => {
      window.removeEventListener('popstate', onPopState);
    };
  }, [isOpen, getCurrentEntry, isNewEntry, finished, onFinished, initialEntry, inputEntry]);

  useEffect(() => {
    if(finished) {
      window.history.back(); // this will trigger the pop state listener and invoke onFinished
    }
  }, [finished]);

  //update hours worked based on start/end/break times
  useEffect(() => {
    if(startTimestamp && endTimestamp && usingStartEndTime) {
      const startDate = new Date();
      startDate.setTime(parseInt(parseInt(startTimestamp / 1000)*1000)); // zero out milliseconds part of the timestamp
      startDate.setSeconds(0);
      const endDate = new Date();
      endDate.setTime(parseInt(parseInt(endTimestamp / 1000)*1000)); // zero out milliseconds part of the timestamp
      endDate.setSeconds(0);
      let workedSeconds = Math.round((endDate.getTime()-startDate.getTime())/1000);
      let workedHours = 0;
      let workedMinutes = 0;

      const breakDuration = parseInt(inputBreakDuration.value);
      if(inputBreakDuration.value.length > 0) {
        if(isNaN(breakDuration)) { //check if it's an integer
          setInputBreakDuration({'value': inputBreakDuration.value, 'error': 'Invalid number'});
        } else {
          workedSeconds -= breakDuration*60; //remove break minutes from the elapsed time
        }
      }

      if(workedSeconds < 0) {
        setCalculatedHoursWorked({'value': '', 'error': 'Negative Shift Length'});
        return;
      }

      //break up seconds into hours, minutes, seconds
      if(workedSeconds >= 60*60) { //at least an hour
        workedHours = Math.floor(workedSeconds / (60*60));
        workedSeconds -= workedHours * 60*60; //subtract an hours worth of milliseconds
      }
      if(workedSeconds >= 60) { //at least a minute
        workedMinutes = Math.floor(workedSeconds / (60));
        workedSeconds -= workedMinutes * 60; //subtract a minutes worth of milliseconds
      }

      let workedDuration = '';
      if(workedHours > 0) {
        workedDuration += workedHours + ' Hour' + (workedHours !== 1 ? 's': ''); // plural hours?
      }
      if(workedMinutes > 0) {
        workedDuration += workedDuration.length > 0 ? ' ' : '';
        workedDuration += workedMinutes + ' Minute' + (workedMinutes !== 1 ? 's': ''); // plural minutes?
      }
      if(workedDuration.length === 0) {
        workedDuration = '0 Hours';
      }

      if(parseInt(inputBreakDuration.value) > 0 || startTimestamp !== endTimestamp) {
        //if(workedDuration !== calculatedHoursWorked.value) {
        //  setHoursWorkedHighlighted(true);
        //  setTimeout(() => {
        //    setHoursWorkedHighlighted(false);
        //  }, 500);
        //}
        setCalculatedHoursWorked({'value': workedDuration, 'error': ''});
      } else {
        setCalculatedHoursWorked({'value': '', 'error': ''});
      }
    }
  }, [startTimestamp, endTimestamp, calculatedHoursWorked.value, inputBreakDuration.value, usingStartEndTime]);

  //add the saved resteraunt name for the entry to the list of choices if it isn't in the user's current preferences
  const getResterauntsChoices = useCallback(() => {
    if(inputEntry === null || typeof inputResteraunt !== 'string' || inputResteraunt.length === 0) {
      return userData.resteraunts;
    }

    let inputResterauntInPreferences = false;
    for(const resteraunt of userData.resteraunts) {
      if(resteraunt === inputResteraunt) {
        inputResterauntInPreferences = true;
        break;
      }
    }
    if(!inputResterauntInPreferences) {
      return [...userData.resteraunts, inputResteraunt];
    }
    return userData.resteraunts;
  }, [inputEntry, userData.resteraunts, inputResteraunt]);

  //add the saved job name for the entry to the list of choices if it isn't in the user's current preferences
  const getJobsChoices = useCallback(() => {
    if(inputEntry === null || typeof inputJob !== 'string' || inputJob.length === 0) {
      return userData.jobs;
    }

    let inputJobInPreferences = false;
    for(const job of userData.jobs) {
      if(job === inputJob) {
        inputJobInPreferences = true;
        break;
      }
    }
    if(!inputJobInPreferences) {
      return [...userData.jobs, inputJob];
    }
    return userData.jobs;
  }, [inputEntry, userData.jobs, inputJob]);

  const shiftDateElement = useMemo(() => (
    <div className='flex justify-between items-center'>
      <div className='flex justify-start items-center grow'>
        <div className='inline whitespace-nowrap text-lg mr-3'>Shift Date</div>
      </div>
      <div className='flex justify-end'>
        <TipzDatetimePickerButtons 
          hidden={!isOpen}
          label=''
          dateOnly={true}
          inputValue={formatISO(startTimestamp)}
          onInit={(timestamp) => {}} 
          onClosed={(timestamp) => {
            const date = new Date();
            date.setTime(parseInt(parseInt(timestamp / 1000)*1000));
            date.setHours(0, 0, 0);
            setStartTimestamp(date.getTime());
          }}
        />
      </div>
    </div>
  ), [startTimestamp, isOpen]);

  const resterauntsElement = useMemo(() => (
    <div className='flex justify-center items-center w-full'>
      <div className='flex w-1/2 justify-start items-center'>
        {/*<div className='inline whitespace-nowrap text-lg mr-3'>{getResterauntsChoices().length > 1 && inputEntry === null ? 'Select' : ''} Workplace</div>*/}
        <div className='inline whitespace-nowrap text-lg mr-3'>Workplace</div>
      </div>
      <div className='w-1/2 flex justify-start'>
        {/* need blank label on TipzSelect so list of options is display on the right side */}
        {getResterauntsChoices().length > 1 ?
          <TipzSelect  
            label=''
            items={getResterauntsChoices()}
            error=''
            inputValue={inputResteraunt}
            interfaceType={isWidescreen ? 'popover' : 'action-sheet'}
            onInput={(value) => { 
              setInputResteraunt(value);
            }} 
          /> 
        :
          <TipzInput 
            className='text-right'
            input={userData.resteraunts[0]}
            error=''
            isReadOnly={true}
            onInput={(value) => {}}
          />
        }
      </div>
    </div>
  ), [userData.resteraunts, inputResteraunt, getResterauntsChoices, isWidescreen]);

  const jobsElement = useMemo(() => (
    <div className='flex justify-center items-center'>
      <div className='flex w-1/2 justify-start items-center'>
        {/*<div className='inline whitespace-nowrap text-lg mr-3'>{getJobsChoices().length > 1 && inputEntry === null ? 'Select' : ''} Job</div>*/}
        <div className='inline whitespace-nowrap text-lg mr-3'>Job</div>
      </div>
      <div className='w-1/2 flex justify-start'>
        {/* need blank label on TipzSelect so list of options is display on the right side */}
        {getJobsChoices().length > 1 ?
          <TipzSelect 
            label=''
            items={getJobsChoices()}
            error=''
            inputValue={inputJob}
            interfaceType={isWidescreen ? 'popover' : 'action-sheet'}
            onInput={(value) => { 
              setInputJob(value);
            }} 
          /> 
        :
          <TipzInput
            className='text-right'
            input={userData.jobs[0]}
            error=''
            isReadOnly={true}
            onInput={(value) => {}}
          />
        }
      </div>
    </div>
  ), [userData.jobs, inputJob, getJobsChoices, isWidescreen]);

  const shiftReportImageElement = useMemo(() => (
    <>
      {(inputShiftReportImage.length > 0 || shiftReportImageUrl !== null) ?
        <div className='flex flex-col justify-middle items-start'>
          <div className='text-lg'>Shift Report Image</div>
          <div className='self-end'>
          {/* TODO: enlarge image here when clicked */}
          {inputShiftReportImage.length > 0 &&
            <img 
              src={inputShiftReportImage}
              className={'rounded ' + (imageExpanded ? 'w-full' : 'w-full max-h-[40vh]')}
              alt='shift receipt' 
              onClick={() => {
                setImageExpanded(!imageExpanded);
              }}
            />
          }
          {(inputShiftReportImage.length === 0 && typeof shiftReportImageUrl === 'string' && shiftReportImageUrl.startsWith('https')) &&
            <img 
              src={shiftReportImageUrl} 
              className={'rounded ' + (imageExpanded ? 'w-full' : 'w-full max-h-[40vh]')}
              alt='shift receipt' 
              onClick={() => {
                setImageExpanded(!imageExpanded);
              }}
            />
          }
          {(inputShiftReportImage.length === 0 && shiftReportImageUrl === 'loading') &&
            <IonSpinner></IonSpinner>
          }
          </div>
          {(inputShiftReportImage.length > 0 || shiftReportImageUrl !== null) &&
            <div className='flex justify-end w-full'>
              {process.env.REACT_APP_LIVE_MODE !== 'true' &&
                <IonChip 
                  className='self-end'
                  onClick={() => {
                    try {
                      (async() => {
                        if(inputShiftReportImage.length > 0 && window.Tesseract) {
                          setOcrProgress('0');

                          const worker = await window.Tesseract.createWorker('eng', 1, {
                            logger: (progress) => {
                              const percent = parseFloat(progress.progress);
                              if(isNaN(percent)) {
                                return;
                              }
                              const newOcrProgress = parseInt((percent*100));
                              const currentOcrProgress = ocrProgressRef.current;
                              if(currentOcrProgress === 0 && (newOcrProgress === 100 || newOcrProgress === 50 || newOcrProgress === 10)) { // block jumping effect before real progress events start
                                return;
                              }
                              setOcrProgress(''+newOcrProgress);
                              ocrProgressRef.current = newOcrProgress;
                            }
                          });
                          worker.setParameters({
                            tessedit_pagseg_mode: window.Tesseract.PSM.SPARSE_TEXT_OSD,
                          });
                          const { data: { text } } = await worker.recognize(inputShiftReportImage);
                          //console.log('---- Tesseract text', text);
                          //alert(text);
                          setOcrProgress('data:\n'+text.toString());

                          //const worker = await window.Tesseract.createWorker('eng', 1, {legacyCore: true, legacyLang: true});
                          //const { data } = await worker.detect(inputShiftReportImage);
                          //console.log('---- Tesseract detect', data);

                          await worker.terminate();
                          //console.log('---- Tesseract ended');
                        }
                      })();
                    } catch(error) {
                    }
                  }}
                >
                  <IonLabel>OCR</IonLabel>
                </IonChip>
              }
              {/*<IonChip 
                className='self-end'
                onClick={async () => {
                  console.log('---1');
                  if(inputShiftReportImage > 0) {
                    console.log('---2');
                    const url = URL.createObjectURL('data:image/jpeg;base64,'+inputShiftReportImage);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = 'image.jpeg';
                    a.click();
                    URL.revokeObjectURL(url);
                  } else {
                    fetch(shiftReportImageUrl)
                      .then(response => response.blob())
                      .then(blob => {
                        console.log('---2');
                        const url = URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        a.href = url;
                        a.download = 'image.jpeg';
                        a.click();
                        URL.revokeObjectURL(url);
                      })
                      .catch(error => {
                        console.log('download image error: ', error);
                      })
                  }
                }}
              >
                <IonLabel>Download</IonLabel>
              </IonChip>*/}
              <IonChip 
                className='self-end'
                onClick={async () => {
                  try {
                    const imagePicker = await Camera.getPhoto({
                      quality: 90,
                      allowEditing: true,
                      resultType: CameraResultType.Base64,
                      correctOrientation: true
                    });
                    const base64String = imagePicker.base64String;

                    const image = new Image();
                    image.onload = () => {
                    };
                    image.src = 'data:image/jpeg;base64,'+base64String;

                    setInputShiftReportImage('data:image/jpeg;base64,'+base64String);
                    //TODO: validate the the image is between 1 and 10 MB, server will enforce this later
                    setInputImageAction('upload');
                    setOcrProgress('');
                  } catch(error) {
                  }
                }}
              >
                <IonLabel>Change Image</IonLabel>
              </IonChip>
              <IonChip 
                className='self-end'
                onClick={() => {
                  setInputShiftReportImage('');
                  setShiftReportImageUrl(null);
                  setInputImageAction('delete');
                }}
              >
                <IonLabel>Remove Image</IonLabel>
              </IonChip>
            </div>
          }
          {ocrProgress.length > 0 &&
            <div className='overflow-scroll whitespace-nowrap w-full'>
              <pre className='text-left'>OCR {isNaN(ocrProgress) ? ocrProgress : ocrProgress + '%'}</pre>
             </div>
          }
        </div>
        :
        <></>
      }
    </>
  ), [inputShiftReportImage, shiftReportImageUrl, imageExpanded, ocrProgress]);

  const onDeleteButton = useCallback((event) => {
    if(inputEntry === null) {
      return;
    }

    ionAlert({
      header: 'Confirm Delete',
      message: 'Are you sure you want to delete this entry?',
      buttons: [
        {
          text: 'Cancel',
        },
        {
          text: 'Delete',
          handler: () => {
            setIsDeletingEntry(true);
            setTimeout(() => {
              axios.post(process.env.REACT_APP_WEBSITE_ACTION_API_URL, {
                'action': 'deleteEntry',
                'email': userData.email,
                'sessionKey': userData.sessionKey,
                'entry': JSON.stringify(inputEntry)
              }).then((response) => {
                try {
                  if (response.status !== 200) {
                    throw "non 200 http response code";
                  }
                  if ('status' in response.data !== true 
                    || 'data' in response.data !== true) {
                    throw 'bad response object';
                  }
                  if(response.data.status === 'error' 
                    && typeof response.data.data === 'object'
                    && 'clientMessage' in response.data.data && typeof response.data.data.clientMessage === 'string'
                    && response.data.data.clientMessage.length > 0) {
                    throw { 'modalMessage' : response.data.data.clientMessage };
                  }

                  //user has an outdated or incorrect session key, make them login again
                  if(response.data.status === 'success' 
                    && 'action' in response.data.data === true
                    && response.data.data.action === 'badSessionKey') {
                    setIsDeletingEntry(false);
                    ionAlert({
                      header: 'Session Expired',
                      message: 'You session has expired, you\'ll need to login again to continue.',
                      buttons: [
                        {
                          text: 'Ok',
                          handler: () => {
                            userData.logout(); 
                          }
                        }
                      ]
                    });
                    return;
                  }

                  if(response.data.status !== 'success' 
                    || 'action' in response.data.data !== true
                    || response.data.data.action !== 'deleteEntrySuccess'
                    || 'actionId' in response.data.data !== true) {
                    throw 'non success response from server';
                  }
                  
                  //console.log('action id', response.data.data.actionId, typeof response.data.data.actionId);

                  //success
                  setIsDeletingEntry(false);
                  setTimeout(() => {
                    userData.deleteEntry(inputEntry.id);
                    showToastMessage('Entry Deleted', 'success', 3000);
                  }, 10);
                  setFinished(true);
                } catch(error) {
                  if(typeof error === 'object' && 'modalMessage' in error) {
                    showAlert(error.modalMessage);
                  } else {
                    showAlert('Something went wrong when deleting your entry. Please try again. Reload the page and try again if the problem persists.');
                  }
                  setIsDeletingEntry(false);
                  //logger.addError('error doing stuff ' + error)
                }
              }).catch((error) => {
                showAlert('Couldn\'t contact the server. Please try again. Reload the page and try again if the problem persists.');
                setIsDeletingEntry(false);
              });
            }, 10);
          }
        }
      ]
    });
  }, [inputEntry, ionAlert, showToastMessage, userData, showAlert]);

  const uploadInputImage = (imageSrc, uploadUrl, uploadFields, onSuccess, onFailure) => {
    const formData = new FormData();
    Object.keys(uploadFields).forEach((key) => {
      //formData.append(key, key === 'key' ? 'test.jpg' : uploadFields[key]); //test using a different key than specified one
      formData.append(key, uploadFields[key]);
    });
    formData.append('Content-Type', 'image/jpeg');

    // we need this binary date to be just the image data, no header 'data:image/jpeg;base64,'
    const binaryString = atob(imageSrc.replace(/^data:image\/[a-zA-Z0-9]+;base64,/,'')); // remove image data header
    const binaryLength = binaryString.length;
    const bytes = new Uint8Array(binaryLength);
    for(let i = 0; i < binaryLength; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    //formData.append('file', atob(imageSrc)); //add the image to the form data, converting it from base64 to binary
    formData.append('file', new Blob([bytes], { type: 'image/jpeg' })); //add the binary image data to the form

    const parseProgress = (progressEvent) => {
      const progressPercent = (progressEvent.loaded / progressEvent.total);
      setImageUploadProgress(progressPercent);
    };

    //upload image to AWS S3 using the presigned url returned by the server
    axios.post(uploadUrl, formData, {
      onUploadProgress: parseProgress
    }).then((response) => {
      try {
        if (response.status !== 204) { //AWS S3 returns 204 No Content on a successfull upload
          throw "non 204 http response code";
        }

        onSuccess();
      } catch(error) {
        if(typeof error === 'object' && 'modalMessage' in error) {
          showAlert(error.modalMessage);
        } else {
          showAlert('Something went wrong when adding your entry image. Please try again. Reload the page and try again if the problem persists.');
          console.log('Something went wrong when adding entry image', error);
        }
        onFailure();
        //logger.addError('error doing stuff ' + error)
      }
    }).catch((error) => {
      showAlert('Couldn\'t contact the server. Please try again. Reload the page and try again if the problem persists.');
      onFailure();
    });
  };

  const onSaveButtonClicked = useCallback((event) => {
    if(showFormButtonError) {
      //wait until the error message clears before attempting to save again
      return;
    }

    let hasErrors = false;
    let errorToastMesssage = '';

    const hoursWorkedError = getNumberError(inputHoursWorked.value);
    if(usingHoursWorked && hoursWorkedError.length > 0 && inputHoursWorked.value.length > 0) {
      errorToastMesssage = 'Hours Worked ' + hoursWorkedError;
      setInputHoursWorked({'value': inputHoursWorked.value, 'error': hoursWorkedError});
      hasErrors = true;
    }

    const cashTipsError = getNumberError(inputCashTips.value);
    if(usingCashCreditTips && cashTipsError.length > 0) {
      errorToastMesssage = 'Cash Tips ' + cashTipsError ;
      setInputCashTips({'value': inputCashTips.value, 'error': cashTipsError});
      hasErrors = true;
    }

    const creditTipsError = getNumberError(inputCreditTips.value);
    if(usingCashCreditTips && creditTipsError.length > 0) {
      errorToastMesssage = 'Credit Tips ' + creditTipsError ;
      setInputCreditTips({'value': inputCreditTips.value, 'error': creditTipsError});
      hasErrors = true;
    }

    const tipInError = getNumberError(inputTipIn.value);
    if(usingTipInOut && tipInError.length > 0) {
      errorToastMesssage = 'Tip In ' + tipInError ;
      setInputTipIn({'value': inputTipIn.value, 'error': tipInError});
      hasErrors = true;
    }

    const tipOutError = getNumberError(inputTipOut.value);
    if(usingTipInOut && tipOutError.length > 0) {
      errorToastMesssage = 'Tip Out ' + tipOutError ;
      setInputTipOut({'value': inputTipOut.value, 'error': tipOutError});
      hasErrors = true;
    }

    const netSalesError = getNumberError(inputNetSales.value);
    if(usingNetSales && netSalesError.length > 0 && inputNetSales.value.length > 0) {
      errorToastMesssage = 'Net Sales ' + netSalesError ;
      setInputNetSales({'value': inputNetSales.value, 'error': netSalesError});
      hasErrors = true;
    }

    if(usingNotes && inputNotes.error.length > 0) {
      hasErrors = true;
    }

    if(inputBreakDuration.error.length > 0) {
      errorToastMesssage = 'Break Length ' + inputBreakDuration.error;
      hasErrors = true;
    }

    if(usingStartEndTime && calculatedHoursWorked.error.length > 0) {
      errorToastMesssage = calculatedHoursWorked.error;
      hasErrors = true;
    } 
    //if(calculatedHoursWorked.value.length === 0) {
    //  errorToastMesssage = 'Shift Length Cannot Be Zero';
    //  hasErrors = true;
    //} 

    if(userData.resteraunts.length > 1 && inputResteraunt.length === 0) {
      errorToastMesssage = 'No Workplace Selected';
      hasErrors = true;
    } 
    if(userData.jobs.length > 1 && inputJob.length === 0) {
      errorToastMesssage = 'No Job Selected';
      hasErrors = true;
    } 

    if(errorToastMesssage.length > 0) {
      showToastMessage(errorToastMesssage, 'danger', 3000);
    }
    if(hasErrors) {
      setShowFormButtonError(true);
      setTimeout(() => {
        setShowFormButtonError(false);
      }, 3000);
      return;
    }

    // save entry json
    // const entryId = (inputEntry && typeof inputEntry.id === 'number') ? inputEntry.id : userData.getNewIdForEntry();
    const entry = getCurrentEntry();

    if(isNewEntry) {
      const onSuccess = () => {
        setIsAddingEntry(false);
        showToastMessage('Entry Added!', 'success', 4000);
        setFinished(true);
      };

      const onFailure = () => {
        setIsAddingEntry(false);
      };

      setIsAddingEntry(true);
      setImageUploadProgress(0);
      axios.post(process.env.REACT_APP_WEBSITE_ACTION_API_URL, {
        'action': 'addEntry',
        'email': userData.email,
        'sessionKey': userData.sessionKey,
        'entry': JSON.stringify(entry),
        'imageAction': inputImageAction,
      }).then((response) => {
        try {
          if (response.status !== 200) {
            throw "non 200 http response code";
          }
          if ('status' in response.data !== true 
            || 'data' in response.data !== true) {
            throw 'bad response object';
          }
          if(response.data.status === 'error' 
            && typeof response.data.data === 'object'
            && 'clientMessage' in response.data.data && typeof response.data.data.clientMessage === 'string'
            && response.data.data.clientMessage.length > 0) {
            throw { 'modalMessage' : response.data.data.clientMessage };
          }

          //user has an outdated or incorrect session key, make them login again
          if(response.data.status === 'success' 
            && 'action' in response.data.data === true
            && response.data.data.action === 'badSessionKey') {
            onFailure();
            ionAlert({
              header: 'Session Expired',
              message: 'You session has expired, you\'ll need to login again to continue.',
              buttons: [
                {
                  text: 'Ok',
                  handler: () => {
                    userData.logout(); 
                  }
                }
              ]
            });
            return;
          }

          if(response.data.status !== 'success' 
            || 'action' in response.data.data !== true
            || response.data.data.action !== 'addEntrySuccess'
            || 'entry' in response.data.data !== true
            || typeof response.data.data.entry !== 'string'
            || 'actionId' in response.data.data !== true) {
            throw 'non success response from server';
          }

          //console.log('action id', response.data.data.actionId, typeof response.data.data.actionId);
          const entryFromServer = JSON.parse(response.data.data.entry);

          // save entry locally
          userData.saveNewEntry(entryFromServer);

          //we received a presigned url to upload the image for this entry and we have an image to upload
          if(inputShiftReportImage.length > 0 && 'imageUploadUrl' in response.data.data && 'imageUploadFields' in response.data.data) {
            uploadInputImage(inputShiftReportImage, response.data.data.imageUploadUrl, response.data.data.imageUploadFields, onSuccess, onFailure);
          } else {
            onSuccess();
          }
        } catch(error) {
          if(typeof error === 'object' && 'modalMessage' in error) {
            showAlert(error.modalMessage);
          } else {
            showAlert('Something went wrong when adding your entry. Please try again. Reload the page and try again if the problem persists.');
          }
          onFailure();
          //logger.addError('error doing stuff ' + error)
        }
      }).catch((error) => {
        showAlert('Couldn\'t contact the server. Please try again. Reload the page and try again if the problem persists.');
        onFailure();
      });
    } else {
      const onSuccess = () => {
        setIsUpdatingEntry(false);
        showToastMessage('Entry Updated', 'success', 3000);
        setFinished(true);
      };
      const onFailure = () => {
        setIsUpdatingEntry(false);
      };

      setIsUpdatingEntry(true);
      setImageUploadProgress(0);
      axios.post(process.env.REACT_APP_WEBSITE_ACTION_API_URL, {
        'action': 'updateEntry',
        'email': userData.email,
        'sessionKey': userData.sessionKey,
        'entry': JSON.stringify(entry),
        'imageAction': inputImageAction
      }).then((response) => {
        try {
          if (response.status !== 200) {
            throw "non 200 http response code";
          }
          if ('status' in response.data !== true 
            || 'data' in response.data !== true) {
            throw 'bad response object';
          }
          if(response.data.status === 'error' 
            && typeof response.data.data === 'object'
            && 'clientMessage' in response.data.data && typeof response.data.data.clientMessage === 'string'
            && response.data.data.clientMessage.length > 0) {
            throw { 'modalMessage' : response.data.data.clientMessage };
          }

          //user has an outdated or incorrect session key, make them login again
          if(response.data.status === 'success' 
            && 'action' in response.data.data === true
            && response.data.data.action === 'badSessionKey') {
            onFailure();
            ionAlert({
              header: 'Session Expired',
              message: 'You session has expired, you\'ll need to login again to continue.',
              buttons: [
                {
                  text: 'Ok',
                  handler: () => {
                    userData.logout(); 
                  }
                }
              ]
            });
            return;
          }

          if(response.data.status !== 'success' 
            || 'action' in response.data.data !== true
            || response.data.data.action !== 'updateEntrySuccess'
            || 'entry' in response.data.data !== true
            || typeof response.data.data.entry !== 'string'
            || 'actionId' in response.data.data !== true) {
            throw 'non success response from server';
          }

          //console.log('action id', response.data.data.actionId, typeof response.data.data.actionId);
          const entryFromServer = JSON.parse(response.data.data.entry);

          // save entry locally
          userData.saveUpdatedEntry(entryFromServer);

          //we received a presigned url to upload the image for this entry and we have an image to upload
          if(inputShiftReportImage.length > 0 && 'imageUploadUrl' in response.data.data && 'imageUploadFields' in response.data.data) {
            uploadInputImage(inputShiftReportImage, response.data.data.imageUploadUrl, response.data.data.imageUploadFields, onSuccess, onFailure);
          } else {
            onSuccess();
          }
        } catch(error) {
          if(typeof error === 'object' && 'modalMessage' in error) {
            showAlert(error.modalMessage);
          } else {
            showAlert('Something went wrong when updating your entry. Please try again. Reload the page and try again if the problem persists.');
          }
          onFailure();
          //logger.addError('error doing stuff ' + error)
        }
      }).catch((error) => {
        showAlert('Couldn\'t contact the server. Please try again. Reload the page and try again if the problem persists.');
        onFailure();
      });
    }
  }, [inputResteraunt, inputBreakDuration, inputHoursWorked, inputCashTips, inputCreditTips, inputTipIn, inputTipOut, inputNetSales, inputNotes, showFormButtonError, showToastMessage, inputJob, userData, showAlert, isNewEntry, ionAlert, usingCashCreditTips, usingNetSales, usingNotes, usingTipInOut, usingHoursWorked, inputShiftReportImage, inputImageAction, getCurrentEntry, usingStartEndTime, calculatedHoursWorked]);

  const getHeader = useCallback(() => {
    console.log('render '+(isNewEntry === true ? 'create' : 'edit')+' header');
    return (
      <IonToolbar color={(showFormButtonError ? 'danger' : (showFormSuccess ? 'success' : 'primary'))}>
        <div className='flex justify-evenly'>
          <TipzButton 
            isError={showFormButtonError}
            isSuccess={showFormSuccess}
            onClick={() => {
              window.history.back();
            }}
          >
            <IonIcon icon={arrowBack} className='text-xl align-middle' /> Back
          </TipzButton>
          <div className='grow-[10]'>
            <IonTitle className=''>{isNewEntry ? 'New' : 'Edit '} Entry</IonTitle>
          </div>
          <div className='mr-2'>
            <TipzLogoMinimal darkMode={false} className='h-[40px]' />
          </div>
          {/*<TipzButton 
            isError={showFormButtonError}
            isSuccess={showFormSuccess}
            onClick={onSaveButtonClicked}
            color='tertiary'
            className=''
          >
            <strong>Save</strong>
          </TipzButton>*/}
        </div>
      </IonToolbar>
    );
  }, [isNewEntry, showFormButtonError, showFormSuccess]);

  const headerJSX = useMemo(() => {
    return getHeader();
  }, [getHeader]);

  const getContent = useCallback(() => {
    console.log('render '+(isNewEntry === true ? 'create' : 'edit')+' content');
    const currentEntry = getCurrentEntry();
    const currentEntryTipsPerHour = Entry.getTipsPerHour(currentEntry);

    return (
    <>
      <div className='mx-auto px-2 text-center my-4 mb-[20vh]'>
        <div className='flex flex-col gap-y-2 justify-center mx-auto max-w-screen-sm'>
          {resterauntsElement}
          {jobsElement}
          {/* shift date */}
          {!usingStartEndTime && shiftDateElement}
          {usingStartEndTime && 
            <div className={(startEndTimeHighlighted ? 'outline-[#ffff00ff]' : 'outline-[#ffff0000]') + ' tipzCard p-1.5 outline outline-offset-2 duration-500 transition-all flex flex-col gap-y-1'}>
              {/* start time */}
              <div ref={startTimeRef} className='flex justify-center items-center'>
                <div className='flex justify-start items-center grow'>
                  <div className='inline whitespace-nowrap text-lg mr-3'>Shift Start</div>
                </div>
                  <div className='px-3 py-2 rounded-md cursor-pointer tipzTime'
                    onClick={() => {
                      setModalTime(formatISO(new Date(startTimestamp)));
                      setModalTimeChangeCallback({callback: (timestamp) => {
                        const date = new Date();
                        date.setTime(timestamp);
                        date.setSeconds(0);
                        date.setTime(parseInt(parseInt(date.getTime()/1000)*1000)); //remove milliseconds part
                        setStartTimestamp(date.getTime());
                      }});
                      showDatePicker(true);
                    }}
                  >
                    {format(new Date(startTimestamp), 'MMM d, yyyy @ p')}
                  </div>
              </div>
              {/* end time */}
              <div className='flex justify-center items-center'>
                <div className='flex justify-start items-center grow'>
                  <div className='inline whitespace-nowrap text-lg mr-3'>Shift End</div>
                </div>
                <div className='px-3 py-2 rounded-md cursor-pointer tipzTime' 
                  onClick={() => {
                    setModalTime(formatISO(new Date(endTimestamp)));
                    setModalTimeChangeCallback({callback: (timestamp) => {
                      const date = new Date();
                      date.setTime(timestamp);
                      date.setSeconds(0);
                      date.setTime(parseInt(parseInt(date.getTime()/1000)*1000)); //remove milliseconds part
                      setEndTimestamp(date.getTime());
                    }});
                    showDatePicker(true);
                  }}
                >  
                  {format(new Date(endTimestamp), 'MMM d, yyyy @ p')}
                </div>
              </div>
              {/* break length */}
              <div className='flex justify-center items-center w-full'>
                <div className='flex justify-start items-center w-1/2'>
                  <div className='flex flex-col sm:flex-row text-lg items-start'>
                    <span className=''>Break Length</span>
                    <span className='text-sm sm:ml-1 mt-[-0.6em] sm:mt-0'>(minutes)</span>
                  </div>
                </div>
                <div className='flex overflow-hidden w-1/2'>
                  <TipzInput 
                    className='w-full'
                    inputRef={inputBreakDurationRef}
                    input={inputBreakDuration.value}
                    inputmode='numeric'
                    error={inputBreakDuration.error}
                    onInput={(value) => {
                      setInputBreakDuration({'value': value, 'error': ''});
                    }}
                    onEnterKey={() => {
                      if(inputCashTipsRef.current !== null) {
                        inputCashTipsRef.current.setFocus(true);
                      }
                    }}
                  />
                </div>
              </div>
              {/* hours worked */}
              <div className='flex justify-end items-center w-full h-[44px]'>
                {calculatedHoursWorked.error.length > 0 ?
                  <IonLabel color='danger' className='font-bold'>{calculatedHoursWorked.error}</IonLabel>
                :
                  <div className='text-lg mr-2'><span className='font-bold transition-all text-lg tipzHours'>{calculatedHoursWorked.value.length === 0 ? '0 Hours' : calculatedHoursWorked.value}</span> Total Hours</div>
                }
              </div>
              {/*<div className='flex justify-center items-center'>
                <div className='flex justify-start items-center grow'>
                  <div className='inline whitespace-nowrap text-lg mr-3'>Hours Worked</div>
                </div>
                <div className='w-[60%] flex justify-end items-center mr-2 h-[44px]'>
                    {calculatedHoursWorked.error.length > 0 ?
                      <IonLabel color='danger' className='font-bold'>{calculatedHoursWorked.error}</IonLabel>
                    :
                      (calculatedHoursWorked.value.length > 0 ?
                        <IonLabel className={`duration-100 font-bold transition-all ${hoursWorkedHighlighted ? 'text-amber-500 dark:text-amber-400 scale-[1.1]' : ''}`}>{calculatedHoursWorked.value}</IonLabel>
                      :
                        <IonNote className='leading-4'>(automatically calculated)</IonNote>
                      )
                    }
                </div>
              </div>*/}
            </div>
          }
          {(usingHoursWorked && !usingStartEndTime) && 
            <div className={(hoursWorkedHighlighted ? 'outline-[#ffff00ff]' : 'outline-[#ffff0000]') + ' outline outline-offset-2 duration-500 transition-all'}>
              <div className='flex justify-center items-center w-full'>
                <div className='flex justify-start items-center w-1/2'>
                  <div className='text-lg'>Hours Worked</div>
                </div>
                <div className='flex overflow-hidden w-1/2'>
                  <TipzInput 
                    inputRef={inputHoursWorkedRef}
                    input={inputHoursWorked.value}
                    inputmode='numeric'
                    error={inputHoursWorked.error}
                    onInput={(value) => {
                      setInputHoursWorked({'value': value, 'error': ''});
                    }}
                    onEnterKey={() => {
                      if(inputCreditTipsRef.current !== null) {
                        inputCreditTipsRef.current.setFocus(true);
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          }
          {(!usingCashCreditTips && !usingTipInOut) && 
            <div className='flex justify-center items-center w-full '>
              <div className='flex justify-between items-center w-1/2 pr-1'>
                <div className='text-lg'>Total Tips</div>
                <span className='tipzMoney'>$</span>
              </div>
              <div className='flex overflow-hidden w-1/2'>
                <TipzInput 
                  inputRef={inputUncategorizedTipsRef}
                  input={inputUncategorizedTips.value} 
                  error={inputUncategorizedTips.error} 
                  inputmode='numeric'
                  inputType='money'
                  onInput={(value) => {
                    setInputUncategorizedTips({'value': value, 'error': ''});
                  }}
                  onEnterKey={() => {
                    if(inputNetSalesRef.current !== null) {
                      inputNetSalesRef.current.setFocus(true);
                    }
                  }}
                />
              </div>
            </div>
          }
          {(usingCashCreditTips || usingTipInOut) && 
            <div className='tipzCard p-1.5 flex flex-col gap-y-1'>
              {usingCashCreditTips &&
                <div className={(cashCreditTipsHighlighted ? 'outline-[#ffff00ff]' : 'outline-[#ffff0000]') + ' outline outline-offset-2 duration-500 transition-all flex flex-col gap-y-1'}>
                  <div className='flex justify-center items-center w-full'>
                    <div className='flex justify-between items-center w-1/2 pr-1'>
                      <div className='text-lg'>Credit Card Tips</div>
                      <span className='tipzMoney'>$</span>
                    </div>
                    <div className='flex overflow-hidden w-1/2'>
                      <TipzInput 
                        inputRef={inputCreditTipsRef}
                        input={inputCreditTips.value} 
                        error={inputCreditTips.error} 
                        inputmode='numeric'
                        inputType='money'
                        onInput={(value) => {
                          setInputCreditTips({'value': value, 'error': ''});
                        }}
                        onEnterKey={() => {
                          if(inputCashTipsRef.current !== null) {
                            inputCashTipsRef.current.setFocus(true);
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className='flex justify-center items-center w-full'>
                    <div className='flex justify-between items-center w-1/2 pr-1'>
                      <div className='text-lg'>Cash Tips</div>
                      <span className='tipzMoney'>$</span>
                    </div>
                    <div className='flex overflow-hidden w-1/2'>
                      <TipzInput 
                        inputRef={inputCashTipsRef}
                        input={inputCashTips.value}
                        error={inputCashTips.error}
                        inputmode='numeric'
                        inputType='money'
                        onInput={(value) => {
                          setInputCashTips({'value': value, 'error': ''});
                        }}
                        onEnterKey={() => {
                          if(inputTipInRef.current !== null) {
                            inputTipInRef.current.setFocus(true);
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              }
              {usingTipInOut && 
                <div className={(tipInHighlighted ? 'outline-[#ffff00ff]' : 'outline-[#ffff0000]') + ' outline outline-offset-2 duration-500 transition-all flex flex-col gap-y-1'}>
                  <div className='flex justify-center items-center w-full'>
                    <div className='flex justify-between items-center w-1/2 pr-1'>
                      <div className='text-lg'>Tip In</div>
                      <span className='tipzMoney'>$</span>
                    </div>
                    <div className='flex overflow-hidden w-1/2'>
                      <TipzInput
                        inputRef={inputTipInRef}
                        input={inputTipIn.value}
                        inputmode='numeric'
                        inputType='money'
                        error={inputTipIn.error}
                        onInput={(value) => {
                          setInputTipIn({'value': value, 'error': ''});
                        }}
                        onEnterKey={() => {
                          if(inputTipOutRef.current !== null) {
                            inputTipOutRef.current.setFocus(true);
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className='flex justify-center items-center w-full'>
                    <div className='flex justify-between items-center w-1/2 pr-1'>
                      <div className='text-lg'>Tip Out</div>
                      <span className='tipzMoney'>$</span>
                    </div>
                    <div className='flex overflow-hidden w-1/2'>
                      <TipzInput
                        inputRef={inputTipOutRef}
                        input={inputTipOut.value}
                        inputmode='numeric'
                        inputType='money'
                        error={inputTipOut.error}
                        onInput={(value) => {
                         setInputTipOut({'value': value, 'error': ''});
                        }}
                        onEnterKey={() => {
                          if(inputNetSalesRef.current !== null) {
                            inputNetSalesRef.current.setFocus(true);
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              }
              <div className='flex justify-end items-center h-[44px] w-full'>
                <div className='font-bold transition-all text-lg mr-2'>
                  $<span className='tipzMoney'>
                    {Util.getCurrencyString(Entry.getTotalTips(getCurrentEntry()), true)}
                  </span>
                </div>
                <div className='text-lg mr-2'>Total Tips</div>
              </div>
            </div>
          }
          {currentEntryTipsPerHour > 0  && 
            <div className='flex justify-center items-center w-full'>
              <div className='flex overflow-hidden w-full justify-end items-center'>
                <span className='tipzPerHour mr-2 text-lg'>{currentEntryTipsPerHour.toFixed(2)}</span>
                <div className='text-lg mr-2'>Tips / Hour</div>
              </div>
            </div>
          }
          {usingNetSales && 
            <div className={(netSalesHighlighted ? 'outline-[#ffff00ff]' : 'outline-[#ffff0000]') + ' outline outline-offset-2 duration-500 transition-all'}>
              <div className='flex justify-center items-center w-full'>
                <div className='flex justify-between items-center w-1/2 pr-1'>
                  <div className='text-lg'>Net Sales</div>
                  <span className='tipzMoney'>$</span>
                </div>
                <div className='flex overflow-hidden w-1/2'>
                  <TipzInput
                    inputRef={inputNetSalesRef}
                    input={inputNetSales.value}
                    inputmode='numeric'
                    inputType='money'
                    error={inputNetSales.error}
                    onInput={(value) => {
                      setInputNetSales({'value': value, 'error': ''});
                    }}
                    onEnterKey={() => {
                      if(inputNotesRef.current !== null) {
                        inputNotesRef.current.setFocus(true);
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          }
          {shiftReportImageElement}
          {usingNotes &&
            <div className={(notesHighlighted ? 'outline-[#ffff00ff]' : 'outline-[#ffff0000]') + ' outline outline-offset-2 duration-500 transition-all'}>
              <TipzTextarea 
                label='Notes' 
                inputRef={inputNotesRef}
                input={inputNotes.value}
                error={inputNotes.error}
                placeholder='' 
                maxLength={1000}
                onInput={(value) => {
                  const validChars = [];
                  const invalidChars = [];
                  //only allow regular ascii code characters. also allow line break (10) and carriage return (13)
                  for(let i = 0; i < value.length; i++) {
                    const charCode = value.charCodeAt(i);
                    if((charCode < 32 || charCode > 126) && charCode !== 10 && charCode !== 13) {
                      invalidChars.push(value[i]);
                    } else {
                      validChars.push(value[i]);
                    }
                  }

                  if(value.length > 1000) {
                    setInputNotes({'value': validChars.join(''), 'error': 'Too long'});
                  } else {
                    setInputNotes({'value': validChars.join(''), 'error': ''});
                  }
                }}
              />
            </div>
          }
          {/*<div className=''>
            {tipinList.map((element, index) => (
              <IonChip key={index} className='text-lg'><IonLabel>{element}</IonLabel></IonChip>
            ))}
          </div>*/}
          {/* Buttons to add more types of info to the report (tipin, tipout, etc) */}
          <div className='h-[20px] w-full'></div>
          <div className='text-left'>
            {!usingCashCreditTips &&
              <IonChip 
                className='text-lg'
                onClick={() => {
                  setUsingCashCreditTips(true);
                  setTimeout(() => {
                    if(!isNaN(parseFloat(inputUncategorizedTips.value))) {
                      setInputCreditTips({'value': inputUncategorizedTips.value, 'error': ''});
                    }
                    setInputUncategorizedTips({'value': '', 'error': ''});
                    if(inputCreditTipsRef.current !== null) {
                      inputCreditTipsRef.current.setFocus(true);
                    }
                    setCashCreditTipsHighlighted(true);
                    setTimeout(() => {
                      setCashCreditTipsHighlighted(false);
                    }, 1000);
                  }, 10);
                }}
              >
                <IonIcon icon={addCircle} className='text-2xl mr-2' />
                <IonLabel>Cash & Credit Card Tips</IonLabel>
              </IonChip>
            }
            {!usingTipInOut &&
              <IonChip 
                className='text-lg'
                onClick={() => {
                  setUsingTipInOut(true);
                  setTimeout(() => {
                    if(!isNaN(parseFloat(inputUncategorizedTips.value))) {
                      setInputTipIn({'value': inputUncategorizedTips.value, 'error': ''});
                    }
                    setInputUncategorizedTips({'value': '', 'error': ''});
                    if(inputTipInRef.current !== null) {
                      inputTipInRef.current.setFocus(true);
                    }
                    setTipInHighlighted(true);
                    setTimeout(() => {
                      setTipInHighlighted(false);
                    }, 1000);
                  }, 10);
                }}
              >
                <IonIcon icon={addCircle} className='text-2xl mr-2' />
                <IonLabel>Tip In & Tip Out</IonLabel>
              </IonChip>
            }
            {!usingNetSales &&
              <IonChip 
                className='text-lg'
                onClick={() => {
                  setUsingNetSales(true);
                  setTimeout(() => {
                    if(inputNetSalesRef.current !== null) {
                      inputNetSalesRef.current.setFocus(true);
                    }
                    setNetSalesHighlighted(true);
                    setTimeout(() => {
                      setNetSalesHighlighted(false);
                    }, 1000);
                  }, 10);
                }}
              >
                <IonIcon icon={addCircle} className='text-2xl mr-2' />
                <IonLabel>Net Sales</IonLabel>
              </IonChip>
            }
            {(!usingStartEndTime) &&
              <IonChip 
                className='text-lg'
                onClick={() => {
                  const currentTimestamp = new Date().getTime();
                  setUsingStartEndTime(true);
                  setStartTimestamp(currentTimestamp);
                  setEndTimestamp(currentTimestamp);
                  setTimeout(() => {
                    //if(startTimeRef.current !== null && contentRef.current !== null) {
                    //  contentRef.current.scrollToPoint(0, startTimeRef.current.offsetTop-30);
                    //}
                    if(contentRef.current) {
                    contentRef.current.scrollToPoint(0, 0);
                    }

                    setStartEndTimeHighlighted(true);
                    setTimeout(() => {
                      setStartEndTimeHighlighted(false);
                    }, 1000);
                  }, 10);
                }}
              >
                <IonIcon icon={addCircle} className='text-2xl mr-2' />
                <IonLabel>Start & End Time</IonLabel>
              </IonChip>
            }
            {(!usingHoursWorked && !usingStartEndTime) &&
              <IonChip 
                className='text-lg'
                onClick={() => {
                  setUsingHoursWorked(true);
                  setTimeout(() => {
                    if(inputHoursWorkedRef.current !== null) {
                      inputHoursWorkedRef.current.setFocus(true);
                    }
                    setHoursWorkedHighlighted(true);
                    setTimeout(() => {
                      setHoursWorkedHighlighted(false);
                    }, 1000);
                  }, 10);
                }}
              >
                <IonIcon icon={addCircle} className='text-2xl mr-2' />
                <IonLabel>Hours Worked</IonLabel>
              </IonChip>
            }
            {!usingNotes &&
              <IonChip 
                className='text-lg'
                onClick={() => {
                  setUsingNotes(true);
                  setTimeout(() => {
                    if(inputNotesRef.current !== null) {
                      inputNotesRef.current.setFocus(true);
                    }
                    setNotesHighlighted(true);
                    setTimeout(() => {
                      setNotesHighlighted(false);
                    }, 1000);
                  }, 10);
                }}
              >
                <IonIcon icon={addCircle} className='text-2xl mr-2' />
                <IonLabel>Notes</IonLabel>
              </IonChip>
            }
            {!(inputShiftReportImage.length > 0 || shiftReportImageUrl !== null) &&
              <IonChip 
                className='text-lg'
                onClick={async () => {
                  try {
                    const imagePicker = await Camera.getPhoto({
                      quality: 90,
                      allowEditing: true,
                      resultType: CameraResultType.Base64,
                      correctOrientation: true
                    });
                    const base64String = imagePicker.base64String;

                    const image = new Image();
                    image.onload = () => {
                      const canvas = document.createElement('canvas');
                      const ctx = canvas.getContext('2d');
                      //canvas.width = image.height;
                      //canvas.height = image.width;
                      //ctx.fillStyle = '#ffffff';
                      //ctx.fillRect(0, 0, canvas.width, canvas.height);
                      //ctx.translate(image.height, 0);
                      //ctx.rotate(Math.PI/2);
                      //ctx.drawImage(image, 0, 0);
                      canvas.width = image.width;
                      canvas.height = image.height;
                      ctx.fillStyle = '#ffffff';
                      ctx.fillRect(0, 0, canvas.width, canvas.height);
                      ctx.drawImage(image, 0, 0);
                      setInputShiftReportImage(canvas.toDataURL());
                    };
                    image.src = 'data:image/jpeg;base64,'+base64String;

                    setInputShiftReportImage('data:image/jpeg;base64,'+base64String);
                    //TODO: validate the the image is between 1 and 10 MB, server will enforce this later
                    setInputImageAction('upload');
                    setOcrProgress('');
                  } catch(error) {
                  }
                }}
              >
                <IonIcon icon={addCircle} className='text-2xl mr-2' />
                <IonLabel>Shift Report Image</IonLabel>
              </IonChip>
            }
            {!(inputShiftReportImage.length > 0 || shiftReportImageUrl !== null) &&
              <IonChip 
                className='text-lg hidden'
                onClick={async () => {
                  try {
                    const imagePicker = await Camera.getPhoto({
                      quality: 90,
                      allowEditing: true,
                      resultType: CameraResultType.Base64,
                      correctOrientation: true
                    });
                    const base64String = imagePicker.base64String;
                    const newInputShiftReportImage = 'data:image/jpeg;base64,'+base64String;

                    const image = new Image();
                    image.onload = () => {
                      const canvas = document.createElement('canvas');
                      const ctx = canvas.getContext('2d');
                      canvas.width = image.width;
                      canvas.height = image.height;
                      ctx.fillStyle = '#ffffff';
                      ctx.fillRect(0, 0, canvas.width, canvas.height);
                      ctx.drawImage(image, 0, 0);
                      setInputShiftReportImage(canvas.toDataURL());

                      const onFailure = () => {
                        setIsAddingEntry(false);
                      };

                      setIsAddingEntry(true);
                      setImageUploadProgress(0);
                      axios.post(process.env.REACT_APP_WEBSITE_ACTION_API_URL, {
                        'action': 'startScan',
                        'email': userData.email,
                        'sessionKey': userData.sessionKey,
                      }).then((response) => {
                        try {
                          if (response.status !== 200) {
                            throw "non 200 http response code";
                          }
                          if ('status' in response.data !== true 
                            || 'data' in response.data !== true) {
                            throw 'bad response object';
                          }
                          if(response.data.status === 'error' 
                            && typeof response.data.data === 'object'
                            && 'clientMessage' in response.data.data && typeof response.data.data.clientMessage === 'string'
                            && response.data.data.clientMessage.length > 0) {
                            throw { 'modalMessage' : response.data.data.clientMessage };
                          }

                          //user has an outdated or incorrect session key, make them login again
                          if(response.data.status === 'success' 
                            && 'action' in response.data.data === true
                            && response.data.data.action === 'badSessionKey') {
                            onFailure();
                            ionAlert({
                              header: 'Session Expired',
                              message: 'You session has expired, you\'ll need to login again to continue.',
                              buttons: [
                                {
                                  text: 'Ok',
                                  handler: () => {
                                    userData.logout(); 
                                  }
                                }
                              ]
                            });
                            return;
                          }

                          if(response.data.status !== 'success' 
                            || 'action' in response.data.data !== true
                            || response.data.data.action !== 'startScanSuccess'
                            || 'imageKey' in response.data.data !== true) {
                            throw 'non success response from server';
                          }
                          const imageKey = response.data.data['imageKey'];
                          const onSuccess = () => {
                            setImageUploadProgress(0);
                            axios.post(process.env.REACT_APP_WEBSITE_ACTION_API_URL, {
                              'action': 'scanImage',
                              'email': userData.email,
                              'sessionKey': userData.sessionKey,
                              'imageKey': imageKey,
                            }).then((response) => {
                              try {
                                if (response.status !== 200) {
                                  throw "non 200 http response code";
                                }
                                if ('status' in response.data !== true 
                                  || 'data' in response.data !== true) {
                                  throw 'bad response object';
                                }
                                if(response.data.status === 'error' 
                                  && typeof response.data.data === 'object'
                                  && 'clientMessage' in response.data.data && typeof response.data.data.clientMessage === 'string'
                                  && response.data.data.clientMessage.length > 0) {
                                  throw { 'modalMessage' : response.data.data.clientMessage };
                                }

                                //user has an outdated or incorrect session key, make them login again
                                if(response.data.status === 'success' 
                                  && 'action' in response.data.data === true
                                  && response.data.data.action === 'badSessionKey') {
                                  onFailure();
                                  ionAlert({
                                    header: 'Session Expired',
                                    message: 'You session has expired, you\'ll need to login again to continue.',
                                    buttons: [
                                      {
                                        text: 'Ok',
                                        handler: () => {
                                          userData.logout(); 
                                        }
                                      }
                                    ]
                                  });
                                  return;
                                }

                                if(response.data.status !== 'success' 
                                  || 'action' in response.data.data !== true
                                  || response.data.data.action !== 'scanImageSuccess'
                                  || 'scan' in response.data.data !== true) {
                                  throw 'non success response from server';
                                }

                                // success
                                setIsAddingEntry(false);
                                console.log('scan', response.data.data.scan);
                                alert(JSON.stringify(response.data.data.scan, null, 2));
                              } catch(error) {
                                if(typeof error === 'object' && 'modalMessage' in error) {
                                  showAlert(error.modalMessage);
                                } else {
                                  showAlert('Something went wrong when scanning your image. Please try again. Reload the page and try again if the problem persists.');
                                }
                                onFailure();
                                //logger.addError('error doing stuff ' + error)
                              }
                            }).catch((error) => {
                              showAlert('Couldn\'t contact the server. Please try again. Reload the page and try again if the problem persists.');
                              onFailure();
                            });
                          };

                          //we received a presigned url to upload the image for this entry and we have an image to upload
                          if(newInputShiftReportImage.length > 0 && 'imageUploadUrl' in response.data.data && 'imageUploadFields' in response.data.data) {
                            uploadInputImage(newInputShiftReportImage, response.data.data.imageUploadUrl, response.data.data.imageUploadFields, onSuccess, onFailure);
                          } else {
                            onFailure();
                          }
                        } catch(error) {
                          if(typeof error === 'object' && 'modalMessage' in error) {
                            showAlert(error.modalMessage);
                          } else {
                            showAlert('Something went wrong when preparing to scanning your image. Please try again. Reload the page and try again if the problem persists.');
                          }
                          onFailure();
                          //logger.addError('error doing stuff ' + error)
                        }
                      }).catch((error) => {
                        showAlert('Couldn\'t contact the server. Please try again. Reload the page and try again if the problem persists.');
                        onFailure();
                      });
                    };
                    image.src = 'data:image/jpeg;base64,'+base64String;

                    setInputShiftReportImage(newInputShiftReportImage);
                    //TODO: validate the the image is between 1 and 10 MB, server will enforce this later
                    setInputImageAction('upload');
                    setOcrProgress('');
                  } catch(error) {
                  }
                }}
              >
                <IonIcon icon={addCircle} className='text-2xl mr-2' />
                <IonLabel>Scan Receipt</IonLabel>
              </IonChip>
            }
          </div>
          {/*
          <div className='flex'>
           {(isNewEntry === false) && 
             <IonButton 
               color='danger'
               className='grow-[1]'
               onClick={onDeleteButton}
             >
               <IonIcon icon={trash} className='mr-2' />Delete Entry
             </IonButton>
           }
           <IonButton 
             color='success'
             className='grow-[4]'
             onClick={onSaveButtonClicked}
           >
             <IonIcon icon={save} className='mr-2' />Save Entry
           </IonButton>
          </div>
          */}

        </div>
      </div>
      <IonFab slot='fixed' horizontal='end' vertical='bottom' >
        {(isNewEntry === false) &&
          <IonFabButton 
             ariaLabel='delete entry'
             className='relative top-[-20px]'
             color={'danger'} 
             onClick={onDeleteButton}
           >
             <IonIcon icon={trash} className='' />
           </IonFabButton> 
        }
        <IonFabButton 
          ariaLabel='save entry'
          color={'success'} 
          onClick={() => {
            if(Entry.isBlankEntry(getCurrentEntry())) {
              ionAlert({
                header: 'Save Blank Entry?',
                buttons: [
                  {
                    text: 'Cancel',
                  },
                  {
                    text: 'Save',
                    handler: () => {
                      onSaveButtonClicked();
                    }
                  }
                ]
              });
            } else {
              onSaveButtonClicked();
            }
          }}
        >
          <IonIcon src='/images/saveIcon.svg' size='large' />
        </IonFabButton>
      </IonFab>
    </>); }, [inputBreakDuration, inputCashTipsRef, inputHoursWorked, hoursWorkedHighlighted, inputCashTips, inputCreditTips, inputTipIn, inputTipOut, inputNetSales, inputNotes, endTimestamp, jobsElement, showDatePicker, startTimestamp, setModalTime, isNewEntry, resterauntsElement, setModalTimeChangeCallback, onSaveButtonClicked, onDeleteButton, inputShiftReportImage, usingNotes, usingCashCreditTips, cashCreditTipsHighlighted, notesHighlighted, usingTipInOut, usingNetSales, tipInHighlighted, netSalesHighlighted, usingHoursWorked, inputUncategorizedTips, shiftDateElement, usingStartEndTime, startEndTimeHighlighted, calculatedHoursWorked, shiftReportImageElement, shiftReportImageUrl, getCurrentEntry]);
 
  const contentJSX = useMemo(() => {
    return getContent();
  }, [getContent]);

  const elementJSX = useMemo(() => (
    <>
      <IonHeader>
        {headerJSX}
      </IonHeader>
      <IonContent ref={contentRef}>
        {contentJSX}
        <IonToast className='' message={toastMessage} color={toastColor} isOpen={toastShowing}></IonToast>
      </IonContent>
      <IonLoading message={'Adding Entry' + (imageUploadProgress > 0 ? ' (' + (parseInt(imageUploadProgress*100)) + '%)' :'')} isOpen={isAddingEntry} />
      <IonLoading message={'Updating Entry' + (imageUploadProgress > 0 ? ' (' + (parseInt(imageUploadProgress*100)) + '%)' :'')} isOpen={isUpdatingEntry} />
      <IonLoading message='Deleting Entry' isOpen={isDeletingEntry} />
    </>
  ), [headerJSX, contentJSX, toastMessage, toastColor, toastShowing, isAddingEntry, isDeletingEntry, isUpdatingEntry, imageUploadProgress ]);

  return (
    <>
      {elementJSX}
    </>
  );
};

export default TipzCreateEntry;
