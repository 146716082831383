import { IonItem, IonInput, IonLabel, IonText, IonIcon } from '@ionic/react';
import { closeCircle } from 'ionicons/icons';

//this component uses styles from sr/index.css
//the styles relevant to this component are enabled by adding the 'tipz' class to IonItem

const TipzInput = ({label = null, input, error, onDelete, onInput, headerWarning, isReadOnly, onEnterKey, inputRef, className = '', placeholder = null, inputmode = 'text', inputType = ''}) => {
  const useHeaderWarning = headerWarning || false;

  const getLabelJSX = () => {
    if(label === null) {
      return <></>;
    }
    if(useHeaderWarning) {
      return <IonLabel position='stacked' className='p-0'>{label}{error.length > 0 ? <strong className='ml-4 text-red-400'>{error}</strong> : ''}</IonLabel>;
    } else {
      return <IonLabel position='stacked' className='p-0'>{label}</IonLabel>;
    }
  };

  return (
    <IonItem lines='none' className='tipz w-full'>
      {getLabelJSX()}
      <IonInput
        ref={inputRef}
        value={input}
        readonly={isReadOnly === true}
        inputmode={inputmode}
        {...(typeof placeholder === 'string' ? {placeholder: placeholder} : '')}
        onKeyUp={(event) => {
          if(event.keyCode === 13 && typeof onEnterKey === 'function') { //enter key
            onEnterKey();
          }
        }}
        onIonInput={(e) => {
          if(inputType === 'money') {
            //console.log(window.getSelection().getRangeAt(0));
            let number = e.target.value.replace(/[^0-9]/g, '');
            if(number.length > 0) {
              number = '' + parseInt(number);
            }
            if(number.length > 9) {
              number = number.substring(0, 9);
            }
            if(number.length === 0) {
              number = '0.00';
            } else if(number.length === 1) {
              number = '0.0' + number;
            } else if(number.length === 2) {
              number = '0.' + number;
            } else {
              number = number.substring(0, number.length - 2) + '.' + number.substring(number.length - 2, number.length);
            }
            onInput(number);
          } else {
            onInput(e.target.value);
          }
        }}
        className={className + (error.length > 0 ? ' hasError ' : '')}
      ></IonInput>
      {useHeaderWarning === false &&
        <IonText color='danger' className={'ml-2 ' + (error.length > 0 ? '' : 'hidden')}>{error.length > 0 ? error : 'error'}</IonText>
      }
      {typeof onDelete === 'function' &&
        <IonIcon slot='end' icon={closeCircle} className='ml-2 cursor-pointer relative top-[0.75em]' title='Remove this resteraunt' size='large' onClick={() => {
          onDelete();
        }}/>
      }
    </IonItem>
  );
}

export default TipzInput;
