import { useEffect, useState, useRef, useCallback } from 'react';
import { IonApp, IonIcon, IonFooter, IonPage, IonModal, IonDatetime, IonContent, IonButton, IonLabel, IonFab, IonFabButton, IonHeader, IonToolbar, IonTitle, IonList, IonItem } from '@ionic/react';
import TipzSummary from './TipzSummary';
import TipzCreateEntry from './TipzCreateEntry';
import TipzBrowseList from './TipzBrowseList';
import TipzBrowseCalendar from './TipzBrowseCalendar';
import TipzSettings from './TipzSettings';
import { download, calendarNumber, add, pieChart, barChart, list, addCircle, settings } from 'ionicons/icons';
import { format, formatISO, parseISO } from 'date-fns';
import TipzLogo from './TipzLogo';
import { FlagIcon } from './MaterialIcons';
import TipzLogoMinimal from './TipzLogoMinimal';
import TipzCalendar from './TipzCalendar';
import Entry from './Entry';

function TipzMain({userData}) {
  const [activePage, setActivePage] = useState('menu');
  //const [isWideMode, setIsWideMode] = useState(window.innerWidth > 750);
  const [isWideMode, setIsWideMode] = useState(false);

  const [editEntryModalOpen, setEditEntryModalOpen] = useState(false);
  const [editEntry, setEditEntry] = useState(null);
  const [isEditingNewEntry, setIsEditingNewEntry] = useState(false);

  const [entrySelectionModalOpen, setEntrySelectionModalOpen] = useState(false);
  const [entrySelectionsForModal, setEntrySelectionsForModal] = useState([]);

  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [modalTime, setModalTime] = useState(formatISO(new Date()));
  const [modalTimeChangeCallback, setModalTimeChangeCallback] = useState(null);

  const contentRef = useRef(null);
  const dateTimeRef = useRef(null);
  const activePageRef = useRef(activePage);
  const editEntryModalOpenRef = useRef(editEntryModalOpen);

  const showDatePickerModal = useCallback((isOpen) => {
    if(isOpen === true) {
      setDatePickerOpen(false);
      setTimeout(() => {
        setDatePickerOpen(true);
      }, 1);
      return;
    } 

    setDatePickerOpen(isOpen);
  }, []);

  const showNewEntryModal = useCallback((startTimestamp) => {
    setEditEntryModalOpen(false);
    setIsEditingNewEntry(true);
    setEditEntry({
     'id': -1,
     'resteraunt': userData.resteraunts.length === 0 ? '' : userData.resteraunts[0],
     'job': userData.jobs.length === 0 ? '' : userData.jobs[0],
     //'startTimestring': parseInt(startTimestamp),
     'startTimestamp': parseInt(startTimestamp),
     //'endTimestring': 0,
     'endTimestamp': 0,
     'breakDuration': '',
     //'hoursWorkedString': '',
     'uncategorizedTips': '',
     'cashTips': '',
     'creditTips': '',
     'tipIn': '',
     'tipOut': '',
     'netSales': '',
     'notes': ''
    });
    setTimeout(() => {
      window.history.pushState('create', '', '#create');
      setEditEntryModalOpen(true);
    }, 1);
  }, [userData]);

  const showEditModal = useCallback((isOpen, entry) => {
    if(isOpen === true) {
      setEditEntryModalOpen(false);
      setEditEntry(null);
      setTimeout(() => {
        setEditEntry(entry);
        setIsEditingNewEntry(false);
        setTimeout(() => {
          window.history.pushState('edit entry', '', '#edit');
          setEditEntryModalOpen(true);
        }, 1);
      }, 1);
    } else {
      window.history.back();
    }
  }, []);

  useEffect(() => {
    console.log('render TipzMain');
  });

  useEffect(() => {
    if(contentRef.current) {
      contentRef.current.scrollToPoint(0, 0);
    }

    let tesseractScript = '';
    if(process.env.REACT_APP_LIVE_MODE !== 'true') {
      tesseractScript = document.createElement('script');
      tesseractScript.src = '/tesseract.min.js';
      tesseractScript.async = true;
      document.body.appendChild(tesseractScript);
    }

    const onResize = () => {
      //setIsWideMode(window.innerWidth > 750);
    };
    onResize();
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
      if(process.env.REACT_APP_LIVE_MODE !== 'true') {
        document.body.removeChild(tesseractScript);
      }
    };
  }, []);

  useEffect(() => {
    activePageRef.current = activePage;
  }, [activePage]);

  useEffect(() => {
    editEntryModalOpenRef.current = editEntryModalOpen;
  }, [editEntryModalOpen]);

  useEffect(() => {
    const onPopState = (event) => {
      if(entrySelectionModalOpen) {
        setEntrySelectionModalOpen(false);
      } 
    };

    window.addEventListener('popstate', onPopState);
    return () => {
      window.removeEventListener('popstate', onPopState);
    };
  }, [entrySelectionModalOpen]);

  const getWideModeContent = () => {
    return (
      <>
        <IonContent className='h-full overflow-y-auto'>
          {/*<div className='absolute w-full w-full h-full bg-cover' style={{ backgroundImage: 'url("/images/palm-trees.jpg")' }}></div>
          <div className={'absolute w-full w-full h-full bg-black ' + (userData.darkModeEnabled ? 'opacity-90' : 'opacity-10')}></div>*/}
          <div className='flex flex-col w-full h-full max-w-screen-xl mx-auto gap-y-2 min-h-[780px]'>

            {/* top line  */}
            <div className='w-full flex p-2'>

              {/* Logo */}
              <TipzLogo className='h-[100px]' darkMode={userData.darkModeEnabled} />

              {/* Logged in as line */}
              <div className='w-full flex flex-col items-end'>
                <div>
                  <span className=''>Logged in as</span> <span className='font-bold'>{userData.email}</span>
                </div>
                <div className='flex items-center h-full gap-x-2'>
                  <IonButton 
                    className=''
                    onClick={() => {
                      setActivePage('settings');
                      window.history.pushState('settings', '', '#settings');
                    }}
                  >
                    <IonIcon icon={settings} className='text-2xl mr-2' />
                    <IonLabel>Settings</IonLabel>
                  </IonButton>
                  <IonButton 
                    className=''
                    color='success'
                    onClick={() => {
                      //setActivePage('create');
                      //window.history.pushState('create', '', '#create');
                      setEditEntryModalOpen(false);
                      setIsEditingNewEntry(true);
                      setEditEntry({
                       'id': -1,
                       'resteraunt': userData.resteraunts.length === 0 ? '' : userData.resteraunts[0],
                       'job': userData.jobs.length === 0 ? '' : userData.jobs[0],
                       //'startTimestring': parseInt(new Date().getTime()),
                       'startTimestamp': parseInt(new Date().getTime()),
                       //'endTimestring': 0,
                       'endTimestamp': 0,
                       'breakDuration': '',
                       //'hoursWorkedString': '',
                       'uncategorizedTips': '',
                       'cashTips': '',
                       'creditTips': '',
                       'tipIn': '',
                       'tipOut': '',
                       'netSales': '',
                       'notes': ''
                      });
                      setTimeout(() => {
                        window.history.pushState('create', '', '#create');
                        setEditEntryModalOpen(true);
                      }, 1);
                    }}
                  >
                    <IonIcon icon={addCircle} className='text-2xl mr-2' />
                    <IonLabel>New Entry</IonLabel>
                  </IonButton>
                </div>
              </div>
            </div>

            {/* Summary Chart  */}
            <div className='w-full h-full flex'>
              <TipzSummary 
                userData={userData} 
                hidden={false}
                showHeader={false} 
              />
            </div>

            <div className='w-full h-full flex grow[1] lg:gap-x-4'>
              <div className='w-full h-full'>
                <TipzBrowseList
                  userData={userData}
                  showEditModal={showEditModal}
                  hidden={false}
                  showHeader={false}
                />
              </div>
              <div className='w-full h-full'>
                <TipzCalendar 
                  userEntries={userData.entries}
                  setEntrySelectionsForModal={(entriesForDay) => {
                    if(typeof entriesForDay === 'object' && Array.isArray(entriesForDay)) {
                       setEntrySelectionModalOpen(false);
                       setEntrySelectionsForModal(entriesForDay);
                       setTimeout(() => {
                         setEntrySelectionModalOpen(true);
                         window.history.pushState('select', '', '#select');
                       }, 10);
                    }
                  }} 
                  showEditModal={showEditModal}
                  showNewEntryModal={showNewEntryModal}
                  selectedDate={new Date()}
                />
              </div>
            </div>
          </div>
        </IonContent>
        {/*<IonFooter className={''}> 
          <div className='flex justify-center items-center font-bold text-2xl w-full bg-stone-500 h-[70px]'>
            Advertisement
          </div>
        </IonFooter>*/}
      </>
    );
  };

  const getNarrowModeContent = () => {
    return (
      <>
        <IonContent ref={contentRef} className={((activePage !== 'menu' && activePage !== 'settings') ? 'hidden' : '') + ' h-full'}>
          <div className='relative h-full mx-auto'>
            <div className={'flex flex-col gap-y-1 justify-start items-center grow h-full'}>
              {/*<div className='absolute w-full h-full bg-cover' style={{ backgroundImage: 'url("/images/palm-trees.jpg")' }}></div>*/}
              {/*<div className={'absolute w-full h-full bg-black ' + (userData.darkModeEnabled ? 'opacity-50' : 'opacity-10')}></div>*/}
              <div className={'relative tipzLoginDisplay w-full py-1 px-2'}>
                <span>Logged in as</span> <span className='font-bold'>{userData.email}</span>
              </div>
              <div className={'flex flex-col gap-y-0 justify-center items-center mt-1'}>
                <div className='relative'>
                  <TipzLogo className='h-[100px]' darkMode={userData.darkModeEnabled} />
                </div>
              </div>
              {/*<h2>Hello {userData.username}!</h2>*/}
              <div className='flex flex-col justify-center items-start gap-y-0 mt-2'>
                <div className='h-[1.5em]'></div>
                <IonButton 
                  className='w-full'
                  color='success'
                  onClick={() => {
                    //setActivePage('create');
                    //window.history.pushState('create', '', '#create');
                    setEditEntryModalOpen(false);
                    setIsEditingNewEntry(true);
                    setEditEntry({
                     'id': -1,
                     'resteraunt': userData.resteraunts.length === 0 ? '' : userData.resteraunts[0],
                     'job': userData.jobs.length === 0 ? '' : userData.jobs[0],
                     //'startTimestring': parseInt(new Date().getTime()),
                     'startTimestamp': parseInt(new Date().getTime()),
                     //'endTimestring': 0,
                     'endTimestamp': 0,
                     'breakDuration': '',
                     //'hoursWorkedString': '',
                     'uncategorizedTips': '',
                     'cashTips': '',
                     'creditTips': '',
                     'tipIn': '',
                     'tipOut': '',
                     'netSales': '',
                     'notes': ''
                    });
                    setTimeout(() => {
                      window.history.pushState('create', '', '#create');
                      setEditEntryModalOpen(true);
                    }, 1);
                  }}
                >
                  <div className='flex items-center justify-between w-full'>
                    <IonIcon icon={addCircle} className='text-2xl pr-2' />
                    <IonLabel className='text-center grow'>New Entry</IonLabel>
                  </div>
                </IonButton>
                <IonButton 
                  className='w-full'
                  onClick={() => {
                    setActivePage('browse');
                    window.history.pushState('browse', '', '#browse');
                  }}
                >
                  <div className='flex items-center justify-between w-full'>
                    <IonIcon icon={list} className='text-2xl pr-2' />
                    <IonLabel className='text-center grow'>My Entries</IonLabel>
                  </div>
                </IonButton>
                <IonButton 
                  className='w-full'
                  onClick={() => {
                    setActivePage('calendar');
                    window.history.pushState('calendar', '', '#calendar');
                  }}
                >
                  <div className='flex items-center justify-between w-full'>
                    <IonIcon icon={calendarNumber} className='text-2xl pr-2' />
                    <IonLabel className='text-center grow'>Calendar</IonLabel>
                  </div>
                </IonButton>
                <IonButton 
                 className='w-full'
                 onClick={() => {
                   setActivePage('summary');
                   window.history.pushState('summary', '', '#summary');
                 }}
                >
                  <div className='flex items-center justify-between w-full'>
                    <IonIcon icon={barChart} className='text-2xl pr-2' />
                    <IonLabel className='text-center grow'>Totals</IonLabel>
                  </div>
                </IonButton>
                <IonButton 
                  className='w-full'
                  onClick={() => {
                    setActivePage('settings');
                    window.history.pushState('settings', '', '#settings');
                  }}
                >
                  <div className='flex items-center justify-between w-full'>
                    <IonIcon icon={settings} className='text-2xl pr-2' />
                    <IonLabel className='text-center grow'>Settings</IonLabel>
                  </div>
                </IonButton>
              </div>
            </div>
            <IonFab slot='fixed' vertical='bottom' horizontal='end'>
              <IonFabButton 
                ariaLabel='new entry'
                color='success' 
                onClick={() => {
                  //setActivePage('create');
                  //window.history.pushState('create', '', '#create');
                  setEditEntryModalOpen(false);
                  setIsEditingNewEntry(true);
                  setEditEntry({
                   'id': -1,
                   'resteraunt': userData.resteraunts.length === 0 ? '' : userData.resteraunts[0],
                   'job': userData.jobs.length === 0 ? '' : userData.jobs[0],
                   //'startTimestring': parseInt(new Date().getTime()),
                   'startTimestamp': parseInt(new Date().getTime()),
                   //'endTimestring': 0,
                   'endTimestamp': 0,
                   'breakDuration': '',
                   //'hoursWorkedString': '',
                   'uncategorizedTips': '',
                   'cashTips': '',
                   'creditTips': '',
                   'tipIn': '',
                   'tipOut': '',
                   'netSales': '',
                   'notes': ''
                  });
                  setTimeout(() => {
                    window.history.pushState('create', '', '#create');
                    setEditEntryModalOpen(true);
                  }, 1);
                }}
              >
                <IonIcon icon={add} className='' />
              </IonFabButton>
            </IonFab>
          </div>
        </IonContent>
        <TipzBrowseList
          userData={userData}
          hidden={activePage !== 'browse'}
          showEditModal={showEditModal}
          editEntryModalOpen={editEntryModalOpen}
          onFinished={() => {
            setActivePage('menu');
          }}
        />
        <TipzBrowseCalendar
          userData={userData}
          hidden={activePage !== 'calendar'}
          showEditModal={showEditModal}
          showNewEntryModal={showNewEntryModal}
          editEntryModalOpen={editEntryModalOpen}
          onFinished={() => {
            setActivePage('menu');
          }}
        />
        <TipzSummary 
          userData={userData}
          hidden={activePage !== 'summary'} 
          onFinished={() => {
            setActivePage('menu');
          }}
        />
        {/*<TipzSettings 
          userData={userData}
          hidden={activePage !== 'settings'} 
          onFinished={() => {
            setActivePage('menu');
          }}
        />*/}
        {/*<IonFooter className={activePage !== 'menu' ? 'hidden' : 'hidden'}> 
          <div className='flex justify-center items-center font-bold text-2xl w-full bg-stone-500 h-[70px]'>
            Advertisement
          </div>
        </IonFooter>*/}
      </>
    );
  };

  return (
    <IonApp>
      <IonPage>
        {isWideMode ? getWideModeContent() : getNarrowModeContent()}
        {/* Modals */}
        <IonModal 
          keepContentsMounted={true} 
          isOpen={editEntryModalOpen}
          canDismiss={() => {
            // this is called when the modal wants to close, like if the backdrop area behind the modal is clicked or <esc> is pressed
            // take this as a signal the user wants to close the modal and send the page back signal to signal our modal to close

            if(editEntryModalOpen === true) { // the modal hasn't been dismissed yet
              window.history.back();
            }
            return editEntryModalOpen === false;
          }}
          onDidDismiss={() => {
            //if(window.location.href.match(/.*#create$/) || window.location.href.match(/.*#edit$/)) {
            //  //this is called only when the user has closed the modal by clicking the the darkened area around it
            //  window.history.back();
            //}
          }}
        >
          <TipzCreateEntry
            userData={userData}
            setModalTime={setModalTime}
            showDatePicker={showDatePickerModal}
            setModalTimeChangeCallback={setModalTimeChangeCallback}
            isOpen={editEntryModalOpen}
            inputEntry={editEntry}
            isNewEntry={isEditingNewEntry} 
            isWidescreen={isWideMode}
            onFinished={() => {
              setEditEntryModalOpen(false);
              setEditEntry(null);
              setDatePickerOpen(false);
            }}
          />
        </IonModal>
        <IonModal 
          keepContentsMounted={true} 
          isOpen={activePage === 'settings'}
          canDismiss={() => {
            // this is called when the modal wants to close, like if the backdrop area behind the modal is clicked or <esc> is pressed
            // take this as a signal the user wants to close the modal and send the page back signal to signal our modal to close

            if(activePage === 'settings') { // the modal hasn't been dismissed yet
              window.history.back();
            }
            return activePage !== 'settings';
          }}
        >
          <TipzSettings 
            userData={userData}
            isOpen={activePage !== 'settings'} 
            onFinished={() => {
              setActivePage('menu');
            }}
          />
        </IonModal>
        <IonModal 
          keepContentsMounted={true}
          isOpen={datePickerOpen}
        >
          <div className='flex w-full h-full justify-center items-center' onClick={(event) => {
            //close modal if user clicks on backdrop area
            if(event.target.tagName.match(/^div$/i)) {
              showDatePickerModal(false);
              setModalTimeChangeCallback(null);
              dateTimeRef.current.reset(modalTime);
            }
          }}>
            <IonDatetime 
              ref={dateTimeRef}
              value={modalTime}
              presentation='date-time'
              preferWheel={true}
              showDefaultButtons={true}
              duration={10}
              doneText='Ok'
              formatOptions={{
                date: {
                  weekday: 'short',
                  month: 'short',
                  day: '2-digit',
                  year: 'numeric'
                },
                time: {
                  hour: '2-digit',
                  minute: '2-digit'
                }
              }}
              onIonChange={(event) => {
                if(modalTimeChangeCallback && typeof modalTimeChangeCallback.callback === 'function') {
                  modalTimeChangeCallback.callback(parseISO(event.target.value).getTime());
                }
                setModalTimeChangeCallback(null);
                showDatePickerModal(false);
              }}
              onIonCancel={(event) => {
                dateTimeRef.current.reset(modalTime);
                setModalTimeChangeCallback(null);
                showDatePickerModal(false);
              }}
            >
            </IonDatetime>
          </div>
        </IonModal>
        <IonModal
          isOpen={entrySelectionModalOpen}
          initialBreakpoint={0.25}
          breakpoints={[0, 0.25, 0.8]}
          header="Select Entry"
          canDismiss={() => {
            // this is called when the modal wants to close, like if the backdrop area behind the modal is clicked or <esc> is pressed
            // take this as a signal the user wants to close the modal and send the page back signal to signal our modal to close

            if(entrySelectionModalOpen === true) { // the modal hasn't been dismissed yet
              window.history.back();
            }
            return entrySelectionModalOpen === false;
          }}
          onDidDismiss={() => {
            //if(window.location.href.match(/.*#select$/)) {
            //  //this is called only when the user has closed the modal by clicking the the darkened area around it
            //  window.history.back();
            //}
          }}
        >
          <IonContent>
            <div className='text font-bold p-1 pt-3 pl-2 select-none'>{entrySelectionsForModal.length} Entries for {entrySelectionsForModal.length > 0 ?   format(new Date(parseInt(Entry.getStartTimestamp(entrySelectionsForModal[0]))), 'EEE, MMM d, yyyy') : ''}</div>
            <IonList>
              {entrySelectionsForModal.map((entry, key) => (
                <IonItem key={key} className='cursor-pointer tipz' lines='full' onClick={() => {
                  showEditModal(true, entry);
                  //setEntrySelectionModalOpen(false);
                   window.history.back();
                }}>
                  {Entry.getListLabel(entry)}
                </IonItem>
              ))}
            </IonList>
          </IonContent>
        </IonModal>
      </IonPage>
    </IonApp>
  );
}

export default TipzMain;
