import TipzLogo from './TipzLogo';
import TipzLogoMinimal from './TipzLogoMinimal';

const LoadingScreen = () => {
  return (
    <div className='h-screen w-screen relative loadingScreen'>
      <div className='absolute left-1/2 translate-x-[-50%] top-[45%] translate-y-[-50%]'>
        <TipzLogo className='h-[120px]' darkMode={true} />
      </div>
    </div>
  )
};

export default LoadingScreen;

//    <div className='h-screen w-screen relative bg-gradient-to-b from-stone-800 to-stone-900'>
//      <div className='absolute left-1/2 translate-x-[-50%] top-[45%] translate-y-[-50%]'>
//        <TipzLogo className='h-[120px]' darkMode={true} />
//      </div>
//    </div>
