import { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { IonContent, IonHeader, IonTitle, IonToolbar, IonButton, IonItem, IonLabel, IonList, IonSegment, IonSegmentButton, IonIcon, IonToast, IonDatetime, IonLoading, useIonAlert, IonFab, IonFabButton  } from '@ionic/react';
//import { SortList } from './MaterialIcons';
import TipzSelect from './TipzSelect';
import TipzButton from './TipzButton';
import TipzLogo from './TipzLogo';
import TipzLogoMinimal from './TipzLogoMinimal';
import { format } from 'date-fns';
import { calendarNumber, list, arrowBack, addCircle, camera, add } from 'ionicons/icons';
import axios from 'axios'
import Entry from './Entry';
import Util from './Util';

const TipzBrowseList = ({userData, showEditModal, editEntryModalOpen, hidden=false, showHeader=true, onFinished = () => {} }) => {
  const sortTypes = useMemo(() => ['Date', 'Net Sales', 'Tips', 'Hours', 'Tips / Hour'], []);
  const testEntriesToAdd = 5;

  const [ionAlert] = useIonAlert();

  const contentRef = useRef(null);

  const [sortDescending, setSortDescending] = useState(true);
  const [sortType, setSortType] = useState(sortTypes[0]);
  const [sortedEntries, setSortedEntries] = useState([]);
  const [inputSortDescending, setInputSortDescending] = useState('Descending');

  const [toastMessage, setToastMessage] = useState('');
  const [toastShowing, setToastShowing] = useState(false);
  const [toastColor, setToastColor] = useState('');
  const [toastTimeout, setToastTimeout] = useState(null);

  const [isAddingEntry, setIsAddingEntry] = useState(false);

  const showToastMessage = useCallback((message, color, duration) => {
    //show toast then hide after a delay period
    setToastMessage(message);
    if(color) {
      setToastColor(color);
    }
    setToastShowing(true);

    if(toastTimeout !== null) {
      clearTimeout(toastTimeout);
    } 
    const timeout = setTimeout(() => {
      setToastShowing(false);
      //setToastTimeout(null);
    }, duration && typeof duration === 'number' ? duration : 2000);
    setToastTimeout(timeout);
  }, [toastTimeout]);


  const updateSort = useCallback(() => {
    const newSortedEntries = [];
    for(const entry of userData.entries) {
      newSortedEntries.push(entry);
    }
    newSortedEntries.sort((one, two) => {
      if(sortType === 'Date') {
        return sortDescending ? Entry.getStartTimestamp(two) - Entry.getStartTimestamp(one) : Entry.getStartTimestamp(one) - Entry.getStartTimestamp(two);
      } else if(sortType === 'Net Sales') {
        return sortDescending ? Entry.getNetSales(two) - Entry.getNetSales(one) : Entry.getNetSales(one) - Entry.getNetSales(two);
      } else if(sortType === 'Tips') {
        return sortDescending ? Entry.getTotalTips(two) - Entry.getTotalTips(one) : Entry.getTotalTips(one) - Entry.getTotalTips(two);
      } else if(sortType === 'Hours') {
        return sortDescending ? Entry.getHoursWorked(two) - Entry.getHoursWorked(one) : Entry.getHoursWorked(one) - Entry.getHoursWorked(two);
      } else if(sortType === 'Tips / Hour') {
        return sortDescending ? Entry.getTipsPerHour(two) - Entry.getTipsPerHour(one) : Entry.getTipsPerHour(one) - Entry.getTipsPerHour(two);
      }
      return Entry.getStartTimestamp(two) - Entry.getStartTimestamp(one);
    });

    setSortedEntries(newSortedEntries);
  }, [userData.entries, sortType, sortDescending]);

  const getHeader = useCallback(() => {
    console.log('render browse header');
    return (
      <IonToolbar color='primary'>
        <div className='flex justify-evenly'>
          <TipzButton 
            onClick={() => {
              window.history.back();
            }}
          >
            <IonIcon icon={arrowBack} className='text-xl align-middle' /> Back
          </TipzButton>
          <div className='grow-[1]'></div>
          <IonTitle className=''>Entries ({sortedEntries.length})</IonTitle>
          <div className='grow-[1]'></div>
          <div className='mr-2'>
            <TipzLogoMinimal darkMode={false} className='h-[40px]' />
          </div>
        </div>
      </IonToolbar>
    );
  }, [sortedEntries]);

  const headerJSX = useMemo(() => {
    return getHeader();
  }, [getHeader]);

  const getContent = useCallback(() => {
    console.log('render browse content');
    return (
      <div className='mx-auto h-full flex flex-col'>
        {/*<div className='flex'><SortList className='mr-2 h-[30px]' />Sort</div>*/}
        <div className='flex flex-col grow-[0]'>
          <div className='m-1'>
            <TipzSelect 
              label={showHeader ? 'Sort By' : 'My Entries, Sorted By'}
              items={sortTypes} 
              error='' 
              inlineLabel={true} 
              placeholder={sortTypes[0]} 
              inputValue={sortType}
              interfaceType={showHeader ? 'action-sheet' : 'popover'}
              onInput={(value) => {
                setSortType(value); 
                // scroll list of entries to the top
                if(contentRef.current) {
                  contentRef.current.scrollTo(0, 0);
                }
              }} 
            /> 
          </div>
          <IonSegment 
            className='w-full'
            value={inputSortDescending}
            onIonChange={(event) => {
              setInputSortDescending(event.target.value);
              setSortDescending(event.target.value === 'Descending');

              // scroll list of entries to the top
              if(contentRef.current) {
                contentRef.current.scrollTo(0, 0);
              }
            }}
          >
            <IonSegmentButton value='Descending'>
              <IonLabel>Descending</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value='Ascending'>
              <IonLabel>Ascending</IonLabel>
            </IonSegmentButton>
          </IonSegment>
        </div>
        <div ref={contentRef} className='grow-[1] overflow-y-scroll h-full'>
          {sortedEntries.length > 0 ?
            <IonList>
              {sortedEntries.map((entry, key) => (
                <IonItem key={key} className='cursor-pointer tipz' lines='full' onClick={() => {
                  showEditModal(true, entry);
                }}>
                  {Entry.getListLabel(entry)}
                </IonItem>
              ))}
            </IonList>
          :
            <h2 className='text-center w-full'>No Entries</h2>
          }
        </div>
      </div>
    );
  }, [inputSortDescending, showEditModal, sortedEntries, sortType, sortTypes, showHeader]);

  const contentJSX = useMemo(() => {
    return getContent();
  }, [getContent]);

  useEffect(() => {
    updateSort(sortType, sortDescending);
  }, [updateSort, sortType, sortDescending]);

  useEffect(() => {
    if(hidden) {
      setToastShowing(false);
    } else if(contentRef.current) {
      // scroll list of entries to the top
      contentRef.current.scrollTo(0, 0);
    }
  }, [hidden]);

  useEffect(() => {
    const onPopState = (event) => {
      if(!hidden && !editEntryModalOpen) {
        onFinished();
      }
    };

    window.addEventListener('popstate', onPopState);
    return () => {
      window.removeEventListener('popstate', onPopState);
    };
  }, [editEntryModalOpen, hidden, onFinished]);

  const elementJSX = useMemo(() => (
    <>
      {showHeader === true && 
        <IonHeader className={hidden === true ? 'hidden' : ''}>
          {headerJSX}
        </IonHeader>
      }
      <IonContent className={hidden === true ? 'hidden' : ''}>
        {contentJSX}
      </IonContent>
      <IonLoading message='Adding Entry' isOpen={isAddingEntry} />
      <IonToast className='' message={toastMessage} color={toastColor} isOpen={toastShowing}></IonToast>
    </>
  ), [headerJSX, contentJSX, hidden, toastMessage, toastColor, toastShowing, isAddingEntry, showHeader]);

  return (
    <>
      {elementJSX}
    </>
  );
};

export default TipzBrowseList;


//<IonButtons>
//  <IonBackButton routerLink='/' defaultHref='/' className=''></IonBackButton>
//</IonButtons>

//routerDirection='forward' routerLink={'/editEntry/'+entry.id} 


//set document title for page
//useEffect(() => {
//  if(router.routeInfo.pathname === '/browseEntries') {
//    document.title = 'Browse Entries | Tipz';
//  }
//});

//restore scroll position when location changes
//useEffect(() => {
//  setTimeout(() => {
//    if(contentRef.current) {
//      contentRef.current.scrollToPoint(0, contentScrollY)
//    }
//  }, 1);
//}, [location]);


//save scroll position
//contentRef.current.getScrollElement().then(data => {
//  setContentScrollY(data.scrollTop);
//});
