const TipzLogoMinimal = ({className}) => {
  return (
    <div></div>
    //<svg
    //  xmlns="http://www.w3.org/2000/svg"
    //  version="1.1"
    //  viewBox="0 0 7.751 4.833"
    //  className={className}
    //>
    //  <defs>
    //    <path d="M2046.704 -3246.224H4069.656V-2612.815H2046.704z"></path>
    //    <path d="M2046.704 -3246.224H4069.656V-2612.815H2046.704z"></path>
    //    <path d="M2046.704 -3246.224H2368.71V-3184.482H2046.704z"></path>
    //  </defs>
    //  <g
    //    fill="#ffff00"
    //    stroke="#000"
    //    strokeWidth="0.051"
    //    fontFamily="Noto Sans Armenian"
    //    fontSize="5.704"
    //    transform="rotate(12.117)"
    //    style={{ lineHeight: "0" }}
    //    letterSpacing="0"
    //    wordSpacing="0"
    //  >
    //    <path
    //      d="M8.052 3.183v-.787h-.838L8.03.958 7.995.45H5.948v.799h.878l-.844 1.386.023.548z"
    //      style={{ InkscapeFontSpecification: "Passion" }}
    //      fontFamily="Passion"
    //    ></path>
    //  </g>
    //  <g
    //    fill="#ffff00"
    //    stroke="#000"
    //    style={{ lineHeight: "0" }}
    //    strokeWidth="0.051"
    //    fontFamily="Noto Sans Armenian"
    //    fontSize="5.704"
    //    letterSpacing="0"
    //    wordSpacing="0"
    //  >
    //    <path
    //      d="M1.018 4.469c.24 0 .44-.023.673-.051l.029-.76c-.114.007-.274.018-.354.018-.08 0-.114-.034-.114-.12V2.421h.474l.04-.707h-.508v-.525H.396l-.051.525h-.32v.707H.3v1.323c0 .548.24.725.72.725z"
    //      style={{ InkscapeFontSpecification: "Passion" }}
    //      fontFamily="Passion"
    //    ></path>
    //  </g>
    //  <g
    //    fill="#00b8c1"
    //    stroke="#000"
    //    strokeWidth="0.051"
    //    fontFamily="Noto Sans Armenian"
    //    fontSize="5.123"
    //    transform="scale(1.11351 .89806)"
    //    style={{ lineHeight: "0" }}
    //    letterSpacing="0"
    //    wordSpacing="0"
    //  >
    //    <path
    //      d="M3.538 4.23h.02a.557.557 0 00.38.113c.686 0 .85-.492.85-1.332 0-.825-.17-1.235-.8-1.235-.225 0-.42.052-.527.21h-.026l-.05-.153h-.708v3.155h.86zm.39-1.158c0 .395-.006.6-.195.6a.454.454 0 01-.205-.046v-1.06a.383.383 0 01.2-.062c.184 0 .2.215.2.568z"
    //      style={{ InkscapeFontSpecification: "Passion" }}
    //      fontFamily="Passion"
    //    ></path>
    //  </g>
    //  <g
    //    fill="#0f0"
    //    stroke="#000"
    //    style={{ lineHeight: "0", InkscapeFontSpecification: "Passion" }}
    //    strokeWidth="0.051"
    //    fontFamily="Passion"
    //    fontSize="1.926"
    //    letterSpacing="0"
    //    wordSpacing="0"
    //  >
    //    <path d="M1.992.573c0 .377.422.362.422.5 0 .049-.023.072-.073.072a1.08 1.08 0 01-.295-.06l-.021.287c.073.025.144.04.229.05v.139h.254v-.15c.16-.043.245-.174.245-.366 0-.363-.42-.372-.42-.48 0-.05.035-.073.09-.073.064 0 .154.019.262.044l.039-.308a2.098 2.098 0 00-.216-.029V.026h-.254v.18c-.168.043-.262.18-.262.367z"></path>
    //  </g>
    //  <path
    //    fill="#0f0"
    //    fillRule="evenodd"
    //    stroke="#000"
    //    strokeLinecap="round"
    //    strokeLinejoin="round"
    //    strokeWidth="0.051"
    //    d="M1.848 1.772h.938v2.704h-.938z"
    //  ></path>
    //</svg>
  );
};

export default TipzLogoMinimal;
